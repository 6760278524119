import React from "react";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Button, Card } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faCog,
  faMoneyBill,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import UpdateFreezeWrapper from "./UpdateFreezeWrapper";
import { pageTitleStyles } from "../../mui/styles";

/**
 * AdminSection is a React component that renders the admin section of the application.
 * It provides various navigational links for importing activities, settings configuration,
 * manual order overrides, sales notes editing, helpdesk management, training modules,
 * and finance-related functions such as commissions. Each section is represented with a
 * card that contains a list item with an icon and text description.
 *
 * @returns {React.ReactElement} A JSX element representing the admin section UI.
 */
function AdminSection() {
  const history = useHistory();
  const listItemStyles = {
    icon: {
      backgroundColor: "#ddd", // Light gray background for the icon circle.
      height: 50, // Fixed height for the icon.
      width: 50, // Fixed width for the icon.
      borderRadius: 25, // Make the icon container circular.
      display: "flex", // Flexbox to center the icon content.
      justifyContent: "center", // Horizontally center the icon.
      alignItems: "center", // Vertically center the icon.
      marginRight: 20, // Space between the icon and text.
    },
  };

  // Fetch company-specific information about update freeze state.

  return (
    <div>
      <Button
        startIcon={<ArrowBack />}
        variant="text"
        color="primary"
        onClick={() => history.goBack()}
      >
        Back
      </Button>
      <h1 style={pageTitleStyles}>Admin Section</h1>
      <Grid container spacing={2}>
        <UpdateFreezeWrapper />
        <Grid item xs={12}>
          <Typography variant="h6" component="div">
            Imports
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <Link to="/import-fibre-activity">
              <ListItem style={{ cursor: "pointer" }}>
                <ListItemIcon style={listItemStyles.icon}>
                  <FontAwesomeIcon icon={faUpload} color="#666666" />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      fontSize: "1.25rem",
                      fontWeight: "400",
                      color: "#000",
                    },
                  }}
                  secondaryTypographyProps={{
                    style: { fontSize: "1rem", color: "#666666" },
                  }}
                  primary="Import Fibre Activity"
                  secondary="Agent Sales, Order Progress, etc."
                />
              </ListItem>
            </Link>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" component="div">
            Settings
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <Link to="/settings">
              <ListItem onClick={() => {}} style={{ cursor: "pointer" }}>
                <ListItemIcon style={listItemStyles.icon}>
                  <FontAwesomeIcon icon={faCog} color="#666666" />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      fontSize: "1.25rem",
                      fontWeight: "400",
                      color: "#000",
                    },
                  }}
                  secondaryTypographyProps={{
                    style: { fontSize: "1rem", color: "#666666" },
                  }}
                  primary="Organisation Settings"
                  secondary="Configure Dealer, Rica and Services settings"
                />
              </ListItem>
            </Link>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" component="div">
            Agent Actions
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <Link to="/order-override">
              <ListItem onClick={() => {}} style={{ cursor: "pointer" }}>
                <ListItemIcon style={listItemStyles.icon}>
                  <FontAwesomeIcon icon={faMoneyBill} color="#666666" />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      fontSize: "1.25rem",
                      fontWeight: "400",
                      color: "#000",
                    },
                  }}
                  secondaryTypographyProps={{
                    style: { fontSize: "1rem", color: "#666666" },
                  }}
                  primary="Manual Order Override"
                  secondary="Capture the correct Agent to be associated with an order"
                />
              </ListItem>
            </Link>

            <Link to="/edit-sales-note">
              <ListItem onClick={() => {}} style={{ cursor: "pointer" }}>
                <ListItemIcon style={listItemStyles.icon}>
                  <FontAwesomeIcon icon={faMoneyBill} color="#666666" />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      fontSize: "1.25rem",
                      fontWeight: "400",
                      color: "#000",
                    },
                  }}
                  secondaryTypographyProps={{
                    style: { fontSize: "1rem", color: "#666666" },
                  }}
                  primary="Edit Sales Note"
                  secondary="Change the captured sales note for an order"
                />
              </ListItem>
            </Link>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" component="div">
            Helpdesk
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <Link to="/sales-leads-admin-view">
              <ListItem onClick={() => {}} style={{ cursor: "pointer" }}>
                <ListItemIcon style={listItemStyles.icon}>
                  <FontAwesomeIcon icon={faMoneyBill} color="#666666" />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      fontSize: "1.25rem",
                      fontWeight: "400",
                      color: "#000",
                    },
                  }}
                  secondaryTypographyProps={{
                    style: { fontSize: "1rem", color: "#666666" },
                  }}
                  primary="Sales Assist"
                  secondary="Admin view for the sales assist"
                />
              </ListItem>
            </Link>

            <Link to="/admin-agent-assist">
              <ListItem onClick={() => {}} style={{ cursor: "pointer" }}>
                <ListItemIcon style={listItemStyles.icon}>
                  <FontAwesomeIcon icon={faMoneyBill} color="#666666" />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      fontSize: "1.25rem",
                      fontWeight: "400",
                      color: "#000",
                    },
                  }}
                  secondaryTypographyProps={{
                    style: { fontSize: "1rem", color: "#666666" },
                  }}
                  primary="Agent Assist"
                  secondary="Admin view for the agent assist"
                />
              </ListItem>
            </Link>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" component="div">
            Zawadi University
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <Link to="/training-modules">
              <ListItem onClick={() => {}} style={{ cursor: "pointer" }}>
                <ListItemIcon style={listItemStyles.icon}>
                  <FontAwesomeIcon icon={faGraduationCap} color="#666666" />
                </ListItemIcon>
                <ListItemText
                  primary="Training Modules"
                  secondary="Add, edit and remove training modules"
                  primaryTypographyProps={{
                    style: {
                      fontSize: "1.25rem",
                      fontWeight: "400",
                      color: "#000",
                    },
                  }}
                  secondaryTypographyProps={{
                    style: { fontSize: "1rem", color: "#666666" },
                  }}
                />
              </ListItem>
            </Link>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" component="div">
            Finance
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <Link to="/commissions">
              <ListItem onClick={() => {}} style={{ cursor: "pointer" }}>
                <ListItemIcon style={listItemStyles.icon}>
                  <FontAwesomeIcon icon={faMoneyBill} color="#666666" />
                </ListItemIcon>
                <ListItemText
                  primary="Commissions"
                  secondary="Functions relating to commissions payments"
                  primaryTypographyProps={{
                    style: {
                      fontSize: "1.25rem",
                      fontWeight: "400",
                      color: "#000",
                    },
                  }}
                  secondaryTypographyProps={{
                    style: { fontSize: "1rem", color: "#666666" },
                  }}
                />
              </ListItem>
            </Link>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default AdminSection;
