import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { ArrowLeft } from "@material-ui/icons";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { pageTitleStyles } from "../../../mui/styles";
import { useCompany } from "../../../contexts/CompanyContext";

/**
 * LevelSettings component allows users to manage dealer levels.
 * It provides functionality to view existing levels and add new levels.
 *
 * @component
 * @returns {React.Element} The rendered component.
 *
 * @example
 * <LevelSettings />
 *
 * @function
 * @name LevelSettings
 *
 * @description
 * This component uses several hooks:
 * - `useHistory` from `react-router-dom` to navigate back to the settings page.
 * - `useState` to manage component state for dialog open/close, level name, level, loading, and error.
 * - `useCompany` custom hook to get dealer levels and upsertDealerLevel function.
 *
 * @typedef {object} DealerLevel
 * @property {string} id - The unique identifier of the dealer level.
 * @property {string} level_name - The name of the dealer level.
 *
 * @typedef {object} UpsertDealerLevelParams
 * @property {number} level - The level number.
 *
 * @async
 * @function handleSubmit
 * @description Handles the submission of the new dealer level form.
 * It sets the loading state, calls the upsertDealerLevel function, and handles any errors.
 *
 * @returns {Promise<void>}
 */
export default function LevelSettings() {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [levelName, setLevelName] = useState(null);
  const [level, setLevel] = useState(2);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { dealerLevels: levels, upsertDealerLevel } = useCompany();

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await upsertDealerLevel({ level_name: levelName, level });
      setOpen(false);
      setLevelName(null);
      setLevel(2);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <div>
      <Button
        startIcon={<ArrowLeft />}
        color="primary"
        onClick={() => history.push("/settings")}
      >
        Back
      </Button>
      <h1 style={pageTitleStyles}>Level Settings</h1>
      <Button color="primary" onClick={() => setOpen(true)} variant="contained">
        Add Level
      </Button>
      <br />
      <br />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Level Name</TableCell>
              <TableCell>Level</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {levels &&
              levels.map(l => (
                <TableRow key={l.id}>
                  <TableCell>{l.level_name}</TableCell>
                  <TableCell>{l.level}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
      >
        <form>
          <DialogContent>
            <Typography>Level:</Typography>
            <Slider
              defaultValue={2}
              step={1}
              marks
              min={2}
              max={10}
              onChangeCommitted={(e, val) => setLevel(val)}
              valueLabelDisplay="on"
            />
            <TextField
              variant="outlined"
              fullWidth
              label="Level Name"
              required
              onChange={e => setLevelName(e.target.value)}
            />
            <br />
            {error ? <span className="sim-error">{error}</span> : null}
          </DialogContent>
          <DialogActions>
            <Button
              disabled={loading}
              color="secondary"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button disabled={loading} color="primary" onClick={handleSubmit}>
              Confirm
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
