const { banks } = require("../constants");

const NULL_BANK = {
  label: null,
  value: null,
  branchCode: null,
};

/**
 * Retrieves details of a bank based on a given key.
 *
 * The function searches through a list of banks to find a match where the
 * bank's label, value, or branch code matches or includes the given key. If a
 * match is found, it returns the bank details; otherwise, it returns a
 * NULL_BANK object.
 *
 * @param {string | number} key - The key to search for, which can be a full or
 *                                partial match of a bank's label, value, or
 *                                branch code.
 * @returns {object} - An object containing the bank details if found, or a
 *                     NULL_BANK object if no match is found.
 */
function getBankDetails(key) {
  if (!key || (typeof key !== "string" && typeof key !== "number")) {
    return NULL_BANK;
  }

  const keyString = String(key).toLowerCase();

  let bank = banks.find(
    b =>
      b.label.toLowerCase() === keyString ||
      b.value.toLowerCase() === keyString ||
      b.branchCode === keyString
  );

  if (!bank || !bank.value || !bank.branchCode) {
    bank = banks.find(
      b =>
        b.label.toLowerCase().includes(keyString) ||
        b.value.toLowerCase().includes(keyString) ||
        b.branchCode.includes(keyString)
    );
  }

  if (!bank || !bank.value || !bank.branchCode) {
    return NULL_BANK;
  }

  return bank;
}

module.exports = getBankDetails;
