import {
  Card,
  CardContent,
  Grid,
  LinearProgress,
  styled,
  Typography,
} from "@material-ui/core";
import { Public } from "@material-ui/icons";
import { linearProgressClasses } from "@mui/material";
import React from "react";

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#D3D3D3",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#3D7155",
  },
}));

/**
 * A card component for displaying a user's leaderboard information,
 * with a rank, name, fiber sales, and a progress bar.
 *
 * @param {object} props - The properties for the component.
 * @param {number} props.rank - The user's rank.
 * @param {string} props.name - The user's name.
 * @param {number} props.percentage - The user's progress towards their target.
 * @param {string} props.fiber - The user's fiber sales.
 * @returns {React.ReactElement} A JSX element representing the card.
 */
export default function DesktopUserCard({ rank, name, percentage, fiber }) {
  return (
    <Card
      style={{
        borderRadius: "16px", // Set the card rounding
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Custom box shadow for contained buttons
        transition: "transform 0.3s ease, box-shadow 0.3s ease", // Smooth transition for hover effects
      }}
      onMouseEnter={e => {
        e.currentTarget.style.transform = "scale(1.02)";
        e.currentTarget.style.boxShadow = "0px 6px 15px rgba(0, 0, 0, 0.2)";
      }}
      onMouseLeave={e => {
        e.currentTarget.style.transform = "scale(1)";
        e.currentTarget.style.boxShadow = "0px 4px 12px rgba(0, 0, 0, 0.1)";
      }}
    >
      <CardContent>
        <Grid container>
          <Grid container xs={11} direction="row" spacing={2}>
            <Grid item>
              <Typography variant="h3" align="left">
                {rank}
              </Typography>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <Grid>
                <Typography
                  style={{ textTransform: "capitalize" }}
                  variant="h6"
                >
                  {name}
                </Typography>
                <BorderLinearProgress
                  variant="determinate"
                  value={percentage}
                />
              </Grid>
            </Grid>{" "}
          </Grid>
          <Grid item xs={1} style={{ marginTop: "15px" }}>
            <Grid container direction="row" justify="center">
              <Typography align="right" variant="h4">
                <Public fontSize="inherit" color="primary" />
              </Typography>

              <Typography variant="h6" align="left">
                :&nbsp;{fiber}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
