import React from "react";
import { IconButton, TextField } from "@material-ui/core";

import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";

import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  grid: {
    border: 0,
  },
  root: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: "space-between",
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  textField: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    margin: theme.spacing(1, 0.5, 1.5),
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(0.5),
    },
    "& .MuiInput-underline:before": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  overlay: {
    flexDirection: "column",
    "& .ant-empty-img-1": {
      fill: theme.palette.type === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.type === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.type === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.type === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.type === "light" ? "0.8" : "0.08",
      fill: theme.palette.type === "light" ? "#f5f5f5" : "#fff",
    },
  },
  label: {
    marginTop: theme.spacing(1),
  },
}));

/**
 * QuickSearchToolbar is a React component that provides a search input field with a toolbar for data grid management.
 * It allows users to input search queries and clear them using a button. Additionally, it includes buttons for
 * managing grid columns, filters, density, and exporting data.
 *
 * @param {object} props - JSX props
 * @param {string} props.value - The current search query string.
 * @param {Function} props.onChange - Callback function to handle changes to the search input.
 * @param {Function} props.clearSearch - Callback function to clear the search input field.
 *
 * @returns {React.ReactElement} The rendered component with search functionality and toolbar options.
 */
function QuickSearchToolbar({ value, onChange, clearSearch }) {
  const classes = useStyles();
  const toolbarButtonStyle = {
    fontSize: ".875rem",
    padding: ".5rem .65rem",
    textTransform: "none",
  };

  return (
    <div className={classes.root}>
      <TextField
        variant="standard"
        value={value}
        style={{
          marginLeft: "1rem",
        }}
        onChange={onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: value ? "visible" : "hidden" }}
              onClick={clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
      <GridToolbarContainer>
        <GridToolbarColumnsButton style={toolbarButtonStyle} />
        <GridToolbarFilterButton style={toolbarButtonStyle} />
        <GridToolbarDensitySelector style={toolbarButtonStyle} />
        <GridToolbarExport style={toolbarButtonStyle} />
      </GridToolbarContainer>
    </div>
  );
}

// PropTypes for QuickSearchToolbar component
QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default QuickSearchToolbar;
