import React from "react";
import { Button, Dialog, Grid } from "@mui/material";
import nanoId from "nano-id";
import SBFileUploader from "../../shared/SBFileUploader";

/**
 * Component for adding a banner.
 *
 * @param {object} props - The component props.
 * @param {boolean} props.open - Indicates if the dialog is open.
 * @param {Function} props.close - Function to close the dialog.
 * @param {object} props.banner - The current banner object.
 * @param {Function} props.setBanner - Function to update the banner object.
 * @param {string} props.folder - The folder path for uploading the banner image.
 *
 * @returns {React.Element} The AddBanner component.
 */
export default function AddBanner({ open, close, banner, setBanner, folder }) {
  const handleSubmit = (data, fileurl) => {
    const file = { data, url: fileurl };

    setBanner({
      ...banner,
      images: [
        {
          name: file.data.name,
          url: fileurl,
          ...file.data,
        },
      ],
    });

    // Refresh the page after setting the banner
    window.location.reload();
  };

  // Closes the image dialog.
  const handleClose = () => {
    close();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <Grid container padding={2} spacing={1}>
          <Grid item xs={12}>
            <SBFileUploader
              bucketName="banner_images"
              customfilePath={`${folder}/${nanoId(10)}`}
              info={handleSubmit}
              singleFile
            />{" "}
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={handleClose}
            >
              {banner.images.length > 0 ? "Done" : "Cancel"}
            </Button>{" "}
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
