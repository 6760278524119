import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Input,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { supabase } from "../../../../supabase";
import { useAuth } from "../../../../contexts/AuthContext";
import { useSB } from "../../../../contexts/SupabaseContext";
import ActivityBubble from "./ActivityBubble.component";

import userFromAuth from "../../../shared/functions/userFromAuth.function";
import formatTicketNumber from "../../../shared/functions/formatTicketNumber";

import sendAssistMessage from "../../../shared/functions/sendAssistMessage.function";
import sendPersonalAlert from "../../../shared/functions/sendPersonalAlert.function";

/**
 * Renders the activity feed for a specific lead. Allows administrators to send messages and mark activities as internal.
 *
 * @component
 * @param {object} props - Component properties.
 * @param {object} props.lead - The lead data associated with the activity feed.
 * @returns {React.ReactElement} The rendered component.
 */
function Activity({ lead }) {
  const { currentUser } = useAuth(); // Get the current authenticated user
  const { updateRow } = useSB(); // Function to update rows in Supabase

  const isAdmin = currentUser.rank === 1; // Boolean to check if the user is an admin

  // State variables
  const [messageText, setMessageText] = useState(""); // Holds the current message text
  const [loading, setLoading] = useState(false); // Tracks loading state for message sending
  const [internal, setInternal] = useState(false); // Tracks whether the message is marked as internal
  const [itemActivity, setItemActivity] = useState([]); // Stores the activity items fetched from the database
  const [user, setUser] = useState({}); // Stores the user details for the lead

  /**
   * Fetches activity data for the current lead from the `sales_assist_activity` table in Supabase.
   */
  const fetchActivity = async () => {
    setLoading(true);

    const { data: activityData, error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("sales_assist_activity")
      .select("*")
      .eq("lead_id", lead.id)
      .order("created_at", { ascending: true }); // Orders activities by creation date

    if (error) {
      console.error(error.message);
    } else {
      setItemActivity(await activityData); // Update activity state with the fetched data
    }

    setLoading(false);
  };

  /**
   * Fetches user details for the current lead from the authentication system.
   */
  const fetchUser = async () => {
    const userQuery = await userFromAuth(lead.auth_id); // Get user data from authentication
    setUser(await userQuery.user); // Set the user state with the fetched user data
  };

  /**
   * Sends a new message related to the lead activity.
   * If the user is an admin, it also triggers a personal alert to the lead user.
   */
  const sendMessage = async () => {
    setLoading(true);

    await sendAssistMessage(
      lead.id,
      messageText,
      internal ? "internal" : isAdmin ? "admin" : "user",
      "sales_assist_activity"
    ); // Sends the message, categorizing it as internal/admin/user based on flags

    // Send a personal alert to the lead user if the message is sent by an admin
    if (isAdmin && user.id && !internal) {
      sendPersonalAlert(
        await user.id,
        `Ticket Response - ${formatTicketNumber(lead.ticket_num, "ZSA")}`,
        "<p>Your ticket received a response. Please view in Sales Assist</p>"
      );
    }

    // Clear the message input and reset internal flag
    setMessageText("");
    setInternal(false);

    setLoading(false);
  };

  // Maps the fetched activity items into ActivityBubble components for rendering
  const activityMap = itemActivity.map(item => (
    <ActivityBubble
      item={item}
      currentUser={currentUser}
      key={item.id}
      ticketUser={`${user.dealer_code}: ${user.contact_name}`}
    />
  ));

  /**
   * useEffect to fetch activity and user details when the component mounts.
   * Also subscribes to real-time updates from the `sales_assist_activity` table.
   */
  useEffect(() => {
    fetchActivity(); // Fetch activity on component mount
    fetchUser(); // Fetch lead user details on component mount

    // Check for valid lead.id, and log an error if it’s undefined
    if (!lead?.id) {
      console.error("lead.id is undefined");
      return;
    }

    // Subscribe to real-time changes in the `sales_assist_activity` table
    const subscription = supabase
      .channel("sales_assist_activity_channel")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: process.env.REACT_APP_SB_SCHEMA,
          table: "sales_assist_activity",
          filter: `lead_id=eq.${lead.id}`,
        },
        () => fetchActivity() // Re-fetch activity on any change
      )
      .subscribe();

    // Clean up the subscription when the component unmounts
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  /**
   * useEffect to mark activities as read when `itemActivity` changes.
   * Admins mark `read_admin`, while users mark `read_user`.
   */
  useEffect(() => {
    const now = new Date().toISOString(); // Current timestamp
    itemActivity.forEach(item => {
      if (isAdmin && !item.read_admin) {
        // Mark as read for admin
        updateRow("sales_assist_activity", item.id, {
          ...item,
          read_admin: now,
        });
      } else if (!isAdmin && !item.read_user && !item.admin_only) {
        // Mark as read for regular users
        updateRow("sales_assist_activity", item.id, {
          ...item,
          read_user: now,
        });
      }
    });
  }, [itemActivity]); // Triggers whenever `itemActivity` changes

  return (
    <Grid container item direction="column" xs={12} spacing={2}>
      <Grid item container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h6">Activity</Typography>
        </Grid>
        <Grid item container direction="column" spacing={1}>
          {activityMap.length > 0 ? (
            activityMap // Render activity items if present
          ) : (
            <Typography>No activity to display</Typography> // Display if no activity
          )}
        </Grid>
        <Grid container item direction="row" spacing={1}>
          {isAdmin && (
            <Grid item alignContent="center" xs={12}>
              <FormControlLabel
                label="Internal"
                control={
                  <Switch
                    checked={internal}
                    onChange={() => setInternal(prev => !prev)} // Toggle internal flag
                    color="primary"
                  />
                }
              />
            </Grid>
          )}
          <Grid item xs={11}>
            <Input
              multiline
              placeholder="Message"
              fullWidth
              value={messageText}
              onChange={e => setMessageText(e.target.value)} // Update message input value
            />
          </Grid>
          <Grid item alignContent="center" xs={1}>
            <Button
              variant="text"
              color="primary"
              onClick={sendMessage} // Send message when button is clicked
              disabled={loading} // Disable button if loading
              type="submit"
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default Activity;
