// #useAuthUpdated

import React, { forwardRef } from "react";
import { Card, CardContent, Divider, Typography } from "@material-ui/core";
import _ from "lodash";
import Notification from "./Notification";

/**
 * DealerNotifications is a component that renders a list of notifications (alerts) for the dealer.
 * It takes in three props: showAlertFunction, allAlerts, and closeMenu.
 * showAlertFunction is a function that takes in a notification and displays it in an alert dialog.
 * allAlerts is an array of all the alerts for the dealer. This should be an array of objects with a "timestamp" property.
 * closeMenu is a function that closes the menu when a notification is clicked.
 *
 * The component renders a Card with a CardContent that lists all the unread notifications and then all the notifications.
 * Each notification is rendered as a Notification component.
 *
 * @param {object} props - The props object
 * @param {Function} props.showAlertFunction - function to display a notification in an alert dialog
 * @param {Array} props.allAlerts - array of notifications for the dealer
 * @param {Function} props.closeMenu - function to close the menu
 * @returns {React.ReactElement} - a Card with a CardContent that lists all the notifications
 */
function DealerNotifications({ showAlertFunction, allAlerts, closeMenu }) {
  const notifications = [];

  let allNotifications = _.orderBy(
    notifications.concat(allAlerts),
    "timestamp",
    "desc"
  );

  let unreadNotifications = [];

  if (allAlerts) {
    unreadNotifications = allNotifications.filter(n => n.read === false);
  }

  if (allNotifications[0] === undefined) {
    allNotifications = [];
  }

  return (
    <Card
      style={{
        minWidth: 300,
        borderRadius: "0px", // Set the card rounding
        boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)", // Initial shadow for cards
      }}
      elevation={0}
    >
      <CardContent>
        <div style={{ marginBottom: 20 }}>
          <Typography style={{ fontWeight: "bold" }}>Alerts</Typography>
        </div>
        <div style={{ marginBottom: 20 }}>Unread Messages:</div>

        <Divider />
        {
          // Map through notifications
          unreadNotifications &&
            // eslint-disable-next-line array-callback-return
            unreadNotifications.map(n => {
              return (
                <Notification
                  viewAlert={() => {
                    showAlertFunction(n);
                  }}
                  notif={n}
                />
              );
            })
        }
        <div style={{ marginBottom: 20, marginTop: 20 }}>All Messages:</div>
        <Divider />
        <br />
        {
          // Map through notifications
          allNotifications &&
            allNotifications.map(n => (
              <Notification
                viewAlert={() => {
                  showAlertFunction(n);
                  closeMenu();
                }}
                notif={n}
                closeMenu={closeMenu}
              />
            ))
        }
      </CardContent>
    </Card>
  );
}

export default forwardRef(DealerNotifications);
