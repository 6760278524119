import { Button } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router-dom";

/**
 * BackLink component renders a button that navigates the user to the previous page.
 *
 * @component
 * @example
 * return (
 *   <BackLink />
 * )
 *
 * @returns {React.Element} A button that navigates back to the previous page.
 */
export default function BackLink() {
  const history = useHistory();
  return (
    <Button
      color="primary"
      startIcon={<ArrowBack />}
      onClick={() => history.goBack()}
      style={{
        marginBottom: 10,
      }}
    >
      Back
    </Button>
  );
}
