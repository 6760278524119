/* eslint-disable no-alert */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/order */
import React, { useEffect, useState } from "react";
import BackLink from "../shared/BackLink";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import "firebase/database";
import { supabase } from "../../supabase";
import IconButton from "@mui/material/IconButton";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useSB } from "../../contexts/SupabaseContext";
import SBFileDownloader from "../shared/SBFileDownloader";
import SBFileUploader from "../shared/SBFileUploader";

/**
 * The TrainingContent component is a React component that is used to manage the content
 * for a training module. It displays the video list, question list, and allows the user
 * to add, edit, or delete videos and questions. It also has a dialog to add additional
 * content to the module.
 *
 * @example
 * <TrainingContent />
 */
function TrainingContent() {
  const id = window.location.pathname.split("/")[2];
  const moduleName = window.location.pathname.split("/")[3];
  const { GetTableWhere, updateRow } = useSB();
  const [loadingPage, setLoadingPage] = useState(true);
  const [videos, setVideos] = useState([]);
  const [videoLink, setVideoLink] = useState("");
  const [videoLanguage, setVideoLanguage] = useState("");
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState({
    question: "",
    question_language: "",
    max_options: null,
  });
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [questionForOptions, setQuestionForOptions] = useState(null);
  const [questionOptionsList, setQuestionOptionsList] = useState([]);
  const [questionTitle, setQuestionTitle] = useState("");
  const [maxOptions, setMaxOptions] = useState(null);
  const [newQuestionOption, setNewQuestionOption] = useState("");
  const [savingContent, setSavingContent] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [additionalContent, setAdditionalContent] = useState([]);
  const [showAdditionalContentModal, setShowAdditionalContentModal] =
    useState(false);

  const uploadBucket = "zawadi_university";

  /**
   * Fetches the external content from the database and sets it in the
   * state. The external content is stored in the "additional_content"
   * field of the training module document.
   */
  const fetchExternalContent = () =>
    SBFileDownloader(uploadBucket, id).then(arr => {
      setAdditionalContent(arr);
    });

  useEffect(() => {
    // Set the loading state to true when the component mounts
    setLoadingPage(true);

    // Fetch the training module details from the database
    GetTableWhere("training_modules", ["id", "eq", id]).then(data => {
      if (data[0]) {
        const docData = data[0];

        // Set the videos in the state from the fetched document
        setVideos(docData.videos || []);

        const uniqueLanguages = new Set();

        // Add video languages to the uniqueLanguages set
        if (docData.videos) {
          docData.videos.forEach(entry => {
            if (entry.video_language) {
              uniqueLanguages.add(entry.video_language);
            }
          });
        }

        // Add question languages to the uniqueLanguages set
        if (docData.questions) {
          docData.questions.forEach(entry => {
            if (entry.question_language) {
              uniqueLanguages.add(entry.question_language);
            }
          });
        }

        // Fetch additional content
        fetchExternalContent();

        // Convert the set of languages to an array and update the state
        const uniqueLanguagesArray = Array.from(uniqueLanguages);
        setLanguages(uniqueLanguagesArray);

        // Set the questions in the state from the fetched document
        setQuestions(docData.questions || []);
      }
      // Set the loading state to false after the data is fetched
      setLoadingPage(false);
    });
  }, []);

  // Effect to update the list of unique languages whenever videos or questions change
  useEffect(() => {
    const uniqueLanguages = new Set();

    // Add video languages to the uniqueLanguages set
    videos.forEach(entry => {
      if (entry.video_language) {
        uniqueLanguages.add(entry.video_language);
      }
    });

    // Add question languages to the uniqueLanguages set
    questions.forEach(entry => {
      if (entry.question_language) {
        uniqueLanguages.add(entry.question_language);
      }
    });

    // Convert the set of languages to an array and update the state
    const uniqueLanguagesArray = Array.from(uniqueLanguages);
    setLanguages(uniqueLanguagesArray);
  }, [videos, questions]);

  // Function to add a new video to the videos array
  const addVideo = () => {
    // if (videoLink && videoLanguage) {
    if (videoLanguage) {
      setVideos([
        ...videos,
        { link: videoLink, video_language: videoLanguage },
      ]);
      // Reset the input fields for adding a video
      setVideoLink("");
      setVideoLanguage("");
    }
  };

  // Function to delete a video from the videos array
  const deleteVideo = video => {
    const updatedVideos = [...videos];
    const index = updatedVideos.indexOf(video);

    updatedVideos.splice(index, 1);
    setVideos(updatedVideos);
  };

  /**
   * Function to save the current content to the database.
   * If the content is valid (i.e., at least one video/language and one question), it will be saved.
   * If the content is not valid, an alert will be shown with an appropriate message.
   * After saving, a success message will be displayed for 3 seconds.
   */
  const saveContent = () => {
    if (videos.length === 0 || questions.length === 0) {
      alert(
        "You must have at least one video/language and one question to save."
      );
      return;
      // eslint-disable-next-line no-else-return
    } else if (!videos[0].link && additionalContent.length === 0) {
      alert("You must at least have a video or additional content to submit.");
      return;
    }
    setSavingContent(true);
    updateRow("training_modules", id, { videos, questions })
      .then(() => {
        setSavingContent(false);
        setAlertMessage("Content Saved");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      })
      .catch(error => {
        setSavingContent(false);
        alert(error.message);
      });
  };

  // Function to handle changes in the input fields for a new question
  const handleInputChange = event => {
    const { name, value } = event.target;
    setNewQuestion(prevQuestion => ({
      ...prevQuestion,
      [name]: value,
    }));
  };

  // Function to add a new question to the questions array
  const handleAddQuestion = () => {
    setQuestions([...questions, newQuestion]);
    // Reset the input fields for adding a question
    setNewQuestion({
      question: "",
      question_language: "",
      max_options: 0,
    });
  };

  // Function to delete a question from the questions array
  const deleteQuestion = index => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  return (
    <div>
      <BackLink />
      <h1>Training Module Content For: {decodeURI(moduleName)}</h1>
      {loadingPage ? (
        <h1>Loading...</h1>
      ) : (
        <>
          <div style={{ marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={7}>
                <h1>Video List</h1>
              </Grid>
              {videos.length === 0 && (
                <Grid item xs={12} sm={7}>
                  <Typography>There are no videos to display.</Typography>
                </Grid>
              )}
              <Grid item xs={12} sm={7}>
                <Typography>
                  To create a module without a video, enter the "Language" and
                  leave the "Video Link" blank.
                </Typography>
              </Grid>
              {videos.length > 0 &&
                languages.length > 0 &&
                languages.map(language => (
                  <Grid item xs={12} sm={7}>
                    <h3>{language}</h3>
                    {videos
                      .filter(entry => entry.video_language === language)
                      .map((video, index) => (
                        <Grid item xs={12} sm={7}>
                          <ListItem key={index}>
                            <ListItemText
                              primary={
                                video.link ? video.link : "No video added"
                              }
                              secondary={`Language: ${video.video_language}`}
                            />
                            <IconButton onClick={() => deleteVideo(video)}>
                              <FontAwesomeIcon icon={faTrash} color="#666666" />
                            </IconButton>
                          </ListItem>
                        </Grid>
                      ))}
                  </Grid>
                ))}
              <Grid item xs={12} sm={7}>
                <hr />
              </Grid>
              <Grid item xs={12} sm={7}>
                <TextField
                  fullWidth
                  label="Video Link"
                  value={videoLink}
                  onChange={e => setVideoLink(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={7}>
                <TextField
                  fullWidth
                  label="Language"
                  value={videoLanguage}
                  onChange={e => setVideoLanguage(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={7}>
                <Button variant="contained" color="primary" onClick={addVideo}>
                  Add Video/Language
                </Button>
              </Grid>
              <Grid item xs={12} sm={7}>
                <hr />
              </Grid>
            </Grid>

            {/* Add External Content */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={7}>
                <h1>Additional Content</h1>
              </Grid>
              {additionalContent.length === 0 && (
                <Grid item xs={12} sm={7}>
                  <Typography>
                    There are no additional content to display.
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} sm={7}>
                {additionalContent.length > 0 &&
                  additionalContent.map(file => {
                    const handleDelete = async () => {
                      setLoadingPage(true);
                      await supabase.storage
                        .from(uploadBucket)
                        .remove([`${id}/${file.fileName}`]);
                      fetchExternalContent().then(() => setLoadingPage(false));
                    };

                    return (
                      <Grid item xs={12} sm={7}>
                        <ListItem key={file.id}>
                          <ListItemText
                            primary={file.fileName}
                            secondary={<a href={file.downloadUrl}>Download</a>}
                          />
                          <IconButton onClick={handleDelete}>
                            <FontAwesomeIcon icon={faTrash} color="#666666" />
                          </IconButton>
                        </ListItem>
                      </Grid>
                    );
                  })}
              </Grid>

              <Grid item xs={12} sm={7}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setShowAdditionalContentModal(true);
                  }}
                >
                  Add Additional Content
                </Button>
              </Grid>
              <Grid item xs={12} sm={7}>
                <hr />
              </Grid>
            </Grid>

            {/* Add Additional Content dialog  */}
            <Dialog
              maxWidth="md"
              fullWidth
              height="100%"
              open={showAdditionalContentModal}
              onClose={() => {
                setShowAdditionalContentModal(false);
              }}
            >
              <DialogTitle>Add Additional Content</DialogTitle>
              <DialogContent>
                <SBFileUploader
                  bucketName={uploadBucket}
                  folderName={id}
                  withSubmit={fetchExternalContent}
                />
                <DialogActions>
                  <Button
                    onClick={() => {
                      setShowAdditionalContentModal(false);
                    }}
                    color="primary"
                  >
                    Done
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={7}>
                <h1>Question List</h1>
              </Grid>
              {questions.length === 0 && (
                <Grid item xs={12} sm={12}>
                  <Typography>There are no questions to display.</Typography>
                </Grid>
              )}
              {questions.length > 0 &&
                languages.length > 0 &&
                languages.map(language => (
                  <Grid item xs={12} sm={7}>
                    <h3>{language}</h3>
                    {questions
                      .filter(entry => entry.question_language === language)
                      .map((q, index) => (
                        <Grid item xs={12} sm={12}>
                          <ListItem key={index}>
                            <ListItemText
                              primary={q.question}
                              secondary={`Language: ${q.question_language}`}
                            />
                            <Button
                              style={{ width: 150 }}
                              onClick={() => {
                                const questionIndex = questions.indexOf(q);
                                setQuestionForOptions(questionIndex);
                                setQuestionOptionsList(q.options || []);
                                setQuestionTitle(q.question);
                                setMaxOptions(q.max_options);
                                setShowOptionsModal(true);
                              }}
                            >
                              Edit Question
                            </Button>
                            <IconButton
                              onClick={() => {
                                const questionIndex = questions.indexOf(q);
                                deleteQuestion(questionIndex);
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} color="#666666" />
                            </IconButton>
                          </ListItem>
                        </Grid>
                      ))}
                  </Grid>
                ))}
              <Grid item xs={12} sm={7}>
                <hr />
              </Grid>
              <Grid item xs={12} sm={7}>
                <TextField
                  label="Question"
                  name="question"
                  value={newQuestion.question}
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={7}>
                <TextField
                  label="Question Language"
                  name="question_language"
                  value={newQuestion.question_language}
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={7}>
                <TextField
                  label="Max Options"
                  name="max_options"
                  type="number"
                  value={newQuestion.max_options}
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={7}>
                <Button
                  disabled={
                    newQuestion.question_language === "" ||
                    newQuestion.question === "" ||
                    newQuestion.max_options === 0
                  }
                  variant="contained"
                  color="primary"
                  onClick={handleAddQuestion}
                >
                  Add Question
                </Button>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginTop: 20 }}>
            <Button
              style={{ height: 40 }}
              variant="contained"
              color="primary"
              onClick={saveContent}
            >
              {savingContent ? "Saving..." : "Save Changes"}
            </Button>
          </div>
        </>
      )}
      {/* Question Options Dialog */}
      <Dialog
        maxWidth="md"
        fullWidth
        height="100%"
        open={showOptionsModal}
        onClose={() => {
          setShowOptionsModal(false);
        }}
      >
        <DialogTitle>Edit Question</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Question"
            value={questionTitle}
            onChange={e => setQuestionTitle(e.target.value)}
          />
          <TextField
            style={{ marginTop: 20 }}
            fullWidth
            label="Max Options"
            value={maxOptions}
            onChange={e => setMaxOptions(e.target.value)}
          />
          <hr />
          <List>
            {questionOptionsList.map((question, index) => (
              <ListItem
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                key={index}
              >
                <TextField
                  style={{ marginTop: 20, width: "70%" }}
                  label="Question Text"
                  value={question.text}
                  onChange={e => {
                    const questionLocal = questionOptionsList[index];
                    questionLocal.text = e.target.value;
                    const updatedOptions = [...questionOptionsList];
                    updatedOptions[index] = questionLocal;
                    setQuestionOptionsList(updatedOptions);
                  }}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={question.is_correct}
                      onChange={e => {
                        const questionLocal = questionOptionsList[index];
                        questionLocal.is_correct = e.target.checked;
                        const updatedOptions = [...questionOptionsList];
                        updatedOptions[index] = questionLocal;
                        setQuestionOptionsList(updatedOptions);
                      }}
                    />
                  }
                  label="Correct"
                />
                <IconButton
                  onClick={() => {
                    const updatedOptions = [...questionOptionsList];
                    updatedOptions.splice(index, 1);
                    setQuestionOptionsList(updatedOptions);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} color="#666666" />
                </IconButton>
              </ListItem>
            ))}
          </List>
          <hr />
          <Grid
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            container
            spacing={2}
          >
            <Grid item xs={9} sm={9}>
              <TextField
                style={{ marginTop: 20, marginBottom: 20 }}
                label="New Answer"
                name="newQuestionOption"
                value={newQuestionOption}
                onChange={e => {
                  setNewQuestionOption(e.target.value);
                }}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3} sm={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setQuestionOptionsList([
                    ...questionOptionsList,
                    { text: newQuestionOption, is_correct: false },
                  ]);
                  setNewQuestionOption("");
                }}
              >
                Add Answer
              </Button>
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              onClick={() => {
                const updatedQuestions = [...questions];
                const questionLocal = updatedQuestions[questionForOptions];
                questionLocal.options = questionOptionsList;
                questionLocal.question = questionTitle;
                questionLocal.max_options = maxOptions;
                updatedQuestions[questionForOptions] = questionLocal;
                setQuestions(updatedQuestions);
                setShowOptionsModal(false);
              }}
              color="primary"
            >
              Done
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {showAlert && (
        <Alert
          style={{ width: "100%", marginTop: "0.5rem" }}
          severity="success"
        >
          {alertMessage}
        </Alert>
      )}
    </div>
  );
}

export default TrainingContent;
