import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";

/**
 * A component that displays the supporting documents for a sales lead.
 *
 * @param {object} props - The props reveived from calling the component
 * @param {object} props.lead - The sales lead object
 * @param {React.ReactElement} props.viewFiles - A component that displays the uploaded files
 * @param {Function} props.toggleUploadFilesModal - A function that toggles the upload files modal
 *
 * @returns {React.ReactElement} - A component that displays the supporting documents
 */
function Documents({ lead, viewFiles, toggleUploadFilesModal }) {
  return (
    <Grid container item direction="column" xs={12} spacing={2}>
      <Grid container item>
        <Typography variant="subtitle1" gutterBottom>
          Supporting Documents:
        </Typography>
        <Grid container item>
          {lead.supporting_document_ref ? (
            <Grid item container direction="rows">
              {viewFiles}
            </Grid>
          ) : (
            <Grid>
              <Typography variant="caption">No Files Uploaded</Typography>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={toggleUploadFilesModal}
          >
            Add Supporting Documents
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Documents;
