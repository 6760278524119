/* eslint-disable no-prototype-builtins */

/**
 * snakeToCamel
 *
 * Converts a JavaScript object from snake_case to camelCase.
 *
 * @param {object} snakeObj - The object to convert
 * @return {object} camelObj - The converted object
 */
function snakeToCamel(snakeObj) {
  /**
   * Converts a string from snake_case to camelCase.
   *
   * @param {string} snakeStr - The string to convert
   * @return {string} camelStr - The converted string
   */
  function toCamel(snakeStr) {
    return snakeStr.replace(/(_\w)/g, match => match[1].toUpperCase());
  }

  // Initialize a new object to hold the converted keys and values
  const camelObj = {};

  // Iterate through each key in the original object
  for (const key in snakeObj) {
    if (snakeObj.hasOwnProperty(key)) {
      // Convert the key to camelCase
      const camelKey = toCamel(key);
      // Assign the value to the new key in the camelCase object
      camelObj[camelKey] = snakeObj[key];
    }
  }

  return camelObj;
}

module.exports = snakeToCamel;
