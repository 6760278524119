/**
 * Retrieves the status name associated with a given status ID.
 *
 * @param {number|string} status - The ID of the status to find.
 * @param {Array<{id: number|string, status_name: string}>} statusList - An array of status objects, each containing an 'id' and 'status_name'.
 * @returns {string} The name of the status corresponding to the provided ID.
 */
function getStatusName(status, statusList) {
  return statusList.find(item => item.id === status).status_name;
}

module.exports = getStatusName;
