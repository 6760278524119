import React, { useEffect, useState } from "react";
import DealerDownstreamTable from "./DealerDownstreamTable";
import { useStructure } from "../../../contexts/StructureContext";

/**
 * Component to display the downstream of a given rank.
 * @param {object} props - The component props.
 * @param {object} props.rank - The rank to display the downstream for.
 * @returns {React.Component} The component displaying the downstream.
 */
export default function DealersDownstream({ rank }) {
  // const { GetDealerDownstreamOfLevel } = useDb();
  // const dealers = GetDealerDownstreamOfLevel(dealerId, rank);

  const { downstream } = useStructure();
  const [dealerList, setDealerList] = useState([]);

  useEffect(() => {
    const dealers = [];
    setDealerList([]);

    downstream
      .filter(agent => agent.rank === rank.level)
      .forEach(agent => {
        dealers.push(agent);
      });

    setDealerList(dealers);
  }, [rank]);

  return (
    <div>
      <DealerDownstreamTable dealers={dealerList} />
    </div>
  );
}
