import React, { useState } from "react";
import ForgotPassword from "./ForgotPassword";
import SignIn from "./Login";

/**
 * This component renders either the SignIn or ForgotPassword components
 * depending on the state of the showForgotPassword state variable.
 *
 * The toggleForgotPasswordView function is used to toggle the
 * showForgotPassword state variable and switch between the SignIn
 * and ForgotPassword components.
 *
 * @param {object} props - The properties for the component.
 * @param {string} props.logo - The logo image URL to display on the sign-in card.
 *
 * @returns {React.ReactElement} The rendered SignIn or ForgotPassword component.
 */
export default function Authenticate({ logo }) {
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const toggleForgotPasswordView = () => {
    setShowForgotPassword(!showForgotPassword);
  };

  return showForgotPassword ? (
    <ForgotPassword toggleForgotPasswordView={toggleForgotPasswordView} />
  ) : (
    <SignIn toggleForgotPasswordView={toggleForgotPasswordView} logo={logo} />
  );
}
