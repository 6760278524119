import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import SBFileDownloader from "../../shared/SBFileDownloader";

/**
 * DownloadSupportingDocsModal component displays a list of downloadable files from a specified bucket and folder.
 *
 * @param {object} props - The component props.
 * @param {string} props.bucket - The name of the bucket to fetch files from.
 * @param {string} [props.folderRef=""] - The reference to the folder within the bucket.
 *
 * @returns {React.Element} The rendered component.
 *
 * @example
 * <DownloadSupportingDocsModal bucket="my-bucket" folderRef="my-folder" />
 */
function DownloadSupportingDocsModal({ bucket, folderRef = "" }) {
  const [filesToDownload, setFilesToDownload] = useState([]);
  const [loading, setLoading] = useState(false);

  const displayMap = filesToDownload?.map(file => {
    return (
      <Grid item sm={12} key={file.id}>
        <a href={file.downloadUrl}>{file.fileName}</a>
      </Grid>
    );
  });

  const fetchFiles = async () => {
    setLoading(true);
    const files = await SBFileDownloader(bucket, folderRef);

    setFilesToDownload(files);
    setLoading(false);
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item sm={12}>
        <Typography variant="h4">Supporting Documents</Typography>
      </Grid>
      <Grid container item spacing={1} sm={12}>
        {loading ? (
          <Typography variant="subtitle1">Loading...</Typography>
        ) : (
          displayMap
        )}
      </Grid>
    </Grid>
  );
}

export default DownloadSupportingDocsModal;
