import React from "react";
import { Switch, Route } from "react-router-dom";
import Authenticate from "../authenticate/Authenticate";
import PhoneLogin from "../authenticate/PhoneLogin";

/**
 * PublicRoutes component renders the public routes for the application.
 *
 * @param {object} props - The component props.
 * @param {string} props.logo - The logo to be passed to the Authenticate component.
 *
 * @returns {React.Element} The rendered component.
 */
export default function PublicRoutes({ logo }) {
  return (
    <Switch>
      <Route exact path="/">
        <Authenticate logo={logo} />
      </Route>
      <Route path="/login" component={Authenticate}>
        <Authenticate logo={logo} />
      </Route>
      <Route path="/phone-login" component={PhoneLogin} />
      <Route path="*" component={Authenticate} />
    </Switch>
  );
}
