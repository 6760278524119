import { Button, DialogActions, Typography } from "@material-ui/core";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Save } from "@material-ui/icons";
import { selectFieldProps } from "../shared/constants";
import { useSB } from "../../contexts/SupabaseContext";
import transformRank from "./functions/transformRank";
import { useCompany } from "../../contexts/CompanyContext";
import { supabase } from "../../supabase";
/**
 * Component for changing the level of a dealer.
 *
 * @component
 * @param {object} props - Component props.
 * @param {boolean} props.open - Whether the dialog is open.
 * @param {Function} props.close - Function to close the dialog.
 * @param {object} props.dealer - Dealer details.
 * @param {number} props.parentLevel - Parent level of the dealer.
 * @returns {React.ReactElement} The ChangeDealerLevel component.
 */
export default function ChangeDealerLevel({
  open,
  close,
  dealer,
  parentLevel,
}) {
  const { updateRow } = useSB();
  const { dealerLevels } = useCompany();

  const [newLevel, setNewLevel] = useState();
  const [lowestLevel, setLowestLevel] = useState(9);
  const [state, setState] = useState({
    loading: false,
    error: null,
  });

  useEffect(async () => {
    const { data, error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("dealers")
      .select("rank", { distinct: true })
      .eq("parent_dealer", dealer.dealer_code);

    if (error) {
      console.error("Error fetching unique ranks:", error);
      return [];
    }

    let ranks = data.map(record => record.rank);

    ranks = [...new Set(ranks)];

    ranks = ranks.sort((a, b) => a - b);

    const lastValue = ranks[ranks.length - 1];

    if (lastValue < 8 && lastValue > 0) {
      // Calculate how many numbers to add
      const numbersToAdd = 8 - lastValue;

      // Add the necessary numbers to the array
      for (let i = 1; i <= numbersToAdd; i++) {
        ranks.push(lastValue + i);
      }
    }

    // Test if ranks has a value
    if (ranks.length > 0) {
      // Set lowest level based on downstream rank
      ranks = ranks.filter(rank => rank !== dealer.rank);
      setLowestLevel(ranks[ranks.indexOf(dealer.rank) + 1]);
    }
  }, []);

  const agentLevelOptions = dealerLevels.map(level => ({
    label: `${level.level_name} (${level.level})`,
    value: level.level,
  }));

  const handleClose = () => {
    setNewLevel(null);
    setState({
      loading: false,
      error: null,
    });
    close();
  };

  const handleSelect = v => {
    setNewLevel(v.value);
  };

  const handleSubmit = async () => {
    setState({
      loading: true,
      error: null,
    });
    updateRow("dealers", dealer.id, {
      rank: newLevel,
    })
      .then(() => {
        handleClose();
      })
      .catch(error => {
        setState({
          loading: false,
          error: error.message,
        });
      });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Change Level</DialogTitle>
      <DialogContent>
        <Typography>
          Current Level: <b>{transformRank(dealer.rank)}</b>
        </Typography>
        <br />
        <Select
          options={agentLevelOptions.filter(
            option =>
              option.value > parentLevel &&
              option.value < lowestLevel &&
              option.value !== dealer.rank
          )}
          {...selectFieldProps}
          className="sim-select"
          onChange={handleSelect}
        />
        <br />
        {state.error ? (
          <p style={{ whiteSpace: "pre-wrap" }}>{state.error}</p>
        ) : (
          <Typography>
            Note: You can not change a level higher than that of this persons
            manager. If you need to do that, first change the person's manager.
            <br />
            <br />
            You also cannot move this person below the highest agent below them.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={handleClose}
          disabled={state.loading}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<Save />}
          disabled={state.loading || !newLevel}
          onClick={handleSubmit}
        >
          {state.loading ? "Loading..." : "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
