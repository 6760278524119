import { supabase } from "../../../supabase";

/**
 * Checks if the given email is already used in the "dealers" table.
 *
 * @param {string} email - The email address to check.
 * @returns {Promise<boolean>} A promise that resolves to true if the email is used, otherwise false.
 */
export default function checkEmailIsUsed(email) {
  return new Promise(resolve => {
    supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("dealers")
      .select()
      .eq("email", email)
      .then(result => {
        resolve(result.data.length > 0);
      })
      .catch(err => {
        console.error(err);
        // Return true if error for safety.
        resolve(true);
      });
  });
}
