import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { pageTitleStyles } from "../../../mui/styles";
import AddBanner from "./AddBanner";
import Banner from "../../global/Banner";

/**
 * BannerSettings component renders the settings for the banner.
 * It includes a button to open a dialog for adding a new banner.
 *
 * @component
 * @example
 * return (
 *   <BannerSettings />
 * )
 *
 * @returns {React.Element} The rendered component.
 */
export default function BannerSettings() {
  const [banner, setBanner] = useState({ images: [] });
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography
        variant="h4"
        style={{
          ...pageTitleStyles,
          fontWeight: "bold",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span style={{ textAlign: "left" }}>Banner Settings</span>
        <span style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            style={{ color: "theme.palette.primary.main", padding: 0 }}
            onClick={handleClickOpen}
          >
            <Typography variant="h4">+</Typography>
          </Button>
        </span>
      </Typography>

      {/* Dialog component */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Banner</DialogTitle>
        <DialogContent>
          <AddBanner
            open={open}
            close={handleClose}
            banner={banner}
            setBanner={setBanner}
            folder="dash"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Banner />
    </div>
  );
}
