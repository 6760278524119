/* eslint-disable jsdoc/require-param */
import { supabase } from "../../../supabase";
import isModuleRelevant from "./isModuleRelevant";

/**
 * Checks if the user has passed the given module.
 * @param {object} module - The module to check.
 * @returns {object} - The module with additional data on attempts and pass status.
 */
const checkPassedModules = async (module, user) => {
  const { data: attemptsList, error } = await supabase
    .schema(process.env.REACT_APP_SB_SCHEMA)
    .from("training_attempts")
    .select()
    .eq("module_id", module.id)
    .eq("dealer_id", user.id);

  if (error) {
    console.error("Error fetching attempts:", error);
    return { ...module, passed: 0, attemptsList: [] };
  }

  let passed = 0;
  if (attemptsList) {
    passed = attemptsList.filter(attempt => attempt.pass === true).length;
  }

  return { ...module, passed, attemptsList };
};

/**
 * Checks the user's status.
 * @param {object} user - The user object with relevant info.
 * @returns {Promise} - A promise resolving to an object with a single key-value pair, { status: boolean }.
 * status: true if the user has passed all relevant modules, false otherwise.
 */
export default function checkUserStatus(user) {
  return new Promise(resolve => {
    if (!user.email_verified || !user.phone_verified || user.status !== 2) {
      resolve({
        status: false,
      });
    } else {
      // Check Training Status Here.
      const { rank, selected_product_types, selected_suppliers } = user;

      supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("training_modules")
        .select()
        .eq("active", true)
        .eq("is_deleted", false)
        .then(async modules => {
          // Initialize an empty array to store relevant modules.
          const moduleArray = [];

          // Iterate through the retrieved modules and check their relevance to the user.
          modules?.data?.forEach(module => {
            if (
              isModuleRelevant(
                module,
                rank,
                selected_product_types,
                selected_suppliers
              ) // Only push relevant modules
            ) {
              moduleArray.push(module);
            }
          });

          const modulesWithAttempts = await Promise.all(
            moduleArray.map(item => {
              return checkPassedModules(item, user);
            })
          );

          const passedModules = modulesWithAttempts?.filter(module => {
            return module.passed >= 1 && module.active;
          });

          if (
            passedModules.length < moduleArray.length &&
            user.user_type === 1
          ) {
            resolve({
              status: false,
            });
          } else {
            resolve({
              status: true,
            });
          }
        });
    }
  });
}
