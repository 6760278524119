const { provinces } = require("../constants");

const NULL_PROVINCE = {
  label: null,
  value: null,
};

/**
 * Retrieves details of a province based on a given key.
 *
 * The function searches through a list of provinces to find a match where the
 * province's label or value matches or includes the given key. If a match is
 * found, it returns the province details; otherwise, it returns a NULL_PROVINCE
 * object.
 *
 * @param {string} key - The key to search for, which can be a full or partial
 *                       match of a province's label or value.
 * @returns {object} - An object containing the province details if found, or
 *                     a NULL_PROVINCE object if no match is found.
 */
function getProvinceDetails(key) {
  if (!key || typeof key !== "string") {
    return NULL_PROVINCE;
  }

  const keyString = String(key).toLowerCase();

  let province = provinces.find(
    b =>
      b.label.toLowerCase() === keyString || b.value.toLowerCase() === keyString
  );

  if (!province || !province.value || !province.label) {
    province = provinces.find(
      b =>
        b.label.toLowerCase().includes(keyString) ||
        b.value.toLowerCase().includes(keyString)
    );
  }

  if (!province || !province.value || !province.label) {
    return NULL_PROVINCE;
  }

  return province;
}

module.exports = getProvinceDetails;
