import { Collapse, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Alert } from "@mui/material";
import React from "react";

/**
 * A SuccessAlert component that takes a boolean "open" prop and an
 * setOpen function to close the alert when the close button is clicked.
 *
 * The alert displays the given text and a close button. The alert is
 * rendered inside a Collapse component, which will only render the
 * component if the "open" prop is true.
 * @param {object} props - The props object.
 * @param {boolean} props.open - Indicates whether the alert is open.
 * @param {Function} props.setOpen - Function to close the alert.
 * @param {string} props.text - The text to display in the alert.
 *
 * @returns {React.Component} The SuccessAlert component.
 */
export default function SuccessAlert({ open, setOpen, text }) {
  return (
    <Collapse in={open}>
      {/* Alert component with a close button */}
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close />
          </IconButton>
        }
      >
        {text}
      </Alert>
    </Collapse>
  );
}
