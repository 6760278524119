/* eslint-disable jsdoc/check-param-names */
/* eslint-disable jsdoc/require-param */
/* eslint-disable no-alert */
// University Modules Tiles

// Phone Verification Tilesimport React from 'react';
import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  Snackbar,
  Button,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { useSB } from "../../contexts/SupabaseContext";
import * as API from "../../api";
import isModuleRelevant from "../shared/functions/isModuleRelevant";

/**
 * A component that displays the user's training and verification status.
 *
 * The component is split into two sections. The first section displays the user's
 * training status, showing the number of modules they have passed out of the total.
 * The second section displays the user's verification status, showing if their phone
 * and email addresses have been verified or not.
 *
 * If the user is not verified, the component provides a button to resend the verification
 * link or OTP.
 *
 * @param {object} currentUser - The current user's data.
 * @param {boolean} otherProfile - If the component is being used to display another user's profile.
 */
export default function SnapshotTiles({ currentUser, otherProfile }) {
  const { GetTableWhere } = useSB();
  const [loading, setLoading] = useState(true);
  const [moduleCount, setModuleCount] = useState(0);
  const [passedModuleCount, setPassedModuleCount] = useState(0);
  const [emailVerified, setEmailVerified] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [loadingSendOTP, setLoadingSendOTP] = useState(false);
  const [loadingSendEmail, setLoadingSendEmail] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const history = useHistory();

  /**
   * Checks if the current user needs training by evaluating relevant training modules
   * and their passed attempts.
   *
   * The function first retrieves all active training modules from the database,
   * then filters them based on the user's rank, selected product types, and selected
   * suppliers. It then checks if the user has passed each relevant module and
   * stores the results in the state.
   *
   * @returns {Promise<void>} - Resolves when the check is complete.
   */
  const checkForTraining = async () => {
    // Destructure the current user's rank, selected product types, and selected suppliers.
    const { rank, selected_product_types, selected_suppliers } = currentUser;

    // Alias the selected product types and suppliers for ease of use.
    const selectedProductTypes = selected_product_types;
    const selectedSuppliers = selected_suppliers;

    // Retrieve all active training modules from the database.
    const modules = await GetTableWhere(
      "training_modules",
      ["active", "eq", true],
      ["is_deleted", "eq", false]
    );

    // Initialize an empty array to store relevant modules.
    const moduleArray = [];

    // Iterate through the retrieved modules and check their relevance to the user.
    modules?.forEach(module => {
      if (
        isModuleRelevant(module, rank, selectedProductTypes, selectedSuppliers) // Only push relevant modules
      ) {
        moduleArray.push(module);
      }
    });

    // Set the module count based on the number of relevant modules.
    setModuleCount(moduleArray.length);

    /**
     * Checks if the user has passed the given module.
     * @param {object} module - The module to check.
     * @returns {object} - The module with additional data on attempts and pass status.
     */
    const checkPassedModules = async module => {
      // Retrieve the user's attempts for the given module.
      const attemptList = await GetTableWhere(
        "training_attempts",
        ["module_id", "eq", module.id], // Match the module ID
        ["dealer_id", "eq", currentUser.id] // Match the user's ID (dealer)
      );

      // Filter attempts that were marked as passed.
      const passed = attemptList.filter(
        attempt => attempt.pass === true
      ).length;

      // Return the module with added pass status and attempt list.
      return { ...module, passed, attemptList };
    };

    // For each relevant module, check the user's pass status.
    const modulesWithAttempts = await Promise.all(
      moduleArray.map(checkPassedModules)
    );

    // Filter the modules where the user has passed more than one attempt and the module is active.
    const passedModules = modulesWithAttempts.filter(module => {
      return module.passed >= 1 && module.active;
    });

    // Set the count of passed modules and stop loading.
    setPassedModuleCount(passedModules.length);
    setLoading(false);
  };

  useEffect(() => {
    checkForTraining(); // Perform the training check.
    setPhoneVerified(currentUser.phone_verified);
    setEmailVerified(currentUser.email_verified);
  }, [currentUser]);

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
            padding: 20,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Grid style={{ marginTop: 10 }} container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Card style={{ minHeight: 200 }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  Zawadi University Status
                </Typography>
                <Typography
                  style={{ marginTop: 10 }}
                  variant="body2"
                  color="text.secondary"
                >
                  {otherProfile
                    ? `${currentUser.contact_name} has`
                    : "You have"}{" "}
                  passed {passedModuleCount} out of {moduleCount} modules.
                </Typography>
                {!otherProfile && passedModuleCount < moduleCount && (
                  <div>
                    <Button
                      onClick={() => {
                        history.push("/training");
                      }}
                      color="primary"
                      style={{ marginTop: 10 }}
                    >
                      Continue Training
                    </Button>
                  </div>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card style={{ minHeight: 200 }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  Details Verification Status
                </Typography>
                <div
                  style={{
                    marginTop: 10,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      marginRight: 30,
                    }}
                  >
                    <Typography variant="h6" component="div">
                      Phone
                    </Typography>
                    <div
                      style={{
                        marginTop: 10,
                      }}
                    >
                      {phoneVerified ? (
                        <Typography variant="body2" color="text.secondary">
                          {otherProfile
                            ? `${currentUser.contact_name}'s`
                            : "Your"}{" "}
                          Phone Number has been verified
                        </Typography>
                      ) : (
                        <div>
                          <Typography variant="body2" color="text.secondary">
                            {otherProfile
                              ? `${currentUser.contact_name}'s`
                              : "Your"}{" "}
                            Phone Number has not been verified
                          </Typography>
                          {!otherProfile && (
                            <Button
                              disabled={loadingSendOTP}
                              onClick={async () => {
                                setLoadingSendOTP(true);
                                const updateAndSendVerification =
                                  await API.updateEmailAndPhone({
                                    authId: currentUser.auth_id,
                                    email: currentUser.email,
                                    phone: currentUser.phone,
                                    emailChanged: false,
                                    phoneChanged: true,
                                  });

                                if (
                                  updateAndSendVerification.data.code === 200
                                ) {
                                  setLoadingSendOTP(false);
                                  history.push("/verify-phone");
                                } else {
                                  setLoadingSendOTP(false);
                                  alert(
                                    "There has been an error, please try again or contact support."
                                  );
                                }
                              }}
                              color="primary"
                              style={{ marginTop: 10 }}
                            >
                              {loadingSendOTP ? "Loading..." : "Resend OTP"}
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography variant="h6" component="div">
                      Email
                    </Typography>
                    <div
                      style={{
                        marginTop: 10,
                      }}
                    >
                      {emailVerified ? (
                        <Typography variant="body2" color="text.secondary">
                          {otherProfile
                            ? `${currentUser.contact_name}'s`
                            : "Your"}{" "}
                          Email Address has been verified
                        </Typography>
                      ) : (
                        <div>
                          <Typography variant="body2" color="text.secondary">
                            {otherProfile
                              ? `${currentUser.contact_name}'s`
                              : "Your"}{" "}
                            Email Address has not been verified
                          </Typography>
                          {!otherProfile && (
                            <Button
                              disabled={loadingSendEmail}
                              onClick={async () => {
                                setLoadingSendEmail(true);
                                const updateAndSendVerification =
                                  await API.updateEmailAndPhone({
                                    authId: currentUser.auth_id,
                                    email: currentUser.email,
                                    phone: currentUser.phone,
                                    emailChanged: true,
                                    phoneChanged: false,
                                  });

                                if (
                                  updateAndSendVerification.data.code === 200
                                ) {
                                  setLoadingSendEmail(false);
                                  setSnackBarMessage(
                                    "Email verification link has been sent to your email address."
                                  );
                                  setIsSnackbarOpen(true);
                                  // Show Alert
                                } else {
                                  setLoadingSendEmail(false);
                                  alert(
                                    "There has been an error, please try again or contact support."
                                  );
                                }
                              }}
                              color="primary"
                              style={{ marginTop: 10 }}
                            >
                              {loadingSendEmail
                                ? "Loading..."
                                : "Resend Verification Email"}
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <Snackbar
                    open={isSnackbarOpen}
                    autoHideDuration={4000}
                    onClose={() => setIsSnackbarOpen(false)}
                  >
                    <Alert
                      severity="success"
                      onClose={() => setIsSnackbarOpen(false)}
                    >
                      {snackBarMessage}
                    </Alert>
                  </Snackbar>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
