import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { Slider } from "@mui/material";
import { Alert } from "@material-ui/lab";
import { pageTitleStyles } from "../../../mui/styles";
import { useCompany } from "../../../contexts/CompanyContext";
import BackLink from "../../shared/BackLink";
import ProductFormValidation from "./ProductFormValidation";

/**
 * EditProduct component allows editing of a product's details.
 *
 * @component
 * @param {object} props - The component props.
 * @param {object} props.match - The match object from react-router.
 * @returns {React.Element} The rendered component.
 *
 * @example
 * <EditProduct match={match} />
 *
 * @typedef {object} FormValues
 * @property {string} product_type - The type of the product.
 * @property {string} product - The name of the product.
 * @property {string} product_description - The description of the product.
 * @property {string} supplier_id - The ID of the supplier.
 * @property {string} status - The status of the product.
 * @property {number} commission - The commission for the product.
 * @property {number} customer_price - The customer price of the product.
 * @property {number} ogr - The OGR percentage.
 */
export default function EditProduct({ match }) {
  const history = useHistory();
  const { activeProductTypes, upsertProduct, getProduct } = useCompany();
  const [loading, setLoading] = useState(true);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState();
  const [activeProductType, setActiveProductType] = useState({});

  const [formValues, setFormValues] = useState({
    product_type: "",
    product: "",
    product_description: "",
    supplier_id: "",
    status: "",
    commission: 0,
    customer_price: 0,
    ogr: 0,
  });

  useEffect(async () => {
    setFormValues(await getProduct(match.params.id));
  }, []);

  useEffect(() => {
    setActiveProductType(
      activeProductTypes.filter(
        productType => productType.id === formValues.product_type
      )[0]
    );
    setLoading(false);
  }, [formValues.product_type]);

  const { max_ogr: maxOgr } = activeProductType ?? 0;

  const ogrMarks = [
    { value: 0, label: "0%" },
    { value: maxOgr * 100, label: "Maximum set OGR %" },
    { value: maxOgr * 100 * 1.3, label: `${maxOgr * 100 * 1.3}%` },
  ];

  // handle changes on form values
  const handleChange = event => {
    const { name, value } = event.target;
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  // handle slider changes

  const handleSliderChangeOgr = event => {
    const { name, value } = event.target;
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      [name]: value / 100,
    }));
    if (formValues.ogr > maxOgr) {
      setError(
        "Warning: The OGR percentage exceeds the maximum percentage set in Product Types. Please consider adjusting the maximum percentage accordingly."
      );
      setSnackbarOpen(true);
    } else if (snackbarOpen) setSnackbarOpen(false);
  };

  // submit to supabase
  const handleEditProduct = async () => {
    ProductFormValidation(formValues)
      .then(async () => {
        if (
          Object.values(formValues).every(
            value => value !== "" && value !== "loading..."
          )
        ) {
          await upsertProduct({
            ...formValues,
            commission: parseInt(formValues.commission),
          });
          history.push(`/admin/Supplier/${formValues.supplier_id}`);
        } else {
          setError("Please complete all the fields.");
          setSnackbarOpen(true);
        }
      })
      .catch(err => {
        setError(err);
        setSnackbarOpen(true);
      });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      {" "}
      <BackLink />
      <h1 style={pageTitleStyles}>Edit Product</h1>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <Grid container xs={12} spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Product"
              placeholder="Product"
              variant="outlined"
              required
              name="product"
              value={formValues.product}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Product Description"
              variant="outlined"
              required
              name="product_description"
              value={formValues.product_description}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel id="product-type-label">Product Type</InputLabel>
              <Select
                value={formValues.product_type}
                fullWidth
                variant="outlined"
                required
                name="product_type"
                onChange={handleChange}
                label="Product Type"
              >
                {activeProductTypes.map(item => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                value={formValues.status}
                fullWidth
                variant="outlined"
                required
                name="status"
                onChange={handleChange}
                label="Status"
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Testing">Testing</MenuItem>
                <MenuItem value="Cancelled">Cancelled</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Commission VAT Exclusive"
              variant="outlined"
              required
              name="commission"
              value={formValues.commission}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Customer Price VAT Inclusive"
              variant="outlined"
              required
              name="customer_price"
              value={formValues.customer_price}
              onChange={handleChange}
              fullWidth
            />
          </Grid>{" "}
          <Grid item xs={12} md={8}>
            <Typography id="continuous-slider" gutterBottom>
              OGR %
            </Typography>
            <Slider
              name="ogr"
              value={formValues.ogr ? formValues.ogr * 100 : 0}
              aria-label="ogr"
              ariaValueText="ogr"
              onChange={handleSliderChangeOgr}
              aria-labelledby="continuous-slider"
              valueLabelDisplay="auto"
              min={0}
              max={maxOgr * 100 * 1.3}
              step={0.1}
              marks={ogrMarks}
              color={formValues.ogr > maxOgr ? "secondary" : "primary"}
            />
          </Grid>{" "}
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleEditProduct}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
}
