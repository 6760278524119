import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

/**
 * A material-ui Dialog component for confirming actions.
 * @param {object} props - The props object.
 * @param {boolean} props.open - Indicates whether the dialog is open.
 * @param {string} props.message - The message to display to the user.
 * @param {Function} props.onConfirm - The function to run when the user confirms.
 * @param {Function} props.onClose - The function to run when the user cancels.
 * @param {boolean} props.loading - Indicates whether the confirmation is loading.
 *
 * @returns {React.Component} A Dialog component.
 */
export default function ConfirmationDialog({
  open,
  message,
  onConfirm,
  onClose,
  loading,
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={loading}
          onClick={onConfirm}
          color="primary"
          autoFocus
        >
          {loading ? "Loading..." : "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
