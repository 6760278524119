/* eslint-disable jsdoc/check-param-names */
/* eslint-disable jsdoc/check-types */
/* eslint-disable jsdoc/require-param */
/**
 * Exports data to a CSV file and triggers the download.
 *
 * @param {Array<object>} data - The data to be exported as CSV.
 * @param {String} report_name - The name of the CSV file.
 */
export default function exportToCsv(data, reportName) {
  if (data[0]) {
    const headerMap = Object.keys(data[0]).join(",");

    const dataMap = data.map(row => Object.values(row).join(",")).join("\n");

    const csvContent = `data:text/csv;charset=utf-8,${headerMap}\n${dataMap}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${reportName}.csv`);
    document.body.appendChild(link);
    link.click();
  }
}
