/**
 * DealerContractsForm component renders a dialog for managing dealer contracts.
 * It allows users to generate new contracts and view previous contracts.
 *
 * @component
 * @param {object} props - The properties passed to the component.
 * @param {boolean} props.dialogIsOpen - Determines if the dialog is open.
 * @param {Function} props.closeDialog - Function to close the dialog.
 * @param {object} props.dealerProp - Dealer properties.
 * @param {string} props.dealerProp.dealer_code - Dealer code.
 * @param {string} props.dealerProp.email - Dealer email.
 *
 * @example
 * <DealerContractsForm
 *   dialogIsOpen={true}
 *   closeDialog={() => {}}
 *   dealerProp={{ dealer_code: "123", email: "dealer@example.com" }}
 * />
 */
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Alert from "@material-ui/lab/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import ReactSelect from "react-select";
import * as Sentry from "@sentry/react";
import generateNewDealerContract from "./functions/contracts/generateNewDealerContract";
import updateDealerContractsTable from "./functions/contracts/updateContractsTable";
import { getAgentContracts, getContractDownloadLink } from "../../api";
import clickupTicket from "./functions/contracts/clickupTicket";

/**
 * DealerContractsForm Component
 *
 * This component renders a form for managing dealer contracts. It includes functionality
 * for generating new contracts and displaying previous agent contracts. The form is
 * displayed within a dialog and includes options for selecting contract types and annexures.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {boolean} props.dialogIsOpen - Indicates whether the dialog is open.
 * @param {Function} props.closeDialog - Function to close the dialog.
 * @param {object} props.dealerProp - Object containing dealer properties.
 * @param {string} props.dealerProp.dealer_code - The dealer code.
 * @param {string} props.dealerProp.email - The dealer's email.
 * @param {number} props.dealerProp.id - The agent ID.
 *
 */
export default function DealerContractsForm(props) {
  const { dialogIsOpen, closeDialog } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [contracts, setContracts] = useState([]);
  const [error] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const arrApendixes = ["302759"]; // annexures for new Dealers contract, the code provided is the main contract that should be included in all contracts
  const [contractLoading, setContractLoading] = useState(false);
  const [contractGenerated, setContractGenerated] = useState(false);
  const [annexureA, setAnnexureA] = useState("");
  const [annexureB, setAnnexureB] = useState("");

  const handleSelectChange = selectedOption2 => {
    setSelectedOption(selectedOption2);
  };
  const contractOptions = [
    { value: "dealer_contract", label: "New Dealer Contract" },
  ];

  useEffect(() => {
    getAllSubMissions();
  }, [contractGenerated]);
  /**
   * Fetches all submissions related to the dealer's contracts.
   *
   * This function retrieves the dealer code from the props, makes an API call to fetch
   * the agent contracts using the dealer code, and sets the contracts state with the
   * retrieved data. If the network response is not OK or any other error occurs during
   * the fetch operation, it logs an error message to the console.
   *
   * @async
   * @function getAllSubMissions
   * @throws {Error} Throws an error if the network response is not OK or if there is a problem with the fetch operation.
   */
  const getAllSubMissions = async () => {
    try {
      const {
        dealerProp: { dealer_code },
      } = props;
      const response = await getAgentContracts({ agentCode: dealer_code });
      if (!response.statusText === "OK") {
        throw new Error("Network response was not ok");
      }
      setContracts(response.data.data);
    } catch (error2) {
      console.error("There was a problem with the fetch operation:", error2);
    }
  };

  // get the download link for the contract from the backend api
  const downloadContract = async contract => {
    const response = await getContractDownloadLink({ id: contract.id });
    if (response.status === 200) {
      if (response.data.status === 404) {
        Sentry.captureMessage("Contract not found", contract);
      } else {
        window.open(response.data.documents[0].url, "_blank");
      }
    }
  };

  return (
    <div>
      <Dialog
        open={dialogIsOpen}
        onClose={() => {
          closeDialog();
        }}
        fullScreen={fullScreen}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: "16px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            minHeight: "80vh",
          },
        }}
      >
        <DialogTitle
          style={{
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
          }}
        >
          Dealer Contracts
        </DialogTitle>
        <DialogContent>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div>
              <h2>Generate New Contract</h2>

              <ReactSelect
                value={selectedOption}
                onChange={handleSelectChange}
                options={contractOptions}
                placeholder="Select contract type"
              />
              {selectedOption !== null &&
                selectedOption.value === "dealer_contract" && (
                  <div>
                    <div>
                      <p> Annexure A</p>
                      <div>
                        <label>
                          <input
                            type="radio"
                            name="annexureA"
                            value="306180"
                            onChange={() => setAnnexureA("306180")}
                          />
                          Annexure A - SADV Resellers
                        </label>
                      </div>
                      <div>
                        <label>
                          <input
                            type="radio"
                            name="annexureA"
                            value="305527"
                            onChange={() => setAnnexureA("305527")}
                          />
                          Annexure A - SADV Super Dealer
                        </label>
                      </div>
                      <div>
                        <label>
                          <input
                            type="radio"
                            name="annexureA"
                            value="306181"
                            onChange={() => setAnnexureA("306181")}
                          />
                          Annexure A - SADV Standard Leader Commission
                        </label>
                      </div>
                      <div style={{ paddingBottom: "4px" }}>
                        <label>
                          <input
                            type="radio"
                            name="annexureA"
                            value="306183"
                            onChange={() => setAnnexureA("306183")}
                          />
                          Annexure A - SADV Multiple Teams Onboarding Growth
                          TL1%
                        </label>
                      </div>
                    </div>
                    <div>
                      <p> Annexure B</p>
                      <div>
                        <label>
                          <input
                            type="radio"
                            value="302783"
                            name="annexureB"
                            onChange={() => setAnnexureB("302783")}
                          />
                          Annexure B - Herotel Resellers
                        </label>
                      </div>
                      <div>
                        <label>
                          <input
                            type="radio"
                            value="306177"
                            name="annexureB"
                            onChange={() => setAnnexureB("306177")}
                          />
                          Annexure B - Herotel Super dealer
                        </label>
                      </div>
                      <div>
                        <label>
                          <input
                            type="radio"
                            value="306185"
                            name="annexureB"
                            onChange={() => setAnnexureB("306185")}
                          />
                          Annexure B - Herotel Standard Leader Commission
                        </label>
                      </div>
                      <div style={{ paddingBottom: "8px" }}>
                        <label>
                          <input
                            type="radio"
                            value="306174"
                            name="annexureB"
                            onChange={() => setAnnexureB("306174")}
                          />
                          Annexure B - Herotel Multiple Teams Onboarding Growth
                          TL1%
                        </label>
                      </div>
                    </div>

                    {!contractLoading && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setContractLoading(true);
                          const {
                            dealerProp: {
                              dealer_code,
                              email,
                              id: agent_id,
                              contact_name,
                              contact_surname,
                            },
                          } = props;
                          let templateIDs = [];
                          templateIDs.push(arrApendixes[0]);
                          templateIDs.push(annexureA);
                          templateIDs.push(annexureB);
                          generateNewDealerContract(
                            dealer_code,
                            email,
                            templateIDs
                          ).then(result => {
                            updateDealerContractsTable(agent_id, templateIDs);
                            clickupTicket(
                              `New Dealer Contract - ${dealer_code}: ${contact_name} ${contact_surname}`
                            );
                            setContractLoading(false);
                            if (result.status === 200) {
                              templateIDs = [];
                              setContractGenerated(true);
                            } else {
                              // eslint-disable-next-line no-alert
                              alert("Error generating contract");
                            }
                          });
                        }}
                      >
                        Generate New Dealer Contract
                      </Button>
                    )}
                    {contractLoading && (
                      <p style={{ fontWeight: "bold" }}>
                        Generating Contract...
                      </p>
                    )}
                    {contractGenerated && (
                      <p style={{ fontWeight: "bold" }}>
                        Contract generated successfully.
                      </p>
                    )}
                  </div>
                )}
              <br />
              <hr style={{ border: "1px solid #ccc", margin: "20px 0" }} />
              <h2>Previous Agent Contracts</h2>
              {contracts && contracts.length > 0 ? (
                contracts.map((contract, index) => (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    style={{
                      borderBottom: "1px solid #ccc",
                      backgroundColor: "#f5f5f5",
                      padding: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <h3>{contract.template.name}</h3>
                    <p>Contract Status: {contract.status}</p>
                    <p>
                      Submission Date:{" "}
                      {moment(contract.submissionDate).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </p>
                    {contract.status === "completed" && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          downloadContract(contract);
                        }}
                      >
                        Open Signed Contract
                      </Button>
                    )}
                  </div>
                ))
              ) : (
                <p>No contracts found.</p>
              )}
            </div>
          </MuiPickersUtilsProvider>
        </DialogContent>

        {error && (
          <Alert
            style={{ width: "100%", marginBottom: "1rem" }}
            severity="error"
          >
            {error}
          </Alert>
        )}
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              closeDialog();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
