import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Snackbar,
  TextField,
  MenuItem,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Backdrop,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import nanoId from "nano-id";
import Markdown from "react-markdown";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useAuth } from "../../../../../contexts/AuthContext";
import { useCompany } from "../../../../../contexts/CompanyContext";
import { supabase } from "../../../../../supabase";
import {
  // salesLeadCategories,
  maritalStatusOptions,
  agentAssistIndustryTypes,
  salesLeadPaymentTypes,
} from "../../../../shared/constants";

import SBFileUploader from "../../../../shared/SBFileUploader";
import ModalCard from "../../../../shared/ModalCard";

import sendAssistMessage from "../../../../shared/functions/sendAssistMessage.function";
import formatTicketNumber from "../../../../shared/functions/formatTicketNumber";
import { sendEmail } from "../../../../../api";

/**
 * AddLeadModal is a React component that renders a modal for adding a lead.
 *
 * @param {object} props - The properties passed to the component.
 * @param {string} props.supplier - The supplier of the lead.
 * @param {Function} props.onClose - A function to be called when the modal is closed.
 * @param {boolean} props.open - A boolean indicating whether the modal is open or not.
 *
 * @returns {React.Component} The rendered AddLeadModal component.
 */
export default function AddLeadModal({ supplier, onClose, open }) {
  const uploadBucket = "sales_assist";
  const { currentUser } = useAuth();
  const { getSupplier } = useCompany();

  const defFormValues = {
    platform: "App",
    dealer_code: currentUser.dealer_code,
    supplier,
    address: "",
    phone_number: "",
    alternative_number: "",
    customer_name: "",
    ref_number: "",
    agent_note: "",
    note: "",
    agreed_to_terms: false,
    gps_location: "",
    email_address: "",
    // category: "",
    status: "Open",
    payment_type: "",
    id_number: "",
    last_updated_by_name: "New",
    supporting_document_ref: "",
    title: "",
    job_title: "",
    marital_status: "",
    current_address: "",
    employer: "",
    employer_contact_number: "",
    company_name: "",
    industry: "",
  };

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [disable, setDisable] = useState(false);
  const [addSupportingDocsModal, setAddSupportingDocsModal] = useState(false);
  const [isFilesUploaded, setIsFilesUploaded] = useState(false);
  const [filesUploaded, setFilesUploaded] = useState([]);
  const [uploadId, setUploadId] = useState("");
  const [formValues, setFormValues] = useState(defFormValues);
  const [supplierDetail, setSupplierDetail] = useState({});
  const [supplierText, setSupplierText] = useState(undefined);
  const [admins, setAdmins] = useState([]);

  // form changes

  useEffect(() => {
    /**
     * Fetches all active admins for the given supplier from the database.
     * Stores the result in the component state.
     *
     * @returns {Promise<void>}
     */
    const fetchAdmins = async () => {
      const { data, error } = await supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("admin_roles")
        .select("*, dealers(contact_name, email)")
        .eq("related_supplier", supplierDetail?.id)
        .eq("role_type", 1)
        .eq("is_deleted", false);

      if (error) {
        console.error("Error fetching admins:", error);
      } else {
        setAdmins(data);
      }
    };

    if (supplierDetail?.id) {
      fetchAdmins();
    }
  }, [supplierDetail]);

  // gps location

  /**
   * Uses the Geolocation API to get the user's current location.
   * If permission is granted, the gps_location field is updated with the user's current location.
   * If permission is denied, the errorCallback is called.
   */
  const getLocation = () => {
    const successCallback = async position => {
      const { coords } = await position;
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        gps_location: `${coords.latitude} ${coords.longitude}`,
      }));
    };

    const errorCallback = error => {
      console.error(error);
    };

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  };

  // form changes

  const handleChange = event => {
    setDisable(false);
    const { name, value } = event.target;
    if (name === "agreed_to_terms") {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        [name]: !formValues.agreed_to_terms,
      }));
    } else {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        [name]: value,
      }));
    }
  };

  // Handle selects

  // const handleCategorySelect = event => {
  //   setDisable(false);
  //   setFormValues(prev => ({ ...prev, category: event.target.value }));
  // };

  const handlePaymentTypeSelect = event => {
    setDisable(false);
    setFormValues(prev => ({ ...prev, payment_type: event.target.value }));
  };

  const handleIndustryTypeSelect = event =>
    setFormValues(prev => ({ ...prev, industry: event.target.value }));

  const handleMaritalStatusSelect = event =>
    setFormValues(prev => ({ ...prev, marital_status: event.target.value }));

  const handleIsFilesUploaded = () => setIsFilesUploaded(true);

  /**
   * Toggles the add supporting documents modal.
   * If the form is valid, the modal is toggled.
   * If the form is invalid, an error message is displayed.
   */
  const toggleAddSupportingDocsModal = () => {
    if (formValid()) {
      setAddSupportingDocsModal(old => !old);
    } else {
      setErrorMessage("Please add all required fields");
      setIsSnackbarOpen(true);
    }
  };

  /**
   * Returns true if the form is valid, false otherwise.
   * The form is valid if all required fields have been filled in.
   * The required fields are:
   * - customer_name
   * - address
   * - phone_number
   * - email_address
   * - id_number
   * - agreed_to_terms
   * @returns {boolean} True if the form is valid, false otherwise.
   */
  const formValid = () => {
    return (
      // formValues.category &&
      formValues.customer_name &&
      formValues.address &&
      formValues.phone_number &&
      formValues.email_address &&
      formValues.id_number &&
      formValues.agreed_to_terms
    );
  };

  /**
   * Sends a sales assist email to the admin with details about the dealer and supplier involved
   * in the sales assist submission.
   *
   * The email is sent to the admin with details about the dealer and supplier involved
   * in the sales assist submission.
   *
   * @param {{admin: object, ticket: object}} params - The parameters for the function.
   * @param {object} params.admin - The admin information, including email.
   * @param {object} params.ticket - The ticket information, including ticket category, date submitted, and user note.
   * @param {number} params.ticket_num - The ticket number, used to format the ticket number as ZSA-00<ticket_num>
   * @returns {Promise<string>} A promise that resolves with a success message
   * if the email is sent successfully, or rejects with an error message if
   * sending the email fails.
   */
  function sendSalesAssistEmail({ admin, ticket, ticket_num }) {
    return new Promise((resolve, reject) => {
      // Optional: You can add validation logic here if needed
      const email = [admin?.dealers.email];
      const subject = `New Sales Assist for ${supplierDetail?.supplier}`;
      const userNote =
        ticket?.agent_note !== "" ? `User Note: ${ticket?.agent_note}` : "";
      const message = `
      Dear ${admin?.dealers.contact_name},
  
      The following dealer had submitted a new Sales assist ticket for this supplier: ${
        supplierDetail?.supplier
      }.
  
      Ticket Number: ZSA-00${ticket_num}
      Order : ${ticket?.payment_type}
      Dealer: ${ticket?.dealer_code}
      Date Submitted: ${new Date().toLocaleString()}
      ${userNote}
  
  
      Best regards
      ZIMS Team
      `;
      // Send the email using the sendEmail function
      sendEmail({
        email, // Email to send to
        subject, // Email subject
        message, // Email message
      })
        .then(response => {
          resolve("Email sent successfully", response.data);
        })
        .catch(error => {
          console.error("Error sending email:", error); // Handle error
          reject("Error sending email: ", error);
        });
    });
  }

  /**
   * Creates a new sales assist entry in the database.
   * If the creation is successful, sends a message to the sales assist table
   * with the ticket number and the user who created the entry.
   * @async
   * @function
   * @returns {Promise<void>}
   */
  const addSalesLead = async () => {
    const ticket = formValues;
    const { error, data } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("sales_assist")
      .insert(formValues)
      .select();

    const { ticket_num, id: ticket_id } = await data[0];

    if (error) {
      setErrorMessage("Error creating entry");
      setIsSnackbarOpen(true);
      console.error(error);
    }

    if (ticket.agreed_to_terms === true && admins.length > 0 && formValid()) {
      await Promise.all(
        admins.map(async admin => {
          try {
            await sendSalesAssistEmail({
              admin,
              supplierDetail,
              ticket,
              ticket_num,
            });
          } catch (err) {
            console.error("Email error:", err);
          }
        })
      );
    }

    sendAssistMessage(
      ticket_id,
      `
      ${formatTicketNumber(ticket_num, "ZSA")} created
      `,
      "update",
      "sales_assist_activity"
    );
  };
  /**
   * Handles the add lead event.
   * If the form is valid, it will add a new sales assist entry to the database.
   * If the creation is successful, it will also send a message to the sales assist table
   * with the ticket number and the user who created the entry.
   * If the form is invalid, it will display an error message.
   * @async
   * @function
   * @returns {Promise<void>}
   */
  const handleAddLead = async () => {
    if (formValid()) {
      try {
        // await addSalesLead({ ...formValues });
        await addSalesLead();
        setFormValues(defFormValues);
        onClose();
      } catch (err) {
        setErrorMessage(err);
        setIsSnackbarOpen(true);
      }
    } else {
      setErrorMessage("Please add all required fields");
      setIsSnackbarOpen(true);
      setDisable(true);
    }
  };

  /**
   * Fetches the uploaded files for the current lead.
   * If the uploaded files fetch is successful, it updates the state with the list of uploaded files.
   * If the uploaded files fetch is unsuccessful, it throws an error.
   * @async
   * @function
   * @returns {Promise<void>}
   */
  const fetchUploadedFiles = async () => {
    const { data: files, error } = await supabase.storage
      .from(uploadBucket)
      .list(uploadId);

    if (error) {
      throw error;
    }

    if (!files || !files.length) {
      // eslint-disable-next-line no-alert
      alert("No Files to upload");
      setFilesUploaded([]);
      setIsFilesUploaded(false);
      setFormValues(old => ({ ...old, supporting_document_ref: "" }));
    } else {
      setFilesUploaded(files);
      setFormValues(old => ({ ...old, supporting_document_ref: uploadId }));
    }
  };

  /**
   * Fetches the sales assist text for the current supplier.
   * If the sales assist text fetch is successful, it updates the state with the sales assist text.
   * If the sales assist text fetch is unsuccessful, it throws an error.
   * @async
   * @function
   * @returns {Promise<void>}
   */
  const fetchSupplierText = async () => {
    const supplierData = await getSupplier(supplier);
    setSupplierDetail(await supplierData);
    setSupplierText(await supplierData.sales_assist_text);
  };

  useEffect(() => {
    if (isFilesUploaded) {
      fetchUploadedFiles();
    }
  }, [addSupportingDocsModal]);

  useEffect(() => {
    setUploadId(nanoId());
    fetchSupplierText();
  }, []);

  const viewFiles = filesUploaded.map(file => {
    /**
     * Handles the deletion of a file from the supporting documents.
     * Deletes the file from the storage bucket and then fetches the updated list of uploaded files.
     * @async
     * @function
     * @returns {Promise<void>}
     */
    const handleDelete = async () => {
      await supabase.storage
        .from(uploadBucket)
        .remove([`${uploadId}/${file.name}`]);
      fetchUploadedFiles();
    };

    return (
      <Grid item xs={12} key={file.id}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={10}>
            <Typography>
              <code>{file.name}</code>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Button color="secondary" onClick={handleDelete}>
              <DeleteForeverIcon />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogTitle>Add Lead</DialogTitle>
      <DialogContent>
        <Grid container xs={12} spacing={2}>
          {/* to use in agent assist */}
          {/* <Grid item xs={12}>
          <TextField
          required
          select
          value={formValues.category}
          onChange={handleCategorySelect}
          id="outlined-required"
          label="Category"
          variant="outlined"
          helperText="Select the lead category"
          fullWidth
          >
          {salesLeadCategories[
            salesLeadCategories[formValues.supplier]
            ? formValues.supplier
            : "def"
            ].map(category => (
              <MenuItem key={category.key} value={category.value}>
              {category.value}
              </MenuItem>
              ))}
              </TextField>
              </Grid> */}
          {supplierText && (
            <>
              <Grid item xs={12}>
                <Markdown>{supplierText}</Markdown>
              </Grid>
              <br />
            </>
          )}
          <Grid item xs={12}>
            <TextField
              label="Customer Name"
              variant="outlined"
              required
              name="customer_name"
              value={formValues.customer_name}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Customer Address"
              variant="outlined"
              required
              name="address"
              value={formValues.address}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Customer Phone Number"
              variant="outlined"
              required
              name="phone_number"
              value={formValues.phone_number}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Customer Whatsapp Number"
              variant="outlined"
              name="alternative_number"
              value={formValues.alternative_number}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="Customer Email Address"
              variant="outlined"
              name="email_address"
              value={formValues.email_address}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="Customer ID Number"
              variant="outlined"
              name="id_number"
              value={formValues.id_number}
              onChange={handleChange}
              fullWidth
            />
          </Grid>{" "}
          <Grid item xs={12}>
            <TextField
              label="Title"
              variant="outlined"
              name="title"
              value={formValues.title}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Job Title"
              variant="outlined"
              name="job_title"
              value={formValues.job_title}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          {maritalStatusOptions && (
            <Grid item xs={12}>
              <TextField
                select
                value={formValues.marital_status}
                onChange={handleMaritalStatusSelect}
                id="outlined-required"
                label="Marital status"
                variant="outlined"
                helperText="Select the Industry type"
                fullWidth
              >
                {maritalStatusOptions &&
                  maritalStatusOptions.map(type => (
                    <MenuItem key={type.key} value={type.value}>
                      {type.value}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              label="Current address in Months"
              variant="outlined"
              name="current_address"
              value={formValues.current_address}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Employer"
              variant="outlined"
              name="employer"
              value={formValues.employer}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Employers Contact number"
              variant="outlined"
              name="employer_contact_number"
              value={formValues.employer_contact_number}
              onChange={handleChange}
              fullWidth
            />
          </Grid>{" "}
          <Grid item xs={12}>
            <TextField
              label="Name of Company"
              variant="outlined"
              name="company_name"
              value={formValues.company_name}
              onChange={handleChange}
              fullWidth
            />
          </Grid>{" "}
          {agentAssistIndustryTypes && (
            <Grid item xs={12}>
              <TextField
                select
                value={formValues.industry}
                onChange={handleIndustryTypeSelect}
                id="outlined"
                label="Industry"
                variant="outlined"
                helperText="Select the Industry type"
                fullWidth
              >
                {agentAssistIndustryTypes &&
                  agentAssistIndustryTypes.map(type => (
                    <MenuItem key={type.key} value={type.value}>
                      {type.value}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          )}
          {salesLeadPaymentTypes[formValues.supplier] && (
            <Grid item xs={12}>
              <TextField
                required
                select
                value={formValues.payment_type}
                onChange={handlePaymentTypeSelect}
                id="outlined-required"
                label="Payment Type"
                variant="outlined"
                helperText="Select the Payment type"
                fullWidth
              >
                {formValues.supplier &&
                  salesLeadPaymentTypes[formValues.supplier].map(type => (
                    <MenuItem key={type.key} value={type.value}>
                      {type.value}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          )}
          <Grid container item xs={12} spacing={2} alignItems="center">
            <Grid item xs={10}>
              <TextField
                label="Customer GPS Location"
                variant="outlined"
                name="gps_location"
                value={formValues.gps_location}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={2} alignContent="center">
              <Button
                color="primary"
                onClick={getLocation}
                disabled={disable}
                fullWidth
              >
                <LocationOnOutlinedIcon /> Get Location
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Additional Notes"
                variant="outlined"
                name="agent_note"
                value={formValues.agent_note}
                onChange={handleChange}
                fullWidth
                multiline
              />
            </Grid>
            <Grid item xs={12}>
              <br />
              <Typography variant="body2">
                *Please note that utilizing the sales assist function does not
                guarantee the finalization, payment, or installation of an
                order. The purpose of utilizing the sales assist function is
                solely for assistance.
              </Typography>
              <br />
              <Typography variant="body2">
                *When using the sales assist function, please ensure to input
                the client's details in the designated spaces. Tickets with
                agent's personal information will be closed without further
                attention.
              </Typography>
              <br />
              <Typography variant="subtitle2">
                *Service Level Agreement (SLA) Times: We are currently unable to
                provide precise SLA times. However, rest assured, we will
                provide updates and feedback as soon as possible.
              </Typography>
              <FormControlLabel
                style={{ marginBottom: 10 }}
                control={<Checkbox required />}
                label="Agreed To Terms"
                name="agreed_to_terms"
                checked={formValues.agreed_to_terms}
                onChange={handleChange}
              />
            </Grid>
            {isFilesUploaded ? (
              <Grid item container direction="rows">
                {viewFiles}
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography variant="caption">No Files Uploaded</Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                color="primary"
                onClick={toggleAddSupportingDocsModal}
                disabled={disable}
              >
                Add Supporting Documents
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Snackbar
                open={isSnackbarOpen}
                autoHideDuration={4000}
                onClose={() => setIsSnackbarOpen(false)}
              >
                <Alert
                  severity="error"
                  onClose={() => setIsSnackbarOpen(false)}
                >
                  {errorMessage}
                </Alert>
              </Snackbar>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          alignItems="center"
          direction="row"
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddLead}
              disabled={disable}
              type="submit"
            >
              Submit
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={onClose}
              type="reset"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      <ModalCard
        open={addSupportingDocsModal}
        onClose={toggleAddSupportingDocsModal}
      >
        <SBFileUploader
          bucketName={uploadBucket}
          folderName={uploadId}
          onClose={toggleAddSupportingDocsModal}
          withSubmit={handleIsFilesUploaded}
        />
      </ModalCard>
    </Dialog>
  );
}
