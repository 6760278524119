// #useAuthUpdated

import {
  Box,
  Grid,
  Paper,
  Avatar,
  Button,
  Container,
  CssBaseline,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { LockOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import logo from "../../img/ZawadiHighRes.gif";
import Copyright from "./Copyright";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    padding: "30px",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#f6fffd",
  },
  avatar: {
    margin: theme.spacing(1),
    marginBottom: "1rem",
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  success: {
    color: "green",
  },
}));

/**
 * A page which allows a user to reset their password.
 *
 * The component renders a form with an email address input field and a submit
 * button. When the form is submitted, a password reset link is sent to the
 * user's email address if it is valid. The component also renders a success
 * message upon successful completion, or an error message if an error occurs
 * or if the email is invalid.
 *@param {object} props -  The properties for the component.
 * @param {Function} props.toggleForgotPasswordView - A function to toggle the
 *   visibility of the forgot password form.
 */
export default function ForgotPassword({ toggleForgotPasswordView }) {
  const classes = useStyles();
  const [email, setEmail] = useState(null);
  const { forgotPassword } = useAuth();

  // Action State
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);

  /**
   * Handles the submission of the forgot password form.
   *
   * Prevents the default form submission behavior, resets the message state,
   * and initiates a password reset process if a valid email is provided.
   * Displays a success message upon successful completion or sets an error
   * message if an error occurs or if the email is invalid.
   *
   * @param {React.FormEvent<HTMLFormElement>} e - The form event.
   * @returns {Promise<void>} A promise that resolves when the password reset process is completed.
   */
  async function handleSubmit(e) {
    e.preventDefault();
    setMessage(false);
    if (email) {
      try {
        setError("");
        setLoading(true);
        await forgotPassword(email).then(() => {
          setMessage(true);
        });
      } catch (err) {
        setError(err.message);
      }
    } else {
      setError("Please enter a valid email address.");
    }
    setLoading(false);
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        style={{
          flexDirection: "column",
          minHeight: "100vh",
          display: "flex",
        }}
        justifyContent="space-between"
      >
        <Paper className={classes.paper}>
          <img src={logo} width={200} alt="Logo" draggable="false" />
          <Avatar className={classes.avatar}>
            <LockOutlined />
          </Avatar>
          <Typography
            component="h1"
            variant="h5"
            style={{ marginBottom: "1rem" }}
          >
            Reset your password
          </Typography>
          <form onSubmit={handleSubmit} className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email address"
              name="email"
              type="email"
              autoComplete="email"
              autoFocus
              onChange={e => setEmail(e.target.value)}
            />
            <br />
            <br />

            {error ? (
              <Alert style={{ width: "100%" }} severity="error">
                {error}
              </Alert>
            ) : message ? (
              <Alert style={{ width: "100%" }} severity="success">
                Request Sent. Please check your inbox for further instructions.
              </Alert>
            ) : (
              <Alert style={{ width: "100%" }} severity="info">
                A link will be sent to your email address which you can use to
                reset your password.
              </Alert>
            )}
            <Button
              type="submit"
              disabled={loading}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Send Reset Email
            </Button>
            <Grid container style={{ margin: "0.5rem 0" }}>
              <Grid item xs>
                <Typography
                  className="sim-text-link"
                  color="primary"
                  onClick={toggleForgotPasswordView}
                  variant="body2"
                >
                  Back to login
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Paper>
        <Copyright />
      </Box>
    </Container>
  );
}
