import { supabase } from "../../supabase";

/**
 * Downloads files from a specified Supabase storage bucket and folder.
 *
 * @async
 * @function SBFileDownloader
 * @param {string} bucket - The name of the Supabase storage bucket.
 * @param {string} [folder=""] - The folder within the bucket to list files from. Defaults to an empty string.
 * @returns {Promise<object[]>} A promise that resolves to an array of objects containing file details.
 * @throws Will throw an error if there is an issue retrieving the file list from Supabase storage.
 * @throws Will return an empty array if there are no files in the specified folder.
 */
async function SBFileDownloader(bucket, folder = "") {
  // Get file list
  const { data: files, error } = await supabase.storage
    .from(bucket)
    .list(folder);

  if (error) {
    throw error;
  }

  // If there are no files in the folder, throw an error
  if (!files || !files.length) {
    // throw new Error("No files to download");
    return [];
  }

  const retArr = files.map(file => {
    const { data } = supabase.storage
      .from(bucket)
      .getPublicUrl(`${folder}/${file.name}`);

    const downloadUrl = `${data.publicUrl}?download`;

    return {
      id: file.id,
      fileName: file.name,
      downloadUrl,
    };
  });

  return retArr;
}

export default SBFileDownloader;
