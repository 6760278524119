import React from "react";
import { Grid } from "@material-ui/core";

import { useCompany } from "../../../contexts/CompanyContext";
import SupplierTile from "../../dealercomps/agent-dashboard/dash-components/SupplierTile";

/**
 * AdminSuppliersList component renders a list of suppliers.
 * It uses the `useCompany` hook to fetch the suppliers data.
 * If there are no suppliers, it displays a message indicating that.
 * Each supplier is rendered using the `SupplierTile` component.
 *
 * @component
 * @returns {React.Element} The rendered component.
 */
export default function AdminSuppliersList() {
  const { suppliers } = useCompany();

  return (
    <div>
      <Grid container xs={12} spacing={1}>
        {suppliers.length === 0 && <p>There are no suppliers to show.</p>}
        {suppliers.map(supplier => (
          <Grid item xs={12} md={6} key={supplier.id}>
            <SupplierTile
              supplierObject={supplier}
              url="/admin/Supplier/"
              isActive
              key={supplier.id}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
