import {
  Avatar,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  styled,
  Typography,
  withStyles,
} from "@material-ui/core";
import { linearProgressClasses } from "@mui/material";
import React from "react";

const BigAvatar = withStyles({
  root: {
    width: 60,
    height: 60,
  },
})(Avatar);

/**
 * A card component for displaying a user's leaderboard information,
 * with a large rank icon, name, fiber sales, and a progress bar.
 *
 * @param {object} props - The properties for the component.
 * @param {string} props.name The user's name.
 * @param {string} props.fiber The user's fiber sales.
 * @param {number} props.percentage The user's progress towards their target.
 * @param {string} props.rank The user's rank, displayed as a large icon.
 * @param {string} props.borderColor The color of the border around the card.
 * @returns {React.ReactElement} A JSX element representing the card.
 */
export default function Top3UserCard({
  name,
  fiber,
  percentage,
  rank,
  borderColor,
}) {
  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 15,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#D3D3D3",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#3D7155",
    },
  }));
  return (
    <Card
      variant="outlined"
      style={{
        height: "100%",
        borderRadius: "16px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        border: `4px solid ${borderColor}`, // Adjust the border thickness and color

        transition:
          "transform 0.3s ease, box-shadow 0.3s ease, filter 0.3s ease", // Smooth transition for hover effects
        transform: "scale(1)", // Default state (no scaling)
      }}
      onMouseEnter={e => {
        e.currentTarget.style.transform = "scale(1.05)"; // Slight scale up
        e.currentTarget.style.boxShadow = "0px 10px 30px rgba(0, 0, 0, 0.2)"; // Deeper shadow for a "lift" effect
        e.currentTarget.style.filter = "brightness(1.1)"; // Slightly brighten the card
      }}
      onMouseLeave={e => {
        e.currentTarget.style.transform = "scale(1)"; // Reset scale
        e.currentTarget.style.boxShadow = "0px 4px 12px rgba(0, 0, 0, 0.1)"; // Reset shadow
        e.currentTarget.style.filter = "brightness(1)"; // Reset brightness
      }}
    >
      <CardContent
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Grid container spacing={2}>
          <Grid container justify="center" alignItems="center" xs={2}>
            <BigAvatar src={rank} />
          </Grid>
          <Grid item xs={10}>
            <Grid>
              <Typography variant="h5" align="left" style={{ fontWeight: 500 }}>
                {name}
              </Typography>

              <Grid container direction="row">
                <Typography color="textPrimary" variant="h6">
                  Fibre Sales:&nbsp;
                </Typography>
                <Typography color="primary" variant="h6">
                  {fiber}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={1} />
          <Grid item xs={10}>
            <BorderLinearProgress variant="determinate" value={percentage} />
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </CardContent>
    </Card>
  );
}
