import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@material-ui/core";
import Select from "react-select";
import { Alert } from "@material-ui/lab";
import { supabase } from "../../../supabase";

/**
 * Modal dialog for finding an order by reference number and reassigning it
 * to a different agent.
 *
 * @param {object} props - The properties passed to the component.
 * @param {Function} props.setShowFindOrderModal - sets showFindOrderModal state
 * @param {Function} props.setShowSuccess - sets showSuccess state
 // * @param {Function} props.refreshData - refreshes the grid data
 * @param {Function} props.fetchAgents - fetches the agents for a specific supplier
 */
function FindOrder({ setShowFindOrderModal, setShowSuccess, fetchAgents }) {
  const [orderNumber, setOrderNumber] = useState("");
  const [loadingModalAction, setLoadingModalAction] = useState(false);
  const [foundOrder, setFoundOrder] = useState([]);
  const [showOrderNotFound, setShowOrderNotFound] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [agents, setAgents] = useState([]);

  const commissionPaid =
    !!foundOrder?.order_status?.agent_activation_comm_paid_date_1;

  /**
   * Function to find an order by reference number.
   * If the order is found, it populates the `foundOrder` state with the order data.
   * If the order is not found, it sets `showOrderNotFound` to true.
   */
  const handleFindOrder = async () => {
    setLoadingModalAction(true);
    setShowOrderNotFound(false);

    if (!orderNumber.trim()) return;

    try {
      const { data: row } = await supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("fibre_order_master")
        .select(
          "*, fibre_order_override (dealers(dealer_id: id, dealer_code, contact_name, contact_surname )), order_status (agent_activation_comm_paid_date_1)"
        )
        .eq("order_number_1", `${orderNumber.trim()}`)
        .single();

      if (!row?.id) {
        setShowOrderNotFound(true);
        setLoadingModalAction(false);
        return;
      }

      setFoundOrder(row);
      setLoadingModalAction(false);
    } catch (error) {
      console.error("Error finding order:", error);
      setLoadingModalAction(false);
    }
  };

  const handleReassignOrder = async () => {
    setLoadingModalAction(true);
    try {
      const { data: existingOrder, error: fetchError } = await supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("fibre_order_override")
        .select("id")
        .eq("ref_num", foundOrder.order_number_1)
        .single();

      if (fetchError && fetchError.code !== "PGRST116") {
        // PGRST116 is the error code for "no rows returned"
        console.error("Error fetching order:", fetchError);
        setLoadingModalAction(false);
        return;
      }

      if (existingOrder) {
        // Update the existing record
        await supabase
          .schema(process.env.REACT_APP_SB_SCHEMA)
          .from("fibre_order_override")
          .update({
            ref_num: foundOrder.order_number_1,
            original_agent_value: foundOrder.agent_identification_1,
            newly_assigned_agent: selectedAgent.value,
            order_date: foundOrder.order_created_date_1,
            temp_newly_assigned_agent: null,
          })
          .eq("id", existingOrder.id);
      } else {
        // Insert a new record
        await supabase
          .schema(process.env.REACT_APP_SB_SCHEMA)
          .from("fibre_order_override")
          .insert({
            ref_num: foundOrder.order_number_1,
            original_agent_value: foundOrder.agent_identification_1,
            newly_assigned_agent: selectedAgent.value,
            order_date: foundOrder.order_created_date_1,
            temp_newly_assigned_agent: null,
          });
      }

      setLoadingModalAction(false);
      setShowFindOrderModal(false);
      setFoundOrder(null);
      setShowSuccess(true);
      setOrderNumber("");
    } catch (error) {
      console.error("Error handling order reassignment:", error);
      setLoadingModalAction(false);
    }
  };

  useEffect(async () => {
    if (foundOrder) {
      const supplierAgents = await fetchAgents(foundOrder.supplier_1);
      setAgents(await supplierAgents);
    }

    if (foundOrder?.fibre_order_override) {
      const { dealers: assignedAgent } = foundOrder.fibre_order_override;
      setSelectedAgent(() => ({
        value: assignedAgent.dealer_id,
        label: `${assignedAgent.dealer_code}: ${assignedAgent.contact_name} ${assignedAgent.contact_surname}`,
      }));
    }
  }, [foundOrder]);

  useEffect(() => {
    setFoundOrder(false);
  }, [orderNumber]);

  return (
    <>
      <DialogTitle>Find Order</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Order Number"
          type="text"
          fullWidth
          variant="outlined"
          value={orderNumber}
          onChange={e => {
            setOrderNumber(e.target.value);
            setShowOrderNotFound(false);
          }}
          placeholder="Enter your order number"
        />
        {foundOrder && foundOrder.id && (
          <div style={{ margin: "20px 0px 20px 0px" }}>
            <h3>Order Details:</h3>
            <p>
              <b>Order Date: </b>
              {foundOrder.order_created_date_1}
            </p>
            <p>
              <b>Ref Num: </b>
              {foundOrder.order_number_1}
            </p>
            <p>
              <b>Agent: </b>
              {foundOrder.agent_identification_1}
            </p>
            <br />
            <h3>Re-Assign Order: </h3>
            <Select
              value={selectedAgent}
              xs={12}
              required
              menuPortalTarget={document.body}
              styles={{
                menuPortal: base => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
              options={agents}
              onChange={e => {
                setSelectedAgent(e);
              }}
              placeholder={
                commissionPaid ? "Commission Paid" : "Select New Agent"
              }
              isDisabled={commissionPaid}
            />
            <Snackbar open={commissionPaid}>
              <Alert severity="error">Commission has already been paid</Alert>
            </Snackbar>
          </div>
        )}
        {showOrderNotFound && (
          <div style={{ color: "red" }}>Order {orderNumber} not found</div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setShowFindOrderModal(false);
            setFoundOrder(null);
            setOrderNumber("");
          }}
          color="secondary"
        >
          Cancel
        </Button>
        {foundOrder && foundOrder.id ? (
          <Button
            onClick={handleReassignOrder}
            color="primary"
            variant="contained"
            disabled={
              !orderNumber.trim() ||
              loadingModalAction ||
              commissionPaid ||
              !selectedAgent
            }
          >
            {loadingModalAction ? "Loading..." : "Save New Agent"}
          </Button>
        ) : (
          <Button
            onClick={handleFindOrder}
            color="primary"
            variant="contained"
            disabled={!orderNumber.trim() || loadingModalAction}
          >
            {loadingModalAction ? "Loading..." : "Find Order"}
          </Button>
        )}
      </DialogActions>
    </>
  );
}

export default FindOrder;
