import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Alert } from "@material-ui/lab";
import moment from "moment/moment";
import { supabase } from "../../../supabase";
import { useCompany } from "../../../contexts/CompanyContext";
import { useAuth } from "../../../contexts/AuthContext";
import { pageTitleStyles } from "../../../mui/styles";
import {
  // salesLeadCategories,
  maritalStatusOptions,
  agentAssistIndustryTypes,
  salesLeadStatuses,
  salesLeadPaymentTypes,
  salesLeadInProgressStatuses,
  salesLeadClosedStatuses,
} from "../../shared/constants";
import ConfirmationModal from "../../shared/ConfirmationModal";
import sendAssistMessage from "../../shared/functions/sendAssistMessage.function";
import sendPersonalAlert from "../../shared/functions/sendPersonalAlert.function";
import userFromAuth from "../../shared/functions/userFromAuth.function";
import formatTicketNumber from "../../shared/functions/formatTicketNumber";
/**
 * This component is used to edit a sales lead. It fetches the sales lead data
 * from the database and displays it in a form. The form can be edited and then
 * submitted to update the sales lead. It also includes a button to delete the
 * sales lead.
 *
 * @param {object} props - The props object should contain the following:
 * @param {string} props.leadId - leadId: The id of the sales lead to be edited
 * @param {Function} props.onClose - onClose: A function to be called when the modal is closed
 * @returns {React.ReactElement} - The JSX element to be rendered
 */
function EditLeadModal({ leadId, onClose }) {
  // Default form values
  const defFormValues = {
    supplier: "",
    status: "",
    // category: "",
    ref_number: "",
    customer_name: "",
    address: "",
    phone_number: "",
    alternative_number: "",
    note: "",
    gps_location: "",
    email_address: "",
    id_number: "",
    payment_type: "",
    last_updated_by_code: "",
    last_updated_by_name: "",
    last_updated_at: "",
    sales_lead_in_progress_status: "",
    sales_lead_closed_status: "",
    history: [],
    title: "",
    job_title: "",
    marital_status: "",
    current_address: "",
    employer: "",
    employer_contact_number: "",
    company_name: "",
    industry: "",
  };
  // Custom hooks
  const { activeSuppliers } = useCompany();
  const { currentUser } = useAuth();
  // Hooks for managing state
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [disable, setDisable] = useState(false);
  const [formValues, setFormValues] = useState(defFormValues);
  const [confirmModal, setConfirmModal] = useState(false);
  const [ticketUser, setTicketUser] = useState({});
  const [regex, setRegex] = useState("");
  // Event handlers
  const handleChangeSuppliers = event => {
    setDisable(false);
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      supplier: event.target.value,
    }));
  };
  const handleError = message => {
    setErrorMessage(message);
    setIsSnackbarOpen(true);
  };

  /**
   * useEffect hook to update the regex pattern and validate the `ref_number` field
   * based on the selected supplier. This hook is triggered whenever `formValues` changes.
   *
   * - First, it checks if `activeSuppliers` and a selected `supplier` in `formValues` are available.
   *   If so, it sets the regex pattern associated with that supplier.
   * - Then, if a `regex` pattern and `ref_number` are available, it creates a new
   *   RegExp object using the pattern and tests the `ref_number` against it.
   * - If the `ref_number` fails the pattern, it displays an error message in a Snackbar
   *   and disables the form submission button. If the `ref_number` passes, it clears the
   *   error message and enables the button.
   *
   * @useEffect
   * @param {Array} dependencies - Dependencies that trigger this hook, in this case, `[formValues]`.
   * @fires Snackbar to display an error message if validation fails.
   * @function
   */

  useEffect(() => {
    if (activeSuppliers && formValues?.supplier) {
      setRegex(activeSuppliers?.find(s => s.id === formValues?.supplier).regex);
    }
    if (regex && formValues?.ref_number) {
      const pattern = new RegExp(regex);
      if (pattern) {
        const result = pattern.test(formValues?.ref_number);
        if (!result) {
          setIsSnackbarOpen(true);
          setErrorMessage(
            "Reference number does not follow the correct format.",
            "error"
          );
          setDisable(true);
        } else {
          setDisable(false);
          setIsSnackbarOpen(false);
          setDisable(false);
        }
      }
    }
  }, [formValues]);

  // Other event handlers (handleCategorySelect, handleStatusSelect, etc.)
  // const handleCategorySelect = event =>
  //   setFormValues(prev => ({ ...prev, category: event.target.value }));
  const handleStatusSelect = event =>
    setFormValues(prev => ({ ...prev, status: event.target.value }));
  const handleInProgressStatusSelect = event =>
    setFormValues(prev => ({
      ...prev,
      sales_lead_in_progress_status: event.target.value,
    }));
  const handleClosedStatusSelect = event =>
    setFormValues(prev => ({
      ...prev,
      sales_lead_closed_status: event.target.value,
    }));
  const handlePaymentTypeSelect = event =>
    setFormValues(prev => ({ ...prev, payment_type: event.target.value }));

  const handleIndustryTypeSelect = event =>
    setFormValues(prev => ({ ...prev, industry: event.target.value }));

  const handleMaritalStatusSelect = event =>
    setFormValues(prev => ({ ...prev, marital_status: event.target.value }));

  const handleChange = event => {
    setDisable(false);
    const { name, value } = event.target;
    if (name === "agreedToTerms") {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        [name]: !formValues.agreedToTerms,
      }));
    } else {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        [name]: value,
      }));
    }
  };
  /**
   * Handle the edit lead event.
   *
   * This function is called when the user clicks on the "Edit Lead"
   * button. It will update the sales assist table with the new values
   * from the form. It will also add a new entry to the history array
   * with the current timestamp and the updated values.
   *
   * If the supplier is "all", it will throw an error.
   *
   * @function
   * @returns {void}
   */
  const handleEditLead = async () => {
    if (formValues.supplier !== "all") {
      try {
        setDisable(true);
        const updatedFormValues = {
          ...formValues,
          // Adding the history for the sales lead
          history: [
            ...formValues.history,
            {
              updated_at: moment().format("YYYY/MM/DD HH:mm:ss"),
              update_address: formValues.address,
              updated_by: currentUser.id,
              updated_by_code: currentUser.dealer_code,
              updated_by_code_name: `${currentUser.dealer_code}: ${currentUser.contact_name}`,
              update_status: formValues.status,
              update_in_progress_status:
                formValues.sales_lead_in_progress_status,
              update_closed_status: formValues.sales_lead_closed_status,
              update_feedback: formValues.note,
            },
          ],
        };
        const { error } = await supabase
          .schema(process.env.REACT_APP_SB_SCHEMA)
          .from("sales_assist")
          .update(updatedFormValues)
          .eq("id", formValues.id)
          .select();
        // Update message composition
        let updateMessage = `
          Updated By: ${currentUser.dealer_code}: ${currentUser.contact_name}
          Status: ${formValues.status}
          `;
        if (formValues.address) {
          updateMessage += `Address: ${formValues.address}\n`;
        }
        if (formValues.note) {
          updateMessage += `Feedback: ${formValues.note}\n`;
        }
        if (formValues.sales_lead_in_progress_status) {
          updateMessage += `In Progress Status: ${formValues.sales_lead_in_progress_status}\n`;
        }
        if (formValues.sales_lead_closed_status) {
          updateMessage += `Closed Status: ${formValues.sales_lead_closed_status}\n`;
        }
        // send message on sales assist activity
        sendAssistMessage(
          formValues.id,
          updateMessage,
          "update",
          "sales_assist_activity"
        );
        // send an alert to the user to check sales assist
        if (ticketUser.id) {
          sendPersonalAlert(
            await ticketUser.id,
            `Ticket Update - ${formatTicketNumber(
              formValues.ticket_num,
              "ZSA"
            )}`,
            "<p>Your ticket received an update. Please view in Sales Assist</p>"
          );
        }
        if (error) console.error(error.message);
        onClose();
        setFormValues(defFormValues);
      } catch (err) {
        handleError(err);
      } finally {
        setDisable(false);
      }
    } else {
      handleError("All is not a Supplier please select one!");
      setDisable(true);
    }
  };
  /**
   * Delete a sales assist lead.
   * @async
   * @function
   * @returns {Promise<void>}
   */
  const handleDeleteLead = async () => {
    try {
      setDisable(true);
      const { error } = await supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("sales_assist")
        .update({ is_active: false })
        .eq("id", formValues.id)
        .select();
      if (error) console.error(error.message);
      setFormValues(defFormValues);
      onClose();
    } catch (err) {
      handleError(err);
    } finally {
      setDisable(false);
    }
  };
  // Fetch call to get data
  const getData = async () => {
    setLoading(true);
    const { data: salesAssistData, error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("sales_assist")
      .select("*")
      .eq("id", leadId);
    if (error) console.error(error);
    const result = await salesAssistData[0];
    const updatedFormValues = {
      ...formValues,
      ...(await result),
      last_updated_by_code: currentUser.dealer_code,
      last_updated_by_name: currentUser.contact_name,
      last_updated_at: moment().format("YYYY-MM-DD"),
      history: result.history || [], // Initialize history as an empty array if it's undefined
    };
    setFormValues(updatedFormValues);
    setLoading(false);
  };
  // use global function
  const getUser = async () => {
    const userFromId = await userFromAuth(formValues.auth_id);
    setTicketUser(await userFromId.user);
  };
  // useEffect to fetch data
  useEffect(() => {
    getData(leadId);
  }, []);
  useEffect(() => {
    if (formValues.auth_id) getUser();
  }, [formValues.auth_id]);
  // Return JSX elements
  return (
    <div>
      <Typography variant="h5" style={pageTitleStyles}>
        Edit Lead
      </Typography>
      <Grid container xs={12} spacing={2}>
        {loading ? (
          <Grid item>
            <Typography>Loading...</Typography>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ height: 60 }}
                required
              >
                <InputLabel
                  style={{
                    fontSize: "12px",
                  }}
                >
                  Select a Supplier
                </InputLabel>
                <Select
                  label="Select a Supplier"
                  value={formValues.supplier}
                  onChange={handleChangeSuppliers}
                  style={{
                    height: 60,
                    marginTop: -2,
                    fontSize: "12px",
                  }}
                >
                  {activeSuppliers.map(supplier => {
                    return (
                      <MenuItem value={supplier.id} key={supplier.id}>
                        {supplier.supplier}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                required
                select
                value={formValues.category}
                onChange={handleCategorySelect}
                id="outlined-required"
                label="Category"
                variant="outlined"
                fullWidth
              >
                {salesLeadCategories[
                  salesLeadCategories[formValues.supplier]
                    ? formValues.supplier
                    : "def"
                ].map(category => (
                  <MenuItem key={category.key} value={category.value}>
                    {category.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                required
                select
                value={formValues.status}
                onChange={handleStatusSelect}
                id="outlined-required"
                label="Status"
                variant="outlined"
                fullWidth
              >
                {salesLeadStatuses.map(status => (
                  <MenuItem key={status.key} value={status.value}>
                    {status.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {formValues.status === "In Progress" && (
              <Grid item xs={12}>
                <TextField
                  select
                  value={formValues.sales_lead_in_progress_status}
                  onChange={handleInProgressStatusSelect}
                  id="outlined-required"
                  label="In Progress Status"
                  variant="outlined"
                  fullWidth
                >
                  {salesLeadInProgressStatuses[
                    salesLeadInProgressStatuses[formValues.supplier]
                      ? formValues.supplier
                      : "def"
                  ].map(status => (
                    <MenuItem key={status.key} value={status.value}>
                      {status.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            {formValues.status === "Closed" && (
              <Grid item xs={12}>
                <TextField
                  select
                  value={formValues.sales_lead_closed_status}
                  onChange={handleClosedStatusSelect}
                  id="outlined-required"
                  label="Closed Status"
                  variant="outlined"
                  fullWidth
                >
                  {salesLeadClosedStatuses[
                    salesLeadClosedStatuses[formValues.supplier]
                      ? formValues.supplier
                      : "def"
                  ].map(status => (
                    <MenuItem key={status.key} value={status.value}>
                      {status.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                label="Refference number"
                variant="outlined"
                required
                name="ref_number"
                value={formValues.ref_number}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Customer Name"
                variant="outlined"
                required
                name="customer_name"
                value={formValues.customer_name}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Address"
                variant="outlined"
                required
                name="address"
                value={formValues.address}
                onChange={handleChange}
                fullWidth
              />
            </Grid>{" "}
            <Grid item xs={12}>
              <TextField
                label="Phone number"
                variant="outlined"
                required
                name="phone_number"
                value={formValues.phone_number}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Alternative number"
                variant="outlined"
                required
                name="alternative_number"
                value={formValues.alternative_number}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Email Address"
                variant="outlined"
                name="email_address"
                value={formValues.email_address}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Customer ID Number"
                variant="outlined"
                name="id_number"
                value={formValues.id_number}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Title"
                variant="outlined"
                name="title"
                value={formValues.title}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Job Title"
                variant="outlined"
                name="job_title"
                value={formValues.job_title}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            {maritalStatusOptions && (
              <Grid item xs={12}>
                <TextField
                  required
                  select
                  value={formValues.marital_status}
                  onChange={handleMaritalStatusSelect}
                  id="outlined-required"
                  label="Marital status"
                  variant="outlined"
                  helperText="Select the Industry type"
                  fullWidth
                >
                  {maritalStatusOptions &&
                    maritalStatusOptions.map(type => (
                      <MenuItem key={type.key} value={type.value}>
                        {type.value}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                required
                label="Current address in Months"
                variant="outlined"
                name="current_address"
                value={formValues.current_address}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Employer"
                variant="outlined"
                name="employer"
                value={formValues.employer}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Employers Contact no"
                variant="outlined"
                name="employer_contact_number"
                value={formValues.employer_contact_number}
                onChange={handleChange}
                fullWidth
              />
            </Grid>{" "}
            <Grid item xs={12}>
              <TextField
                required
                label="Name of Company"
                variant="outlined"
                name="company_name"
                value={formValues.company_name}
                onChange={handleChange}
                fullWidth
              />
            </Grid>{" "}
            {agentAssistIndustryTypes && (
              <Grid item xs={12}>
                <TextField
                  required
                  select
                  value={formValues.industry}
                  onChange={handleIndustryTypeSelect}
                  id="outlined-required"
                  label="Industry"
                  variant="outlined"
                  helperText="Select the Industry type"
                  fullWidth
                >
                  {agentAssistIndustryTypes &&
                    agentAssistIndustryTypes.map(type => (
                      <MenuItem key={type.key} value={type.value}>
                        {type.value}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            )}
            {salesLeadPaymentTypes[formValues.supplier] && (
              <Grid item xs={12}>
                <TextField
                  required
                  select
                  value={formValues.payment_type}
                  onChange={handlePaymentTypeSelect}
                  id="outlined-required"
                  label="Payment Type"
                  variant="outlined"
                  helperText="Select the Payment type"
                  fullWidth
                >
                  {formValues.supplier &&
                    salesLeadPaymentTypes[formValues.supplier].map(type => (
                      <MenuItem key={type.key} value={type.value}>
                        {type.value}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                label="GPS Location"
                variant="outlined"
                required
                name="gps_location"
                value={formValues.gps_location}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Feedback"
                variant="outlined"
                required
                name="note"
                value={formValues.note}
                onChange={handleChange}
                fullWidth
                multiline
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleEditLead}
                disabled={disable || loading}
              >
                Submit
              </Button>{" "}
            </Grid>{" "}
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                style={{ backgroundColor: "#c70000", color: "ButtonFace" }}
                onClick={() => setConfirmModal(true)}
                disabled={disable || loading}
              >
                Delete
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Snackbar
                open={isSnackbarOpen}
                autoHideDuration={4000}
                onClose={() => setIsSnackbarOpen(false)}
              >
                <Alert
                  severity="error"
                  onClose={() => setIsSnackbarOpen(false)}
                >
                  {errorMessage}
                </Alert>
              </Snackbar>
            </Grid>
          </>
        )}
      </Grid>
      <ConfirmationModal
        onConfirm={handleDeleteLead}
        onClose={() => setConfirmModal(false)}
        open={confirmModal}
      />
    </div>
  );
}
export default EditLeadModal;
