const yup = require("yup");

/**
 * Validates the product form values based on the defined schema.
 *
 * @param {object} values - The values to be validated.
 * @param {number} values.ogr - The weighting OGR, required.
 * @param {number} values.customer_price - The customer price, required.
 * @param {string} values.status - The status, required.
 * @param {string} values.product_type - The product type, required.
 * @param {string} [values.product_description] - The product description, optional.
 * @param {string} values.product - The product name, required.
 * @returns {Promise<object>} A promise that resolves with the validated values or rejects with an error message.
 */
export default function ProductFormValidation(values) {
  return new Promise((resolve, reject) => {
    const schema = yup.object().shape({
      ogr: yup.number().required("Weighting OGR is required"),
      customer_price: yup.number().required("Customer Price is required"),
      status: yup.string().required("Status is required"),
      product_type: yup.string().required("Product Type is required"),
      product_description: yup.string().nullable(),
      product: yup.string().required("Product name is required"),
    });

    schema
      .validate(values)
      .then(result => {
        resolve(result);
      })
      .catch(err => {
        reject(err.message);
      });
  });
}
