/* eslint-disable react/destructuring-assignment */
// #useAuthUpdated

import { Box, LinearProgress, Typography } from "@material-ui/core";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import { useAuth } from "../../contexts/AuthContext";
import { storage } from "../../firebase";

/**
 * LinearProgressWithLabel is a functional component that displays a linear progress bar
 * with a label indicating the progress percentage.
 *
 * @param {object} props - The properties passed to the component.
 * @param {number} props.value - The current progress value (0-100).
 * @returns {React.Element} The rendered component.
 */
function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

/**
 * FileUploader component handles file uploads to a specified storage path.
 *
 * @component
 * @param {object} props - Component props
 * @param {Function} props.sendFile - Callback function to send the file URL and name after upload
 * @param {string} [props.label] - Optional label for the file input
 * @param {string} [props.storagePath] - Optional storage path for the uploaded file
 * @returns {React.Element} The rendered component
 *
 * @example
 * <FileUploader sendFile={handleFileUpload} label="Upload your document" storagePath="custom/path" />
 */
export default function FileUploader({ sendFile, label, storagePath }) {
  const { currentUser } = useAuth();
  const storageRef = storagePath
    ? storage.ref().child(storagePath)
    : storage.ref("users").child(currentUser.id);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");

  const handleFileChange = e => {
    if (e.target.files[0]) {
      handleUploadStart(e.target.files[0]);
    }
  };

  const handleUploadStart = fileLocal => {
    setProgress(0);
    const fileName = Math.random().toString(36).slice(-10);

    const uploadTask = storageRef
      .child(`documents/${fileName}/${fileLocal.name}`)
      .put(fileLocal);

    uploadTask.on(
      "state_changed",
      snapshot => {
        const upProgress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(upProgress);
      },
      uploadError => {
        // Handle different error cases
        switch (uploadError.code) {
          case "storage/unauthorized":
            setError("User does not have permission to access the object.");
            break;
          case "storage/canceled":
            setError("User canceled the upload.");
            break;
          case "storage/unknown":
            setError(
              "Unknown error occurred, inspect the error server response."
            );
            break;
          default:
            setError("Error during file upload.");
        }
      },
      () => {
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then(url => {
            setProgress(100);
            sendFile({
              url,
              fileName: fileLocal.name,
            });
          })
          .catch(urlError => {
            console.error(urlError);
            setError("Failed to retrieve file URL.");
          });
      }
    );
  };

  return (
    <Box style={{ width: "100%", marginTop: "1rem" }}>
      {label || "Upload File"}
      <br />
      <br />
      <input
        type="file"
        onChange={handleFileChange}
        style={{ marginBottom: "1rem" }}
      />
      <br />
      <LinearProgressWithLabel value={progress} />
      <br />
      {error && (
        <Alert style={{ width: "100%", marginBottom: "1rem" }} severity="error">
          Error Uploading file
        </Alert>
      )}
    </Box>
  );
}
