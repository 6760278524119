import React from "react";
import { Grid, Paper, Typography } from "@mui/material/";
import { useTheme } from "@material-ui/core";

/**
 * @function MessageBubble
 * @description A component to render a message bubble containing a Reference Number, message text, and timestamp.
 * @param {object} props Component props
 * @param {string} props.RefNum The Reference Number for the message
 * @param {string} props.timestamp The timestamp for the message
 * @param {string} props.message The message text
 * @returns {React.ReactElement} The rendered component
 */
function MessageBubble({ RefNum, timestamp, message }) {
  const theme = useTheme();
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Paper
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px",
            margin: "10px",
            height: "100%",
            borderRadius: "16px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h5"
            color={theme.palette.primary.main}
            align="left"
            sx={{ paddingTop: "10px", paddingLeft: "10px" }}
          >
            {RefNum}
          </Typography>

          <Typography
            variant="h6"
            align="left"
            sx={{
              wordWrap: "break-word",
              padding: "10px",
              color: "Grey",
            }}
          >
            {message}
          </Typography>
          <Typography variant="caption" sx={{ alignSelf: "flex-end" }}>
            {timestamp}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default MessageBubble;
