/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Check, Search, PersonAdd, ArrowBack } from "@material-ui/icons";
import { Button, CardContent, Snackbar, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import CreateDealerForm from "./CreateDealerForm";
import DealerLevelTabs from "../tabs/DealerLevelTabs";
import DealersTable from "../tables/DealersTable";
import { pageTitleStyles } from "../../mui/styles";
import { useCompany } from "../../contexts/CompanyContext";

/**
 * Component for rendering the content inside a tab.
 * @param {object} props - The properties for the TabPanel.
 * @param {React.ReactNode} props.children - The content to display inside the tab.
 * @param {number} props.value - The current active tab index.
 * @param {number} props.index - The index of this tab.
 */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <CardContent className="iso-card-content">
          <Typography component="span">{children}</Typography>
        </CardContent>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

/**
 * ManageDealers is a React component that renders the content
 * for the Manage Teams page. It displays a list of dealer levels
 * in a tabbed interface, and allows users to create new dealers
 * or view/search all dealers. It also displays a button to view
 * all approvals.
 *
 * @component
 * @example
 * <ManageDealers />
 */
export default function ManageDealers() {
  const [snackbarIsOpen, setSnackbarIsOpen] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [listLevel, setListLevel] = useState();
  const history = useHistory();
  const { dealerLevels } = useCompany();

  useEffect(() => {
    if (dealerLevels && dealerLevels.length > 0 && !listLevel) {
      setListLevel(dealerLevels[0].level);
    }
  }, [dealerLevels, listLevel]);

  const closeDialog = () => {
    setDialogIsOpen(false);
  };

  const openDialog = () => {
    setDialogIsOpen(true);
  };

  const openSnackbar = () => {
    setSnackbarIsOpen(true);
  };

  return (
    <>
      <Button
        color="primary"
        startIcon={<ArrowBack />}
        onClick={() => history.goBack()}
      >
        Back
      </Button>
      <h1 style={pageTitleStyles}>Manage Teams</h1>

      <div style={{ marginBottom: ".5rem" }}>
        <Button
          variant="outlined"
          style={{ margin: "0 1rem 1rem 0" }}
          onClick={() => history.push("/all-dealers")}
          startIcon={<Search />}
          color="primary"
        >
          View / Search All
        </Button>
        <Button
          onClick={() => {
            openDialog(<CreateDealerForm closeDialog={closeDialog} />);
          }}
          variant="outlined"
          style={{ margin: "0 1rem 1rem 0" }}
          startIcon={<PersonAdd />}
          color="primary"
        >
          Create
        </Button>
        <Button
          variant="outlined"
          style={{ margin: "0 1rem 1rem 0" }}
          onClick={() => history.push("/approvals")}
          startIcon={<Check />}
          color="primary"
        >
          Approvals
        </Button>
      </div>

      <CreateDealerForm
        dialogIsOpen={dialogIsOpen}
        closeDialog={closeDialog}
        openSnackbar={openSnackbar}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarIsOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarIsOpen(false)}
        message="Request Submitted for Approval."
      />
      <DealerLevelTabs levels={dealerLevels} setListLevel={setListLevel} />
      {listLevel && <DealersTable rank={listLevel} />}
    </>
  );
}
