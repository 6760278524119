import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { sendEmail } from "../../../api";
import sendPersonalAlert from "../../shared/functions/sendPersonalAlert.function";

/**
 * SendAlertModal component renders a modal dialog to send an alert email
 * to a specified dealer. It allows users to input a subject and message,
 * and handles the submission of the email as well as sending a personal
 * alert to the dealer.
 *
 * @param {object} props - The properties object.
 * @param {boolean} props.open - Indicates whether the modal is open.
 * @param {Function} props.onClose - Function to close the modal.
 * @param {object} props.dealer - Object containing dealer information.
 *
 * @returns {React.Element} The SendAlertModal component.
 */
export default function SendAlertModal({ open, onClose, dealer }) {
  const [messageSubject, setMessageSubject] = useState("");
  const [messageContent, setMessageContent] = useState("");
  const [loading, setLoading] = useState(false);
  const email = dealer?.email;
  const subject = messageSubject;
  const message = `
${messageContent}
  `;

  const handleSubjectChange = e => {
    setMessageSubject(e.target.value);
  };
  const handleMessageChange = e => {
    setMessageContent(e.target.value);
  };
  /*
   * Sends an email with the specified subject and message content to a dealer.
   *
   * @returns {Promise<string>} A promise that resolves with a success message if the email is sent successfully,
   * or rejects with an error message if sending fails.
   */

  /**
   * Sends an email with the specified details.
   *
   * @returns {Promise} A promise that resolves with a success message and response data if the email is sent successfully,
   *                    or rejects with an error message and error details if the email fails to send.
   */
  function handleSendEmail() {
    return new Promise((resolve, reject) => {
      sendEmail({
        email,
        subject,
        message,
      })
        .then(response => {
          resolve("Email sent successfully", response.data);
        })
        .catch(error => {
          console.error("Error sending email:", error);
          reject("Error sending email: ", error);
        });
    });
  }

  /*
   * Handle form submission and send email and personal alert to dealer.
   *
   * 1. Set loading to true
   * 2. Send email to dealer using `handleSendEmail` function
   * 3. Catch email error and log it to console
   * 4. Set loading to false
   * 5. Send personal alert to dealer using `sendPersonalAlert` function
   * 6. Catch personal alert error and log it to console
   * 7. Set loading to false
   * 8. Clear form fields
   * 9. Close modal
   */
  const handleSubmit = async () => {
    setLoading(true);
    try {
      await handleSendEmail();
    } catch (error) {
      console.error("Error sending email:", error);
      setLoading(false);
      return;
    }

    try {
      await sendPersonalAlert(dealer.id, subject, message);
    } catch (error) {
      console.error("Error sending personal alert:", error);
      setLoading(false);
      return;
    }
    setMessageSubject("");
    setMessageContent("");
    setLoading(false);
    onClose();
  };

  const handleClose = () => {
    setMessageSubject("");
    setMessageContent("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={() => handleClose()} fullWidth>
      <DialogTitle align="center">Send Alert</DialogTitle>
      <DialogContent>
        <Grid alignContent="center" justify="center">
          <Grid xs={12} style={{ marginBottom: "1rem" }}>
            <TextField
              label="Subject"
              onChange={handleSubjectChange}
              value={messageSubject}
              variant="outlined"
              fullWidth
            />{" "}
          </Grid>
          <Grid xs={12} style={{ marginBottom: "1rem" }}>
            <TextField
              label="Message"
              onChange={handleMessageChange}
              value={messageContent}
              variant="outlined"
              multiline
              minRows={4}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose()}
          color="secondary"
          variant="contained"
          disabled={loading}
        >
          Close
        </Button>{" "}
        <Button
          onClick={() => handleSubmit()}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}
