/* eslint-disable react/no-array-index-key */
/* eslint-disable no-alert */
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Snackbar,
  TextField,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import { pageTitleStyles } from "../../../mui/styles";

/**
 * AvailableServices component displays and manages the list of available services.
 * It allows users to add new services and remove existing ones.
 *
 * @component
 * @example
 * return (
 *   <AvailableServices />
 * )
 *
 * @returns {React.Element} The rendered component.
 */
export default function AvailableServices() {
  const { getAvailableServices, removeService, addService } = useDb();
  const [services, setServices] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [newServiceName, setNewServiceName] = useState("");

  useEffect(() => {
    setLoading(true);
    getServices();
  }, []);

  const getServices = () => {
    getAvailableServices()
      .then(result => {
        setLoading(false);
        if (result.length > 0) {
          setServices(result);
        } else {
          setServices([]);
        }
      })
      .catch(error => {
        alert(error.message);
      });
  };

  const handleDelete = () => {
    setConfirmOpen(false);
    setLoading(true);

    removeService(selectedService.id)
      .then(() => {
        getServices();
      })
      .catch(error => {
        alert(error.message);
      });
  };

  const handleAdd = () => {
    setLoading(true);
    addService(newServiceName)
      .then(() => {
        getServices();
      })
      .catch(error => {
        alert(error.message);
      });
  };

  return (
    <div>
      <h1 style={pageTitleStyles}>Available Services</h1>
      {loading ? (
        <CircularProgress />
      ) : (
        <Card style={{ maxWidth: 600 }}>
          <CardContent>
            <div>
              {services.map((item, index) => (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      height: 50,
                      alignItems: "center",
                      borderBottom: "solid",
                      borderBottomColor: "#888",
                      borderBottomWidth: 0.5,
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <li key={`${index}${item.value}`}>{item.label}</li>
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          setConfirmOpen(true);
                          setSelectedService(item);
                        }}
                        color="primary"
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
              <div>
                <div style={{ marginTop: 30 }}>
                  <Typography variant="subtitle2">Add New Service:</Typography>
                </div>
                <div style={{ marginTop: 30 }}>
                  <TextField
                    label="Service Name"
                    variant="outlined"
                    fullWidth
                    onChange={e => setNewServiceName(e.target.value)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    width: "100%",
                    marginTop: 30,
                  }}
                >
                  <Button
                    onClick={() => {
                      handleAdd();
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Add Service
                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
      <Dialog
        open={confirmOpen}
        maxWidth="sm"
        fullWidth
        onClose={() => {
          setConfirmOpen(false);
        }}
      >
        <DialogContent>
          <Typography>
            <b>Are you sure you want to delete {selectedService?.label}?</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setConfirmOpen(false);
            }}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDelete();
            }}
            variant="contained"
            color="primary"
          >
            Delete Service
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snackOpen}
        onClose={() => setSnackOpen(false)}
        autoHideDuration={5000}
        message="Service Settings Updated!"
      />
    </div>
  );
}
