import { supabase } from "../supabase";

/**
 * Uploads a file to a specified bucket in Supabase.
 *
 * @param {string} filePath The path where the file should be uploaded in the bucket.
 * @param {File} file The file to be uploaded.
 * @param {string} bucketName The name of the bucket where the file should be uploaded.
 * @return {Promise<object>} A promise which resolves to an object containing the uploaded file data or an error if the upload fails.
 */
async function useSupabaseFileUpload(filePath, file, bucketName) {
  try {
    const { data, error } = await supabase.storage
      .from(bucketName)
      .upload(filePath, file);

    if (error) {
      console.error("Error during upload:", error);
      return { error };
    }
    return { data };
  } catch (error) {
    console.error("Error while uploading:", error);
    return { error };
  }
}

export default useSupabaseFileUpload;
