/**
 * Formats a phone number by removing spaces and ensuring it is in the international format
 * starting with +27 for South African numbers.
 *
 * @param {string} number - The phone number to be formatted.
 * @returns {string} - The formatted phone number in the form of '+27XXXXXXXXX'.
 *
 * @example
 * formatPhoneNumber('082 123 4567'); // Returns '+27821234567'
 * formatPhoneNumber('+27082 123 4567'); // Returns '+27821234567'
 * formatPhoneNumber('821234567'); // Returns '+27821234567'
 */
function formatPhoneNumber(number) {
  let formattedPhoneNumber = number;

  formattedPhoneNumber = formattedPhoneNumber.replace(/ /g, "");

  if (formattedPhoneNumber.startsWith("0")) {
    formattedPhoneNumber = `+27${formattedPhoneNumber.slice(1, 10)}`;
  }

  if (formattedPhoneNumber.startsWith("+270")) {
    formattedPhoneNumber = `+27${formattedPhoneNumber.slice(4, 13)}`;
  }

  if (formattedPhoneNumber.length === 9) {
    formattedPhoneNumber = `+27${formattedPhoneNumber}`;
  }

  return formattedPhoneNumber;
}

module.exports = formatPhoneNumber;
