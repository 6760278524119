import React from "react";
import { Grid, Typography } from "@material-ui/core";

import sqlToStandardDate from "../../../shared/functions/sqlToStandardDate";

/**
 * Displays lead details.
 *
 * This component takes a lead object and a supplier object as a prop and
 * displays the details of the lead.
 *
 * @param {object} props - The properties for the Details component.
 * @param {object} props.lead - The lead object.
 * @param {object} props.supplier - The supplier object.
 *
 * @returns {React.ReactElement} The Details component.
 */
export default function AgentAssistDetails({ lead, supplier }) {
  return (
    <Grid container item direction="row" xs={12} spacing={1}>
      {lead.created_at && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Date Submitted:</Typography>
          <Typography variant="body2">
            {sqlToStandardDate(lead.created_at)}
          </Typography>
        </Grid>
      )}
      {lead.dealer_code && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Dealer Code:</Typography>
          <Typography variant="body2">{lead.dealer_code}</Typography>
        </Grid>
      )}
      {lead.category && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Category:</Typography>
          <Typography variant="body2">{lead.category}</Typography>
        </Grid>
      )}
      {lead.status && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Lead Status:</Typography>
          <Typography variant="body2">{lead.status}</Typography>
        </Grid>
      )}
      {lead.status === "In Progress" && lead.ticket_in_progress_status && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Status Feedback:</Typography>
          <Typography variant="body2">
            {lead.ticket_in_progress_status}
          </Typography>
        </Grid>
      )}
      {lead.status === "Closed" && lead.ticket_closed_status && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Status Feedback:</Typography>
          <Typography variant="body2">{lead.ticket_closed_status}</Typography>
        </Grid>
      )}
      {lead.admin_note && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Helpdesk Feedback:</Typography>
          <Typography variant="body2" paragraph>
            {lead.admin_note}
          </Typography>
        </Grid>
      )}
      {lead.supplier && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Supplier:</Typography>
          <Typography variant="body2">{supplier.supplier}</Typography>
        </Grid>
      )}
      {lead.supplier_ticket_date && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Supplier Ticket Date: </Typography>
          <Typography variant="body2">{lead.supplier_ticket_date}</Typography>
        </Grid>
      )}
      {lead.supplier_ticket_number && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">Supplier Ticket Number: </Typography>
          <Typography variant="body2">{lead.supplier_ticket_number}</Typography>
        </Grid>
      )}
      {lead.user_note && (
        <Grid item xs={12} lg={4} md={6}>
          <Typography variant="subtitle2">User Notes:</Typography>
          <Typography variant="body2">{lead.user_note}</Typography>
        </Grid>
      )}
    </Grid>
  );
}
