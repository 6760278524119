import React, { useContext } from "react";
import Papa from "papaparse";
import moment from "moment";
import { supabase } from "../supabase";
import useSupabaseFileUpload from "../hooks/useSupabaseFileUpload";
import { startGenericFibreCsvImport } from "../api";

const SupabaseContext = React.createContext();

/**
 * Custom hook to access the Supabase context.
 *
 * @returns {object} The current context value for Supabase.
 */
export function useSB() {
  return useContext(SupabaseContext);
}

/**
 * The Supabase provider component.
 *
 * This component provides a context for all other components to access the Supabase client and
 * database functions. It wraps all other components and provides the necessary functions to interact
 * with the Supabase database.
 *
 * @param {object} props The props to pass to the context.
 * @param {object} props.children The children components to render.
 *
 * @returns {React.ReactElement} The rendered context provider component.
 */
export default function SupabaseProvider({ children }) {
  /**
   * Retrieves and returns all records from a specified table in the Supabase database.
   *
   * @param {string} table - The name of the table to query.
   * @param {string} orderBy - The column name by which to order the results.
   * @returns {Promise<Array>} A promise that resolves to an array of records from the specified table.
   * The promise is rejected if an error occurs during the query.
   */
  function GetWholeTable(table, orderBy) {
    return new Promise((resolve, reject) => {
      supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from(table)
        .select()
        .order(orderBy)
        .then(data => {
          if (data.error) {
            reject(data.error);
          } else {
            resolve(data.data);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  /**
   * Retrieves data from a Supabase table, filtered by the given conditions.
   *
   * The conditions are specified as an array of arrays, where each inner array contains three elements:
   * 1. The column name to filter by.
   * 2. The operator to use for the comparison. Supported operators are:
   *   - "eq": Equal to
   *   - "gt": Greater than
   *   - "lt": Less than
   *   - "gte": Greater than or equal to
   *   - "lte": Less than or equal to
   *   - "like": LIKE operator for case-sensitive comparison
   *   - "ilike": ILIKE operator for case-insensitive comparison
   *   - "is": IS NULL operator
   *   - "in": IN operator
   *   - "neq": Not equal to
   *   - "contains": Array contains
   *   - "containedBy": Array contained by
   * 3. The value to compare with.
   *
   * @param {string} table The name of the Supabase table to retrieve from.
   * @param {Array} conditions An array of arrays, where each inner array contains the column name,
   *   operator, and value to filter by.
   *
   * @returns {Promise<Array>} A promise that resolves to an array of objects, where each object
   *   represents a row in the table.
   */
  async function GetTableWhere(table, ...conditions) {
    try {
      const query = supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from(table)
        .select();

      if (conditions) {
        conditions.forEach(([column, operator, value]) => {
          switch (operator) {
            case "eq": // Equal to
              query.eq(column, value);
              break;
            case "gt": // Greater than
              query.gt(column, value);
              break;
            case "lt": // Less than
              query.lt(column, value);
              break;
            case "gte": // Greater than or equal to
              query.gte(column, value);
              break;
            case "lte": // Less than or equal to
              query.lte(column, value);
              break;
            case "like": // LIKE operator for case-sensitive comparison
              query.like(column, value);
              break;
            case "ilike": // ILIKE operator for case-insensitive comparison
              query.ilike(column, value);
              break;
            case "is": // IS NULL operator
              query.is(column, value);
              break;
            case "in": // IN operator
              query.in(column, value);
              break;
            case "neq": // Not equal to
              query.neq(column, value);
              break;
            case "contains": // Array contains
              query.contains(column, value);
              break;
            case "containedBy": // Array contained by
              query.containedBy(column, value);
              break;
            default:
              throw new Error(`Unsupported operator: ${operator}`);
          }
        });
      }
      const { data, error } = await query;
      if (error) {
        throw new Error(error.message);
      }
      return data;
    } catch (error) {
      console.error(`Error fetching ${table}:`, error);
    }
  }

  /**
   * Inserts a new row into the specified Supabase table.
   *
   * @param {string} table - The name of the Supabase table to insert into.
   * @param {object} data - An object representing the data to insert as a new row in the table.
   *
   * @returns {Promise<object>} A promise that resolves to the inserted row's data.
   */
  async function insertRow(table, data) {
    return await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from(table)
      .insert(data)
      .select();
  }

  /**
   * Updates an existing row in the specified Supabase table.
   *
   * @param {string} table - The name of the Supabase table to update.
   * @param {number|string} rowId - The ID of the row to update.
   * @param {object} updateData - An object representing the data to update for the row.
   *
   * @returns {Promise<object>} A promise that resolves to the updated row's data.
   */
  async function updateRow(table, rowId, updateData) {
    return await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from(table)
      .update(updateData)
      .eq("id", rowId);
  }

  /**
   * Deletes a row from the specified Supabase table.
   *
   * @param {string} table - The name of the Supabase table to delete from.
   * @param {number|string} rowId - The ID of the row to delete.
   *
   * @returns {Promise<object>} A promise that resolves to the deleted row's data.
   */
  async function deleteRow(table, rowId) {
    return await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from(table)
      .delete()
      .eq("id", rowId);
  }

  /**
   * Retrieves a single row from the specified Supabase table, by its ID.
   *
   * @param {string} table - The name of the Supabase table to retrieve from.
   * @param {number|string} rowId - The ID of the row to retrieve.
   *
   * @returns {Promise<object>} A promise that resolves to the retrieved row's data, or null if it does not exist.
   */
  async function getRowById(table, rowId) {
    return (
      await supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from(table)
        .select()
        .eq("id", rowId)
    ).data[0];
  }

  /**
   * Uploads a generic fibre CSV file to Supabase and initiates the import process.
   *
   * @param {Array} records - The records to be converted to CSV and uploaded.
   * @param {string} supplierName - The name of the supplier, used to generate the file name.
   * @returns {Promise<void>} A promise that resolves when the file is uploaded and the import process is started.
   *
   * @throws {Error} Throws an error if the file upload fails.
   */
  async function uploadGenericFibreCsv(records, supplierName) {
    // eslint-disable-next-line no-async-promise-executor, no-unused-vars
    return new Promise(async (res, rej) => {
      const csv = Papa.unparse(records, { type: "text/csv" });
      const file = new Blob([csv], { type: "text/csv" });
      const fileName = `${supplierName}_${moment().format("YYYYMMDDHHmmss")}`; // filename
      // eslint-disable-next-line no-unused-vars
      const upload = await useSupabaseFileUpload(
        fileName,
        file,
        "fibre_data_imports" // bucket name
      ).then(async res2 => {
        if (!res2.error) {
          // use API
          const obj = {
            filePath: fileName,
            bucketName: "fibre_data_imports",
          };
          const response = await startGenericFibreCsvImport(obj);
          if (response.error) {
            throw new Error(response.error);
          }
          // eslint-disable-next-line no-alert
          alert("File uploaded successfully");
          res();
        } else {
          // eslint-disable-next-line no-alert
          alert("Error uploading file");
          throw new Error(res.error);
        }
      });
    });
  }

  // eslint-disable-next-line react/jsx-no-constructed-context-values

  // eslint-disable-next-line
  const value = {
    GetTableWhere,
    GetWholeTable,
    updateRow,
    insertRow,
    deleteRow,
    getRowById,
    uploadGenericFibreCsv,
  };

  return (
    <SupabaseContext.Provider value={value}>
      {children}
    </SupabaseContext.Provider>
  );
}
