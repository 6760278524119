// #useAuthUpdated

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { ArrowLeftOutlined, ArrowRightAlt } from "@material-ui/icons";
import React, { useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../contexts/AuthContext";
import { useDb } from "../../contexts/DatabaseContext";

/**
 * AdminDrilldown component fetches and displays drilldown data for admin users.
 * It supports both connections and activations types and allows pagination, level selection, and CSV export.
 *
 * @component
 * @returns {React.Element} The rendered component.
 *
 * @example
 * <AdminDrilldown />
 *
 * @function AdminDrilldown
 *
 * @description
 * This component fetches drilldown data based on the URL parameters and displays it in a table format.
 * It supports pagination, level selection, and CSV export functionality.
 *
 * @returns {React.Element} The rendered component.
 */
export default function AdminDrilldown() {
  const { type, rank, dealerId, year, month, network } = useParams();
  const {
    getDrillDownConnectionsAdmin,
    getDrillDownActivationsAdmin,
    getDealerName,
    GetLevelsBelow,
  } = useDb();
  const { currentUser } = useAuth();
  const history = useHistory();
  const levels = GetLevelsBelow(parseInt(currentUser.level));

  // Action State
  const [loaded, setLoaded] = useState(false);
  const [dealers, setDealers] = useState([]);
  const [dealerName, setDealerName] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState(null);

  // Pagination
  const itemsPerPage = 50; // <- Not state but related to below pagination
  const [page, setPage] = useState(1);

  const handleLevelChange = e => {
    setSelectedLevel(e.target.value - 1);
    // console.log(e.target.value)
    window.location.replace(
      `/dd-report-admin/activations/${
        e.target.value - 1
      }/${dealerId}/${year}/${month}/${network}`
    );
  };

  const firstLoad = async () => {
    const parsedRank = parseInt(rank);
    if (type === "connections") {
      const d = await getDrillDownConnectionsAdmin(
        parsedRank + 1,
        month,
        year,
        network,
        dealerId
      );
      const dName = await getDealerName(dealerId);
      setDealerName(dName);
      setSelectedLevel(parsedRank);
      setDealers(d);
      setLoaded(true);
    } else if (type === "activations") {
      const d = await getDrillDownActivationsAdmin(
        parsedRank + 1,
        month,
        year,
        network,
        dealerId
      );
      const dName = await getDealerName(dealerId);
      setSelectedLevel(parsedRank);
      setDealerName(dName);
      setDealers(d);
      setLoaded(true);
    }
  };

  if (!loaded) {
    firstLoad();
    return <CircularProgress />;
  }
  return (
    <div>
      {rank === "1" ? (
        <h3>
          Total allocated {type} for Level {parseInt(rank) + 1}
        </h3>
      ) : (
        <p>
          Total allocated {type} for all Level {parseInt(rank) + 1} dealers
          under <b>{dealerName}</b>
        </p>
      )}
      <Button
        onClick={() => history.goBack()}
        variant="contained"
        color="primary"
        startIcon={<ArrowLeftOutlined />}
      >
        Back
      </Button>
      <br />
      <br />
      <Card>
        <CardContent>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>User Level</TableCell>
                  <TableCell>User Name</TableCell>
                  <TableCell>User Phone</TableCell>
                  <TableCell>Total {type}</TableCell>
                  <TableCell>Total Allocated {type}</TableCell>
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {dealers &&
                  dealers
                    .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                    .map(d => (
                      <TableRow key={d.id}>
                        <TableCell>{d.rank}</TableCell>
                        <TableCell>{d.dealerName}</TableCell>
                        <TableCell>{d.phone}</TableCell>
                        <TableCell>
                          {type === "connections" ? d.connTotal : d.actTotal}
                        </TableCell>
                        <TableCell>
                          {type === "connections"
                            ? d.connTotal
                            : d.directActTotal}
                        </TableCell>
                        <TableCell align="right">
                          <Link
                            to={`/drilldown-sims/${network}/${
                              d.id
                            }/${year}/${month}/${
                              type === "connections" ? "connected" : "activated"
                            }/${
                              type === "connections"
                                ? "connection"
                                : "activation"
                            }`}
                          >
                            <Button
                              color="primary"
                              variant="outlined"
                              endIcon={<ArrowRightAlt />}
                            >
                              View Sims
                            </Button>
                          </Link>
                          <Link
                            to={`/dealer-all-children/${d.id}/${network}/${year}/${month}`}
                          >
                            <Button
                              color="primary"
                              variant="outlined"
                              endIcon={<ArrowRightAlt />}
                            >
                              View Downstream
                            </Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <CardActions>
          <Pagination
            count={Math.ceil(dealers.length / itemsPerPage)}
            page={page}
            onChange={(e, val) => setPage(val)}
            defaultPage={1}
            color="primary"
            size="large"
            showFirstButton
            showLastButton
          />

          <FormControl>
            <InputLabel>Downstream Level</InputLabel>
            <Select
              value={selectedLevel + 1}
              onChange={handleLevelChange}
              style={{
                width: 170,
              }}
            >
              {levels &&
                levels.map(l => (
                  <MenuItem key={l.id} value={l.level}>
                    {l.level}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <CSVLink
            data={dealers}
            style={{ marginTop: 5, textDecoration: "none" }}
            filename={`${type}_${dealerName}`}
          >
            <Button
              variant="contained"
              startIcon={<FontAwesomeIcon icon={faFileExcel} />}
              style={{ backgroundColor: "green", color: "#fff" }}
            >
              {" "}
              Export to CSV
            </Button>
          </CSVLink>
        </CardActions>
      </Card>
    </div>
  );
}
