import React from "react";
import { AuthProvider } from "../contexts/AuthContext";
import Wrapper from "./Wrapper";
import Loading from "./Loading";

/**
 * The main application component.
 *
 * This component wraps the main application logic inside a Provider for the
 * Authentication context. It also wraps the entire app in a Loading component
 * that shows a loading indicator until the app is ready.
 *
 * @returns {React.ReactElement} The application component element.
 */
function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Loading>
          <Wrapper />
        </Loading>
      </AuthProvider>
    </div>
  );
}

export default App;
