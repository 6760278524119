import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import {
  ContactMail,
  Delete,
  Face,
  ThumbDown,
  ThumbUp,
} from "@material-ui/icons";
import { CardActions } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useCompany } from "../../../contexts/CompanyContext";
import BackLink from "../../shared/BackLink";
import DeleteRequest from "./DeleteRequest";
import DeclineRequest from "./DeclineRequest";
import CreateDealerForm from "../CreateDealerForm";
import { useSB } from "../../../contexts/SupabaseContext";
import sqlToStandardDate from "../../shared/functions/sqlToStandardDate";
import * as API from "../../../api";
import { useAuth } from "../../../contexts/AuthContext";
import sendApprovalEmail from "./ApprovalEmails";
import SnapshotTiles from "../../user-profile/SnapshotTiles";

/**
 * Component for displaying and managing details of a dealer approval request.
 * It fetches dealer and parent dealer information, displays them, and allows
 * for approving, declining, or deleting the request. The component also handles
 * sending approval emails and updating dealer information.
 *
 * State:
 * - dealer: Contains dealer details.
 * - parent: Contains parent dealer details.
 * - deleteOpen: Boolean controlling the delete confirmation dialog.
 * - declineOpen: Boolean controlling the decline confirmation dialog.
 * - state: Loading and error statuses.
 * - upStream: Contains upstream structure details.
 * - dialogIsOpen: Boolean controlling the edit details dialog.
 *
 * Effects:
 * - Fetches dealer request details on component mount and when `id` changes.
 * - Fetches parent and upstream structure details when dealer information is available.
 */
export default function ApprovalDetail() {
  const { id } = useParams();
  const history = useHistory();
  const { getRowById, updateRow, GetTableWhere } = useSB();
  const { productTypes, suppliers } = useCompany();
  const [dealer, setDealer] = useState(null);
  const [parent, setParent] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [declineOpen, setDeclineOpen] = useState(false);
  const [state, setState] = useState({
    loading: false,
    error: null,
  });
  const [upStream, setUpstream] = useState([]);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const { currentUser } = useAuth();

  // Function to get the dealer request
  const getRequest = async () => {
    const dealerRequest = await getRowById("dealers", id);
    if (!dealerRequest) {
      setState({
        loading: false,
        error: "No dealer found",
      });
    }
    setDealer(dealerRequest);
  };

  // Function to get the parent dealer by the parent dealer code
  const getParent = async () => {
    const parentDealerRequest = await GetTableWhere("dealers", [
      "dealer_code",
      "eq",
      dealer.parent_dealer,
    ]);

    setParent(await parentDealerRequest[0]);
  };

  // Function to get the dealers upstream structure
  const getUpstream = async () => {
    const ParentDealers = await GetTableWhere("commission_org_structure", [
      "dealer_code",
      "eq",
      dealer.parent_dealer,
    ]);
    setUpstream(ParentDealers);
  };

  // useEffect to get the parent and upstream structure
  useEffect(() => {
    if (dealer) {
      getParent();
      getUpstream();
    }
  }, [dealer]);

  // close dialog function
  const closeDialog = () => {
    setDialogIsOpen(false);
    getRequest();
  };

  // useEffect to get the dealer request
  useEffect(() => {
    const unsubscribe = getRequest();
    return unsubscribe;
  }, [id]);
  /**
   * Handle approve button click
   * 1. Set loading to true
   * 2. Get new dealer code
   * 3. Update dealer row with new dealer code and status
   * 4. Send approval email
   * 5. Handle email error
   * 6. Set loading to false and navigate to manage-dealers if email is sent successfully
   */
  const handleApprove = async () => {
    setState({
      loading: true,
      error: null,
    });

    try {
      const result = await API.getNewDealerCode();

      await updateRow("dealers", id, {
        status: 2,
        dealer_code: result.data.dealer_code,
      });

      let emailError = null;

      try {
        await sendApprovalEmail({
          parent,
          dealer,
          dealer_code: result.data.dealer_code,
          last_updated_by: currentUser.auth_id,
        });
      } catch (err) {
        console.error("Email error:", err); // Handle email error
        emailError = "Failed to send approval email.";
      }
      if (emailError) {
        setState({
          loading: false,
          error: emailError,
        });
      } else {
        setState({
          loading: false,
          error: null,
        });
        // Navigate only after emails are successfully sent
        history.push("/manage-dealers");
      }
    } catch (err) {
      console.error("Approval error:", err); // Handle error in getting dealer code or updating row
      setState({
        loading: false,
        error: err.message,
      });
    }
  };

  // function to parse the selected products
  const parseSelectedProducts = productsFromUser => {
    if (!productsFromUser) {
      return "No Products Selected";
    }
    // filter the selected products
    const selectedProducts = productTypes.filter(product =>
      productsFromUser.includes(product.id)
    );
    //  return the selected products by mapping the product names and joining them
    return selectedProducts.map(product => product.name).join(", ");
  };

  // function to parse the selected suppliers
  const parseSelectedSuppliers = suppliersFromUser => {
    if (!suppliers) {
      return "No Suppliers Selected";
    }
    // filter the selected suppliers
    const selectedSuppliers = suppliers.filter(supplier =>
      suppliersFromUser.includes(supplier.id)
    );
    //  return the selected supplier by maping the supplier names and joining them
    return selectedSuppliers.map(supplier => supplier.supplier).join(", ");
  };

  return (
    <div>
      <h1>Approval Detail</h1>
      <BackLink />
      {dealer ? (
        <div>
          <SnapshotTiles currentUser={dealer} otherProfile />
          <Card style={{ marginTop: 30 }}>
            <CardActions>
              <Button
                startIcon={<ThumbUp />}
                variant="contained"
                color="primary"
                onClick={handleApprove}
                disabled={state.loading}
              >
                Approve
              </Button>
              <Button
                startIcon={<ThumbDown />}
                variant="contained"
                color="secondary"
                disabled={state.loading}
                onClick={() => setDeclineOpen(true)}
              >
                Decline
              </Button>
              <Button
                startIcon={<Delete />}
                color="secondary"
                disabled={state.loading}
                onClick={() => setDeleteOpen(true)}
              >
                Delete
              </Button>
              {state.error && (
                <Typography color="secondary">{state.error}</Typography>
              )}
            </CardActions>
          </Card>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardHeader
                  title="Person Details"
                  avatar={<Avatar src={dealer.person_photo} />}
                  subheader={`Submitted: ${sqlToStandardDate(
                    dealer.created_at
                  )}`}
                />
                <CardActions>
                  <Button
                    startIcon={<ContactMail />}
                    variant="contained"
                    color="primary"
                    target="_blank"
                    href={dealer.id_photo}
                  >
                    View ID Photo
                  </Button>
                  <Button
                    startIcon={<Face />}
                    variant="contained"
                    color="primary"
                    target="_blank"
                    href={dealer.person_photo}
                  >
                    View Headshot
                  </Button>
                  <div style={{ marginLeft: 10 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setDialogIsOpen(true);
                      }}
                    >
                      Edit Details
                    </Button>
                  </div>
                </CardActions>
                <CardContent>
                  <br />
                  <Typography>Name: {dealer.contact_name}</Typography>
                  <Typography>Surname: {dealer.contact_surname}</Typography>
                  <Typography>
                    ID/Passport Number: {dealer.contact_id_no}
                  </Typography>
                  <Typography>Gender: {dealer.gender}</Typography>
                  <Typography>Nationality: {dealer.nationality}</Typography>
                  <Typography>Phone: {dealer.phone}</Typography>
                  <Typography>Payments Phone: {dealer.alt_phone}</Typography>
                  <Typography>RICA Online: {dealer.operator_id} </Typography>
                  <br />
                  <Typography>
                    Date of Birth: {sqlToStandardDate(dealer.date_of_birth)}
                  </Typography>
                  <br />
                  <Typography>Street: {dealer.street}</Typography>
                  <Typography>Suburb: {dealer.suburb}</Typography>
                  <Typography>City: {dealer.city}</Typography>
                  <Typography>Province: {dealer.province}</Typography>
                  <Typography>Country: {dealer.country} </Typography>
                  <br />
                  <Typography style={{ textTransform: "capitalize" }}>
                    Selected Product Types:{" "}
                    {parseSelectedProducts(dealer.selected_product_types)}
                  </Typography>
                  <Typography style={{ textTransform: "capitalize" }}>
                    Selected Suppliers:{" "}
                    {parseSelectedSuppliers(dealer.selected_suppliers)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardHeader
                  title="Manager Details"
                  avatar={<Avatar />}
                  subheader={`${parent?.dealer_code}: ${parent?.contact_name} ${parent?.contact_surname}`}
                />
                <CardContent>
                  {/* <Typography>
                    Type (Level):{" "}
                    {levels && levels[dealer.parent?.rank - 2]?.label} (
                    {dealer.parent?.rank}){" "}
                  </Typography> */}
                  <br />
                  <Typography>Name: {parent?.contact_name}</Typography>
                  <Typography>Surname: {parent?.contact_surname}</Typography>
                  <Typography>
                    ID/Passport Number: {parent?.contact_id_no}
                  </Typography>
                  <Typography>Phone: {parent?.phone}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  {parent && upStream !== undefined ? (
                    <>
                      <Typography>Parent structure:</Typography>

                      <Typography>
                        Assistant Team Leader:{" "}
                        {upStream[0]?.assistant_team_leader}
                      </Typography>

                      <Typography>
                        {" "}
                        Tier 1 Team Leader: {upStream[0]?.tier_1_team_leader}
                      </Typography>

                      <Typography>
                        Tier 2 Team Leader: {upStream[0]?.tier_2_team_leader}
                      </Typography>

                      <Typography>
                        Sales manager: {upStream[0]?.sales_manager}
                      </Typography>

                      <Typography>Dealer: {upStream[0]?.dealer}</Typography>

                      <Typography>
                        Super Dealer: {upStream[0]?.super_dealer}
                      </Typography>

                      <br />
                    </>
                  ) : (
                    <Typography>No Parent Found</Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <DeleteRequest
            reqId={id}
            open={deleteOpen}
            close={() => setDeleteOpen(false)}
          />
          <DeclineRequest
            reqId={id}
            open={declineOpen}
            close={() => setDeclineOpen(false)}
          />
          <CreateDealerForm
            dialogIsOpen={dialogIsOpen}
            closeDialog={closeDialog}
            dealerProp={dealer}
            editingApproval
            editing
          />
        </div>
      ) : (
        <LinearProgress />
      )}
    </div>
  );
}
