import React from "react";
import AllUsers from "../dealercomps/reports/AllUsers";
import ImportFibreReport from "../import-reports/ImportFibreReport";
import DealerAgents from "../manage-dealers/dealer-detail-r/DealerAgents";
import DealerDetail from "../manage-dealers/dealer-detail-r/DealerDetail";
import ManageDealers from "../manage-dealers/ManageDealers";
import AdminDrilldown from "../reports/AdminDrilldown";
import LevelSettings from "../settings/levels/LevelSettings";
import AvailableServices from "../settings/services/AvailableServices";
import ProductTypes from "../settings/Suppliers/ProductTypes";
import Settings from "../settings/Settings";
import PrivateRoute from "./PrivateRoute";
import AllDealers from "../manage-dealers/dealer-lists-r/AllDealers";
import DealersChildDownstream from "../dealercomps/subdealers/DealersChildDownstream";
import DealerApprovals from "../manage-dealers/approvals/DealerApprovals";
import ApprovalDetail from "../manage-dealers/approvals/ApprovalDetail";
import GlobalAlerts from "../global-alerts/GlobalAlerts";
import SadvAgentCsvImport from "../shared/SadvAgentCsvImport";
import AdminSection from "../admin-section/AdminSection";
import AvailableSuppliers from "../settings/Suppliers/AvailableSuppliers";
import EditSupplier from "../settings/Suppliers/EditSupplier";
import EditProduct from "../settings/Suppliers/EditProduct";
import AdminSupplier from "../settings/Suppliers/AdminSupplier";
import SelectDash from "../dealercomps/agent-dashboard/SelectDash";
import Supplier from "../dealercomps/agent-dashboard/dash-components/Supplier";
import OrderOverride from "../admin-section/order-override/OrderOverride";
import TrainingModules from "../training/TrainingModules";
import TrainingContent from "../training/TrainingContent";
import TrainingMarks from "../training/TrainingMarks";
import TrainingContentViewer from "../training/TrainingContentViewer";
import TrainingAttempt from "../training/TrainingAttempt";
import EditSalesNote from "../admin-section/EditSalesNote";
import AdminSalesView from "../admin-section/admin-sales-leads/AdminSalesView";
import AlertsList from "../global-alerts/AlertsList";
import CreateAlert from "../global-alerts/CreateAlert";
import UpdatePassword from "../authenticate/UpdatePassword";
import EmailVerification from "../manage-dealers/EmailVerification";
import PhoneVerification from "../manage-dealers/PhoneVerification";
import ProfilePage from "../user-profile/ProfilePage";
import commissions from "../admin-section/finance/commissions";
import BannerSettings from "../settings/Banner/BannerSettings";
import AdminAgentAssistFullView from "../admin-section/admin-agent-assist/AdminAgentAssistFullView";

/**
 * Routes component that defines all the private routes for the admin section.
 * Each route is protected by the PrivateRoute component which ensures that
 * only authenticated users can access these routes.
 *
 * @component
 * @example
 * return (
 *   <Routes />
 * )
 *
 * @returns {React.Element} The rendered routes for the admin section.
 */
export default function Routes() {
  return (
    <>
      <PrivateRoute exact path="/" component={SelectDash} />
      <PrivateRoute exact path="/Supplier/:supplierId" component={Supplier} />
      <PrivateRoute
        path="/import-fibre-activity"
        component={ImportFibreReport}
      />
      <PrivateRoute exact path="/manage-dealers" component={ManageDealers} />
      <PrivateRoute exact path="/settings" component={Settings} />
      <PrivateRoute path="/settings/levels" component={LevelSettings} />
      <PrivateRoute path="/settings/services" component={AvailableServices} />
      <PrivateRoute path="/settings/suppliers" component={AvailableSuppliers} />
      <PrivateRoute path="/settings/product-types" component={ProductTypes} />
      <PrivateRoute path="/settings/banner" component={BannerSettings} />
      <PrivateRoute
        exact
        path="/dd-report-admin/:type/:rank/:dealerId/:year/:month/:network"
        component={AdminDrilldown}
      />
      <PrivateRoute exact path="/all-users" component={AllUsers} />
      <PrivateRoute path="/dealer-detail/:dealerId" component={DealerDetail} />
      <PrivateRoute path="/all-dealers" component={AllDealers} />
      <PrivateRoute
        path="/child-dealer-downstream/:dealerId"
        component={DealersChildDownstream}
      />
      <PrivateRoute path="/dealer-agents/:dealerId" component={DealerAgents} />
      {/* Dealer Approvals */}
      <PrivateRoute path="/approvals" component={DealerApprovals} />
      <PrivateRoute path="/approval-detail/:id" component={ApprovalDetail} />
      <PrivateRoute exact path="/alerts" component={GlobalAlerts} />
      <PrivateRoute exact path="/alerts/view" component={AlertsList} />
      <PrivateRoute exact path="/alerts/create" component={CreateAlert} />
      <PrivateRoute exact path="/alerts/:id/edit" component={CreateAlert} />
      {/* Training Videos */}
      <PrivateRoute exact path="/training" component={TrainingContentViewer} />
      {/* Commission */}
      <PrivateRoute
        exact
        path="/import-sadv-agents-csv"
        component={SadvAgentCsvImport}
      />
      <PrivateRoute exact path="/admin-section" component={AdminSection} />
      <PrivateRoute exact path="/addSupplier" component={EditSupplier} />
      <PrivateRoute path="/Edit/:id/" component={EditSupplier} />
      <PrivateRoute path="/EditProduct/:id/" component={EditProduct} />
      <PrivateRoute path="/admin/Supplier/:id/" component={AdminSupplier} />
      <PrivateRoute exact path="/order-override" component={OrderOverride} />
      <PrivateRoute
        exact
        path="/training-modules"
        component={TrainingModules}
      />
      <PrivateRoute
        exact
        path="/training-content/:id/:name"
        component={TrainingContent}
      />
      <PrivateRoute
        exact
        path="/training-marks/:id/:name"
        component={TrainingMarks}
      />
      <PrivateRoute
        exact
        path="/training-attempt/:id"
        component={TrainingAttempt}
      />
      <PrivateRoute exact path="/edit-sales-note" component={EditSalesNote} />
      <PrivateRoute exact path="/update-password" component={UpdatePassword} />
      <PrivateRoute exact path="/profile" component={ProfilePage} />
      <PrivateRoute
        exact
        path="/sales-leads-admin-view"
        component={AdminSalesView}
      />
      <PrivateRoute
        exact
        path="/verify-email/:verificationId"
        component={EmailVerification}
      />
      <PrivateRoute exact path="/verify-phone" component={PhoneVerification} />
      <PrivateRoute exact path="/commissions" component={commissions} />
      <PrivateRoute
        exact
        path="/admin-agent-assist"
        component={AdminAgentAssistFullView}
      />
    </>
  );
}
