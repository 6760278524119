import React from "react";
import { Box, Typography, Link } from "@material-ui/core";

/**
 * Copyright component renders a copyright notice with a link to the
 * Zawadi Africa website and displays the current year.
 *
 * @component
 * @returns {React.ReactElement} A Box containing the copyright information.
 */
function Copyright() {
  return (
    <Box mt={8} mb={3}>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link
          className="sim-text-link"
          color="inherit"
          target="_blank"
          rel="noopener"
          href="https://zawadi.africa/"
        >
          Zawadi Africa
        </Link>{" "}
        {new Date().getFullYear()}
      </Typography>
    </Box>
  );
}

export default Copyright;
