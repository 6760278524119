/* eslint-disable react/no-danger */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import BackLink from "../shared/BackLink";
import { getAllAlerts } from "../../api";

/**
 * The AlertsList component renders a list of alerts for the current user.
 * The user can filter the alerts by active status.
 *
 * The component fetches the alerts data from the database when mounted.
 * It renders a loading indicator until the data is loaded.
 * Then it renders a list of cards, each containing an alert with its title,
 * description, and active status.
 *
 * The user can edit an alert by clicking on the Edit button.
 *
 * @return {React.Component} The AlertsList component.
 */
export default function AlertsList() {
  const { currentUser } = useAuth();
  const [alerts, setAlerts] = useState([]);
  const [immutableAlerts, setImmutableAlerts] = useState([]);
  const [active, setActive] = useState();
  const [loading, setLoading] = useState(true);

  /**
   * Retrieves all alerts for the current user from the database.
   *
   * This function takes no arguments.
   *
   * It returns a promise that resolves with an array of alerts, each
   * containing the alert's title, description, and active status.
   *
   * If there is an error, it logs an error message to the console.
   */
  async function GetAllAlerts() {
    return await getAllAlerts({ created_by: currentUser.id })
      .then(doc => {
        // Create a Map to store unique items based on alertId.
        const uniqueItemsMap = new Map();

        // Iterate through the doc array to store only one item per alertId.
        doc.data.rows.forEach(item => {
          uniqueItemsMap.set(item.new_alert_id, item);
        });

        // Convert the Map values back to an array.
        const uniqueItemsArray = Array.from(uniqueItemsMap.values());
        // console.log(uniqueItemsMap);
        return uniqueItemsArray;
      })
      .catch(err => {
        // eslint-disable-next-line
        console.error(err, "getAllAlerts", {});
      });
  }

  // useEffect to fetch and set alerts data on component mount.
  useEffect(async () => {
    const data = await GetAllAlerts(currentUser.id);
    setImmutableAlerts(data);

    const mutableAlerts = data?.filter(alert => alert.active === true);

    setAlerts(mutableAlerts);

    setLoading(false);
  }, []);

  // useEffect to filter alerts based on the active state.
  useEffect(() => {
    if (!loading) {
      let mutableAlerts = [...immutableAlerts];

      if (active) {
        mutableAlerts = mutableAlerts.filter(alert => alert.active === true);
      }

      if (active === false && active !== undefined) {
        mutableAlerts = mutableAlerts.filter(alert => alert.active === false);
      }

      setAlerts(mutableAlerts);
    }
  }, [active]);

  return (
    <div>
      <h1>Alerts</h1>
      <BackLink />
      {loading ? (
        <CircularProgress />
      ) : (
        <div>
          {/* Filters */}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <h3>Filters</h3>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Select
                fullWidth
                placeholder="Status"
                name="active"
                options={[
                  { label: "Active", value: true },
                  { label: "Inactive", value: false },
                ]}
                defaultValue={{ label: "Active", value: true }}
                onChange={e => {
                  setActive(e.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 20 }}>
            {alerts &&
              alerts.map(alert => {
                return (
                  <Grid item lg="4" xs="12">
                    <Card style={{ margin: 5 }} elevation={1}>
                      <CardHeader title={alert.title || "Alert"} />
                      {/* eslint-disable-next-line react/no-danger */}
                      <CardContent>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              alert.description || alert.message || alert.msg,
                          }}
                        />
                        <p>Active: {alert.active ? "True" : "False"}</p>
                      </CardContent>
                      <CardActions>
                        <Link to={`/alerts/${alert.new_alert_id}/edit`}>
                          <Button variant="contained" color="primary">
                            Edit
                          </Button>
                        </Link>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        </div>
      )}
    </div>
  );
}
