// #useAuthUpdated

import React, { useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Button,
  Container,
  TextField,
  CssBaseline,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ArrowForward } from "@material-ui/icons";
import { useAuth } from "../../contexts/AuthContext";
import logo from "../../img/ZawadiHighRes.gif";
import Copyright from "./Copyright";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    padding: "30px",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#f6fffd",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

/**
 * A page which allows a user to sign in using their phone number and an one-time
 * password (OTP) sent to their phone.
 *
 * @function
 * @returns {React.ReactElement} The JSX element representing the component.
 * @example
 * import PhoneLogin from "./PhoneLogin";
 *
 * const MyComponent = () => {
 *   return (
 *     <PhoneLogin />
 *   );
 * };
 */
export default function PhoneLogin() {
  const [state, setState] = useState({});
  const [error, setError] = useState("");
  const [otpView, setOtpView] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const { currentUser, loginWithPhone, verifyOtp } = useAuth();
  const [phone, setPhone] = useState("");
  const [otp, setOTP] = useState("");
  const history = useHistory();
  const classes = useStyles();

  const handleChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const isNumber = value => {
    const val = !!Number(value);
    return val;
  };

  const reportError = errorValue => {
    setPageLoading(false);
    setError(errorValue);
  };

  const handleSubmit = async () => {
    try {
      setError(null);
      setPageLoading(true);

      // Validate number
      if (!isNumber(state.phone)) {
        throw new Error("Cellphone number is incorrectly formatted");
      }

      if (state.phone.length !== 10) {
        throw new Error("Cellphone number is incorrect length");
      }

      if (state.phone[0] !== "0") {
        throw new Error("Cellphone number does not start with 0");
      }

      // Format number (+27)
      const formattedCellphoneNumber = `+27${state.phone.slice(1, 10)}`;

      // Log in
      const valid = await loginWithPhone(formattedCellphoneNumber);

      if (valid) {
        setPhone(formattedCellphoneNumber);

        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        // setConfirmationResult(confirmation);
        setOtpView(true);
      } else {
        setError("Number not found");
      }

      setPageLoading(false);
    } catch (err) {
      reportError(typeof err === "string" ? err : err.message);
    }
  };

  const handleOtpSubmit = async () => {
    try {
      setError(null);
      setPageLoading(true);

      verifyOtp(phone, otp);
      // User signed in successfully.
      history.push("/");
    } catch (err) {
      setPageLoading(false);
      setError(typeof err === "string" ? err : err.message);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <div />
      <Helmet>
        <title>Login</title>
      </Helmet>
      <CssBaseline />
      <Box
        style={{
          display: "flex",
          minHeight: "100vh",
          flexDirection: "column",
        }}
        justifyContent="space-between"
      >
        {!otpView ? (
          <Paper className={classes.paper}>
            <img src={logo} width={200} alt="Logo" draggable="false" />
            <Typography
              component="h1"
              variant="h5"
              style={{ marginBottom: "1rem" }}
            >
              Sign in with phone
            </Typography>
            {pageLoading ? (
              <CircularProgress />
            ) : (
              <TextField
                inputProps={{
                  maxLength: 10,
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Phone number"
                name="phone"
                autoFocus
                type="text"
                onChange={handleChange}
                placeholder="eg: 0821234567"
              />
            )}

            <br />
            {error && (
              <Alert style={{ width: "100%" }} severity="error">
                {error}
              </Alert>
            )}
            <span style={{ color: "blue" }}>{currentUser?.email}</span>
            <Button
              onClick={handleSubmit}
              disabled={pageLoading}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              endIcon={<ArrowForward />}
            >
              Next
            </Button>
            <Grid container style={{ margin: "0.5rem 0" }} justify="flex-end">
              <Grid item xs="auto">
                <Link className="sim-text-link" to="/login" variant="body2">
                  Sign in with email
                </Link>
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <Paper className={classes.paper}>
            {logo ? (
              <img src={logo} width={200} alt="Logo" draggable="false" />
            ) : (
              <Typography color="primary" variant="h2">
                <b>ZIMS</b>
              </Typography>
            )}
            <Typography
              component="h1"
              variant="h5"
              style={{ marginBottom: "1rem" }}
            >
              Enter OTP
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Enter OTP"
              name="otp"
              value={otp}
              autoFocus
              onChange={e => {
                setOTP(e.target.value);
              }}
            />
            <br />
            {error && (
              <Alert style={{ width: "100%" }} severity="error">
                {error}
              </Alert>
            )}
            <span style={{ color: "blue" }}>{currentUser?.email}</span>
            <Button
              onClick={handleOtpSubmit}
              disabled={pageLoading}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Submit
            </Button>
            <Alert
              style={{ width: "100%", margin: "0.5rem 0" }}
              severity="info"
            >
              If you haven't received your SMS within 40 seconds, please try
              again
            </Alert>
            <Grid container justify="flex-end" style={{ margin: "0.5rem 0" }}>
              <Grid item xs="auto">
                <Button color="primary" onClick={() => setOtpView(false)}>
                  Retry
                </Button>
              </Grid>
            </Grid>
            <Grid container style={{ margin: "0.5rem 0" }} justify="flex-end">
              <Grid item xs="auto">
                <Link className="sim-text-link" to="/login" variant="body2">
                  Sign in with email
                </Link>
              </Grid>
            </Grid>
          </Paper>
        )}
        <Copyright />
      </Box>
    </Container>
  );
}
