const moment = require("moment");

/**
 * @description
 * Verifies the validity of a South African ID number against date of birth and gender.
 * @param {string} id - The South African ID number to validate
 * @param {number} dob - The date of birth of the ID number holder in Unix time
 * @param {string} userGivenGender - The gender given by the user
 * @param {string} userGivenResidence - The residence of the user
 * @returns {Promise} A promise which resolves to an object with a valid property
 *  and a message property. The valid property is a boolean indicating whether the
 *  ID number is valid or not. The message property is a string with a message
 *  indicating why the ID number is invalid.
 */
function verifyIdNumber(id, dob, userGivenGender, userGivenResidence) {
  let transformedDOB = dob;
  transformedDOB = parseInt(dob, 10) + 7200;

  return new Promise(resolve => {
    const idToValidate = String(id).replace(/\s*/g, "");

    if (idToValidate.length !== 13) {
      resolve({
        valid: false,
        message: "ID number must be 13 digits long",
      });
      return;
    }

    if (!idToValidate.match(/^[0-9]{13}$/g)) {
      resolve({
        valid: false,
        message: "ID number must only contain numbers",
      });
      return;
    }

    const idArray = idToValidate.split("");

    const idMonth = Number(`${idArray[2]}${idArray[3]}`);
    const idDay = Number(`${idArray[4]}${idArray[5]}`);

    if (idMonth < 1 || idMonth > 12) {
      resolve({
        valid: false,
        message: "ID number has an incorrect month value",
      });
      return;
    }

    if (idDay < 1 || idDay > 31) {
      resolve({
        valid: false,
        message: "ID number has an incorrect day value",
      });
      return;
    }

    // // calculate date of birth
    const dateOfBirth = moment(
      `${idArray[0]}${idArray[1]}-${idMonth}-${idDay}`,
      "YY-MM-DD"
    ).format("YYMMDD");
    const formattedDOB = moment.unix(transformedDOB).format("YYMMDD");

    if (String(dateOfBirth) !== String(formattedDOB)) {
      resolve({
        valid: false,
        message: "ID number does not match date of birth",
      });
      return;
    }

    // // calculate gender
    const idGender = Number(idArray[6]);
    const gender = idGender >= 5 ? "M" : "F";

    if (gender !== userGivenGender) {
      resolve({
        valid: false,
        message: "Gender does not match ID Number",
      });
      return;
    }

    // calculate citizenship
    const idCitizenship = Number(idArray[10]);
    const citizenship = userGivenResidence === "ZAF" ? 0 : 1;

    if (idCitizenship !== citizenship) {
      resolve({
        valid: false,
        message: "citizen does not match ID Number",
      });
      return;
    }

    const step1 =
      Number(idArray[0]) +
      Number(idArray[2]) +
      Number(idArray[4]) +
      Number(idArray[6]) +
      Number(idArray[8]) +
      Number(idArray[10]);
    const step2 =
      2 *
      Number(
        `${idArray[1]}${idArray[3]}${idArray[5]}${idArray[7]}${idArray[9]}${idArray[11]}`
      );
    const step3 = Number(
      `${step2}`.split("").reduce((r, v) => (Number(r) || 0) + Number(v), 0)
    );
    const step4 = step1 + step3;
    const step5 = 10 - Number(`${step4}`.charAt(`${step4}`.length - 1));
    const step6 = Number(`${step5}`.charAt(`${step5}`.length - 1));

    // console.log({ step1, step2, step3, step4, step5, step6 });

    /* eslint-disable-next-line */
    if (step6 != idArray[12]) {
      resolve({
        valid: false,
        message: "ID number is incorrect",
      });
      return;
    }

    resolve({
      valid: true,
      message: "ID number is valid",
    });
  });
}

module.exports = verifyIdNumber;
