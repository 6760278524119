import { supabase } from "../../../supabase";

/**
 * Checks if a phone number is already used in the "dealers" table.
 *
 * @param {string} number - The phone number to check.
 * @returns {Promise<boolean>} A promise that resolves to true if the phone number is used, false otherwise.
 */
export default function checkPhoneNumberIsUsed(number) {
  return new Promise(resolve => {
    supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("dealers")
      .select()
      .eq("phone", number)
      .then(result => {
        resolve(result.data.length > 0);
      })
      .catch(err => {
        console.error(err);
        // Return true if error for safety.
        resolve(true);
      });
  });
}
