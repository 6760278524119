import React, { useEffect, useState } from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Snackbar,
  TextField,
  MenuItem,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Backdrop,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import nanoId from "nano-id";
import Markdown from "react-markdown";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useAuth } from "../../../../../contexts/AuthContext";
import { useCompany } from "../../../../../contexts/CompanyContext";
import { supabase } from "../../../../../supabase";
import { agentAssistCategories } from "../../../../shared/constants";
import SBFileUploader from "../../../../shared/SBFileUploader";
import ModalCard from "../../../../shared/ModalCard";
import sendAssistMessage from "../../../../shared/functions/sendAssistMessage.function";
import formatTicketNumber from "../../../../shared/functions/formatTicketNumber";
import { sendEmail } from "../../../../../api";

/**
 * AddLeadModal is a React component that renders a modal for adding a lead.
 *
 * @param {object} props - The component props.
 * @param {string} props.supplier - The supplier name.
 * @param {Function} props.onClose - The function to be called when the modal is closed.
 * @param {boolean} props.open - A boolean indicating whether the modal is open or not.
 *
 * @returns {React.ReactElement} The element to be rendered.
 */
export default function AddAgentAssistTicketModal({ supplier, onClose, open }) {
  const uploadBucket = "agent_assist";
  const now = new Date();
  const { currentUser } = useAuth();
  const { getSupplier } = useCompany();
  const defFormValues = {
    dealer_code: `${currentUser.dealer_code}: ${currentUser.contact_name} ${currentUser.contact_surname}`,
    supplier,
    agreed_to_terms: false,
    category: "",
    status: "Open",
    last_updated_by_pretty: "New",
    supporting_doc_ref: "",
    user_note: "",
    supplier_ticket_date: now.toISOString().slice(0, 10),
    supplier_ticket_number: "",
  };
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [disable, setDisable] = useState(false);
  const [addSupportingDocsModal, setAddSupportingDocsModal] = useState(false);
  const [isFilesUploaded, setIsFilesUploaded] = useState(false);
  const [filesUploaded, setFilesUploaded] = useState([]);
  const [uploadId, setUploadId] = useState("");
  const [formValues, setFormValues] = useState(defFormValues);
  const [supplierDetail, setSupplierDetail] = useState(undefined);
  const [admins, setAdmins] = useState([]);

  // form changes

  useEffect(() => {
    const fetchAdmins = async () => {
      const { data, error } = await supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("admin_roles")
        .select("*, dealers(contact_name, email)")
        .eq("related_supplier", supplierDetail?.id)
        .eq("role_type", 2)
        .eq("is_deleted", false);

      if (error) {
        console.error("Error fetching admins:", error);
      } else {
        setAdmins(data);
      }
    };

    if (supplierDetail?.id) {
      fetchAdmins();
    }
  }, [supplierDetail]);

  const handleChange = event => {
    setDisable(false);
    const { name, value } = event.target;
    if (name === "agreed_to_terms") {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        [name]: !formValues.agreed_to_terms,
      }));
    } else {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        [name]: value,
      }));
    }
  };

  // Handle selects

  const handleCategorySelect = event => {
    setDisable(false);
    setFormValues(prev => ({ ...prev, category: event.target.value }));
  };

  const handleSupplierDateSelect = selectedDate => {
    setFormValues(prev => ({
      ...prev,
      supplier_ticket_date: selectedDate.toISOString().slice(0, 10),
    }));
  };

  //

  const handleIsFilesUploaded = () => setIsFilesUploaded(true);

  /**
   * Toggles the add supporting documents modal.
   * If the form is valid, the modal is toggled.
   * If the form is invalid, an error message is displayed.
   */
  const toggleAddSupportingDocsModal = () => {
    if (formValid()) {
      setAddSupportingDocsModal(old => !old);
    } else {
      setErrorMessage("Please add all required fields");
      setIsSnackbarOpen(true);
    }
  };

  /**
   * Returns true if the form is valid, false otherwise.
   * The form is valid if all required fields have been filled in.
   * The required fields are:
   * - customer_name
   * - address
   * - phone_number
   * - email_address
   * - id_number
   * - agreed_to_terms
   * @returns {boolean} True if the form is valid, false otherwise.
   */
  const formValid = () => {
    return (
      formValues.category &&
      formValues.supplier_ticket_date &&
      formValues.supplier_ticket_number &&
      formValues.agreed_to_terms
    );
  };

  /**
   * Creates a new sales assist entry in the database.
   * If the creation is successful, sends a message to the sales assist table
   * with the ticket number and the user who created the entry.
   * @async
   * @function
   * @returns {Promise<void>}
   */
  const addAgentAssistLead = async () => {
    const { error, data } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("agent_assist")
      .insert(formValues)
      .select();

    const { id: ticket_id } = await data[0];

    if (error) {
      setErrorMessage("Error creating entry");
      setIsSnackbarOpen(true);
      console.error(error.message);
    }

    sendAssistMessage(
      ticket_id,
      `
      ${formatTicketNumber(ticket_id, "ZSA")} created
      `,
      "update",
      "agent_assist_activity"
    );
  };

  /**
   * Sends an agent assist email to the admin with details about the dealer and supplier involved
   * in the agent assist submission.
   *
   * The email is sent to the admin with details about the dealer and supplier involved
   * in the agent assist submission.
   *
   * @param {{admin: object, ticket: object}} params - The parameters for the function.
   * @param {object} params.admin - The admin information, including email.
   * @param {object} params.ticket - The ticket information, including ticket category, date submitted, and user note.
   * @returns {Promise<string>} A promise that resolves with a success message
   * if the email is sent successfully, or rejects with an error message if
   * sending the email fails.
   */
  function sendAgentAssistEmail({ admin, ticket }) {
    return new Promise((resolve, reject) => {
      // Optional: You can add validation logic here if needed
      const email = [admin?.dealers.email];
      const subject = `New Agent Assist for ${supplierDetail?.supplier}`;
      const message = `
      Dear ${admin?.dealers.contact_name},
  
      The following dealer had submitted a new agent assist ticket for this supplier: ${supplierDetail?.supplier}.
  
      Ticket Number: ${ticket?.supplier_ticket_number}
      Name: ${ticket?.dealer_code}
      Ticket Category: ${ticket?.category}
      Date Submitted: ${ticket?.supplier_ticket_date}
      User Note: ${ticket?.user_note}
  
  
      Best regards
      ZIMS Team
      `;
      // Send the email using the sendEmail function
      sendEmail({
        email, // Email to send to
        subject, // Email subject
        message, // Email message
      })
        .then(response => {
          resolve("Email sent successfully", response.data);
        })
        .catch(error => {
          console.error("Error sending email:", error); // Handle error
          reject("Error sending email: ", error);
        });
    });
  }

  /**
   * Handles the add lead event.
   * If the form is valid, it will add a new sales assist entry to the database.
   * If the creation is successful, it will also send a message to the sales assist table
   * with the ticket number and the user who created the entry.
   * If the form is invalid, it will display an error message.
   * @async
   * @function
   * @returns {Promise<void>}
   */
  const handleAddTicket = async () => {
    const ticket = formValues;
    if (formValid()) {
      try {
        await addAgentAssistLead();
        setFormValues(defFormValues);
        onClose();
      } catch (err) {
        setIsSnackbarOpen(true);
      }
    } else {
      setErrorMessage("Please add all required fields");
      setIsSnackbarOpen(true);
      setDisable(true);
    }
    if (admins.length > 0 && formValid()) {
      await Promise.all(
        admins.map(async admin => {
          try {
            await sendAgentAssistEmail({
              admin,
              supplierDetail,
              ticket,
            });
          } catch (err) {
            console.error("Email error:", err);
          }
        })
      );
    }
  };

  /**
   * Fetches the uploaded files for the current lead.
   * If the uploaded files fetch is successful, it updates the state with the list of uploaded files.
   * If the uploaded files fetch is unsuccessful, it throws an error.
   * @async
   * @function
   * @returns {Promise<void>}
   */
  const fetchUploadedFiles = async () => {
    const { data: files, error } = await supabase.storage
      .from(uploadBucket)
      .list(uploadId);

    if (error) {
      throw error;
    }

    if (!files || !files.length) {
      // eslint-disable-next-line no-alert
      alert("No Files to upload");
      setFilesUploaded([]);
      setIsFilesUploaded(false);
      setFormValues(old => ({ ...old, supporting_doc_ref: "" }));
    } else {
      setFilesUploaded(files);
      setFormValues(old => ({ ...old, supporting_doc_ref: uploadId }));
    }
  };

  /**
   * Fetches the sales assist text for the current supplier.
   * If the sales assist text fetch is successful, it updates the state with the sales assist text.
   * If the sales assist text fetch is unsuccessful, it throws an error.
   * @async
   * @function
   * @returns {Promise<void>}
   */
  const fetchSupplierDetail = async () => {
    const supplierData = await getSupplier(supplier);
    setSupplierDetail(await supplierData);
  };

  useEffect(() => {
    if (isFilesUploaded) {
      fetchUploadedFiles();
    }
  }, [addSupportingDocsModal]);

  useEffect(() => {
    setUploadId(nanoId());
    fetchSupplierDetail();
  }, []);

  const viewFiles = filesUploaded.map(file => {
    /**
     * Handles the deletion of a file from the supporting documents.
     * Deletes the file from the storage bucket and then fetches the updated list of uploaded files.
     * @async
     * @function
     * @returns {Promise<void>}
     */
    const handleDelete = async () => {
      await supabase.storage
        .from(uploadBucket)
        .remove([`${uploadId}/${file.name}`]);
      fetchUploadedFiles();
    };

    return (
      <Grid item xs={12} key={file.id}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={10}>
            <Typography>
              <code>{file.name}</code>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Button color="secondary" onClick={handleDelete}>
              <DeleteForeverIcon />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogTitle>Add Agent Assist Ticket</DialogTitle>
      <DialogContent>
        <Grid container xs={12} spacing={2}>
          {supplierDetail?.agent_assist_text && (
            <>
              <Grid item xs={12}>
                <Markdown>{supplierDetail.agent_assist_text}</Markdown>
              </Grid>
              <br />
            </>
          )}
          <Grid item xs={12}>
            <TextField
              required
              select
              value={formValues.category}
              onChange={handleCategorySelect}
              id="outlined-required"
              label="Category"
              variant="outlined"
              helperText="Select the lead category"
              fullWidth
            >
              {agentAssistCategories[
                agentAssistCategories[formValues.supplier]
                  ? formValues.supplier
                  : "def"
              ].map(category => (
                <MenuItem key={category.key} value={category.value}>
                  {category.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                fullWidth
                variant="inline"
                format="yyyy-MM-dd"
                margin="normal"
                id="date-picker-inline"
                label={`Date when the ticket was logged with ${supplierDetail?.supplier}?`}
                value={formValues.supplier_ticket_date}
                onChange={handleSupplierDateSelect}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={`${supplierDetail?.supplier} ticket number:`}
              variant="outlined"
              required
              name="supplier_ticket_number"
              value={formValues.supplier_ticket_number}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid container item xs={12} spacing={2} alignItems="center">
            <Grid item xs={12}>
              <TextField
                label="Additional Notes"
                variant="outlined"
                name="user_note"
                value={formValues.user_note}
                onChange={handleChange}
                fullWidth
                multiline
              />
            </Grid>
            <Grid item xs={12}>
              <br />
              <Typography variant="body2">
                *Please note that utilizing the agent assist function does not
                guarantee the finalization, payment, or installation of an
                order. The purpose of utilizing the agent assist function is
                solely for assistance.
              </Typography>
              <br />
              <Typography variant="body2">
                *When using the agent assist function, please ensure to input
                the ticket details in the designated spaces.
              </Typography>
              <br />
              <Typography variant="subtitle2">
                *Service Level Agreement (SLA) Times: We are currently unable to
                provide precise SLA times. However, rest assured, we will
                provide updates and feedback as soon as possible.
              </Typography>
              <FormControlLabel
                style={{ marginBottom: 10 }}
                control={<Checkbox required />}
                label="Agreed To Terms"
                name="agreed_to_terms"
                checked={formValues.agreed_to_terms}
                onChange={handleChange}
              />
            </Grid>
            {isFilesUploaded ? (
              <Grid item container direction="rows">
                {viewFiles}
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography variant="caption">No Files Uploaded</Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                color="primary"
                onClick={toggleAddSupportingDocsModal}
                disabled={disable}
              >
                Add Supporting Documents
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Snackbar
                open={isSnackbarOpen}
                autoHideDuration={4000}
                onClose={() => setIsSnackbarOpen(false)}
              >
                <Alert
                  severity="error"
                  onClose={() => setIsSnackbarOpen(false)}
                >
                  {errorMessage}
                </Alert>
              </Snackbar>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          alignItems="center"
          direction="row"
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddTicket}
              disabled={disable}
              type="submit"
            >
              Submit
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={onClose}
              type="reset"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      <ModalCard
        open={addSupportingDocsModal}
        onClose={toggleAddSupportingDocsModal}
      >
        <SBFileUploader
          bucketName={uploadBucket}
          folderName={uploadId}
          onClose={toggleAddSupportingDocsModal}
          withSubmit={handleIsFilesUploaded}
        />
      </ModalCard>
    </Dialog>
  );
}
