import React, { useState } from "react";
import { Grid, Button } from "@material-ui/core";

import { ModalCard } from "../../../../shared/ModalCard";
import { AddSupplierLinkModal } from "./AddSupplierLinkModal";

/**
 * A button component that adds a new supplier link to a user's dashboard.
 *
 * Displays a button that opens a modal to add a new supplier link.
 * When the modal is closed, either by submitting a new link or by clicking away,
 * the button's state is reset.
 *
 * @param {object} props - The props passed to the component.
 * @param {object} props.restVar - The rest variable object.
 * @param {Function} props.addSupplierLink - The function to add a supplier link.
 *
 * @returns A React component containing a button and a modal to add a new link
 */
export function AddSupplierLinkButton({ restVar, addSupplierLink }) {
  // Default states
  const defModals = {
    addLinkModal: false,
  };

  // States

  const [modals, setModals] = useState(defModals);

  // Set State handlers

  const toggleAddLinkModal = () =>
    setModals(prev => ({ ...prev, addLinkModal: !prev.addLinkModal }));

  // Submission handler

  const addSupplierLinkHandler = newLink => {
    addSupplierLink(newLink);
    toggleAddLinkModal();
  };

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="flex-end">
        <Button onClick={toggleAddLinkModal} color="secondary">
          Add Link
        </Button>
      </Grid>
      <ModalCard open={modals.addLinkModal} onClose={toggleAddLinkModal}>
        <AddSupplierLinkModal
          restVar={restVar}
          addNewLink={addSupplierLinkHandler}
          onClose={toggleAddLinkModal}
        />
      </ModalCard>
    </Grid>
  );
}

export default AddSupplierLinkButton;
