// #useAuthUpdated

import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
  Card,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAuth } from "../../contexts/AuthContext";
import Copyright from "./Copyright";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    padding: "30px",

    alignItems: "center",
    flexDirection: "column",
    // maxHeight: "max-content",
    backgroundColor: "#f6fffd",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

/**
 * Renders the sign-in component for user authentication.
 *
 * This component provides a login form for users to sign in using their email
 * and password. It includes input fields for email and password, a submit button,
 * and an option to navigate to the forgot password view. The component uses
 * authentication context to handle user login and displays error messages if
 * login fails. It also displays the current user's email if logged in.
 *@param {object} props - The properties for the component.
 * @param {Function} props.toggleForgotPasswordView - Function to toggle the forgot password view.
 * @param {string} props.logo - The logo image URL to display on the sign-in card.
 *
 * @returns {React.ReactElement} The rendered login form component.
 */
export default function SignIn({ toggleForgotPasswordView, logo }) {
  const classes = useStyles();
  const { login, currentUser } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  /**
   * Handles the submission of the login form.
   *
   * The function prevents the default form submission behavior, attempts to log
   * the user in with the provided email and password, and redirects the user to
   * the home page if the login is successful. If a login error occurs, the
   * function sets the error state with the error message. The loading state is
   * also set to true before the login attempt and false after.
   *
   * @param {React.FormEvent<HTMLFormElement>} e - The form event.
   *
   * @returns {Promise<void>} A promise which resolves when the form has been processed.
   */
  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(email.trim(), password);
      history.push("/");
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }

  return (
    <Container component="main" maxWidth="xs">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <CssBaseline />
      <Box
        style={{
          flexDirection: "column",
          minHeight: "100vh",
          display: "flex",
        }}
        justifyContent="space-between"
      >
        <Card className={classes.paper}>
          {logo ? (
            <img
              src={logo}
              width={200}
              alt="Logo"
              draggable="false"
              style={{ marginLeft: "-20px" }}
            />
          ) : (
            <Typography color="primary" variant="h2">
              <b>ZIMS</b>
            </Typography>
          )}
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            Sign in
          </Typography>
          <form onSubmit={handleSubmit} className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email address"
              name="email"
              type="email"
              autoComplete="email"
              autoFocus
              onChange={e => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={e => setPassword(e.target.value)}
            />
            <br />
            {error && (
              <Alert
                style={{ width: "100%", marginTop: "0.5rem" }}
                severity="error"
              >
                {error}
              </Alert>
            )}
            <span style={{ color: "blue" }}>{currentUser?.email}</span>
            <Button
              type="submit"
              disabled={loading}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
          </form>
          <Grid container style={{ margin: "8px 0" }}>
            <Grid item xs>
              <Typography
                className="sim-text-link"
                color="primary"
                onClick={toggleForgotPasswordView}
                variant="body2"
              >
                Forgot password?
              </Typography>
            </Grid>
            <Grid item>
              <Link to="/phone-login" variant="body2" className="sim-text-link">
                Sign in with phone
              </Link>
            </Grid>
          </Grid>
        </Card>
        <Copyright />
      </Box>
    </Container>
  );
}
