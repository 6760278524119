import { Chip, IconButton } from "@material-ui/core";
import { Search, ThumbUp, Warning } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BackLink from "../../shared/BackLink";
import SimDataGrid from "../../shared/data-grid/SimDataGrid";
import sqlToStandardDate from "../../shared/functions/sqlToStandardDate";
import { useAuth } from "../../../contexts/AuthContext";
import { supabase } from "../../../supabase";

/**
 * Component that displays a list of all the dealer requests that have been
 * submitted by the current user.
 *
 * This component fetches the list of dealers from the database using the
 * Supabase JavaScript client. It then renders a table with the following
 * columns:
 *
 * - Status: The status of the request, displayed as a chip.
 * - Resubmit: A button that allows the user to resubmit the request.
 * - Name: The name of the dealer.
 * - Surname: The surname of the dealer.
 * - Phone: The phone number of the dealer.
 *
 * The component also renders a link to the approval detail page for each
 * dealer request.
 *
 * @returns {React.Component} The DealerRequestApprovals component.
 */
export default function DealerRequestApprovals() {
  const [dealers, setDealers] = useState([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchDealers = async () => {
      const { data, error } = await supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("dealers")
        .select("*")
        .filter("submitted_by->>uid", "eq", currentUser.id)
        .filter("status", "eq", 1)
        .filter("email_verified", "eq", true)
        .filter("phone_verified", "eq", true);

      if (error) {
        console.error("Error fetching dealers:", error);
      } else {
        setDealers(data);
      }
    };

    fetchDealers();
  }, [currentUser.id]);

  const rows =
    dealers &&
    dealers.map(r => ({
      id: dealers.id,
      ...r,

      date: sqlToStandardDate(r.created_at),
    }));

  const columns = [
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: params => (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ cursor: "pointer" }}
        >
          {params.row.status === 1 && <Chip label="Pending" />}
          {params.row.status === 2 && (
            <Chip color="primary" label="Approved" icon={<ThumbUp />} />
          )}
          {params.row.status === 5 && (
            <Chip color="secondary" label="Declined" icon={<Warning />} />
          )}
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Resubmit",
      width: 100,
      renderCell: params => (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ cursor: "pointer" }}
        >
          {params.row.status === 1 && <Chip label="Pending" />}
          {params.row.status === 5 && (
            <Link to={`/approval-detail/${params.row.id}`}>
              <IconButton>
                <Search />
              </IconButton>
            </Link>
          )}
          {params.row.status === 2 && <Chip label="Approved" color="primary" />}
        </div>
      ),
    },
    { field: "contact_name", headerName: "Name", width: 120 },
    { field: "contact_surname", headerName: "Surname", width: 120 },
    { field: "phone", headerName: "Phone", width: 120 },
  ];

  return (
    <div>
      <h1>Your Pending Requests</h1>
      <BackLink />
      <SimDataGrid data={rows} columns={columns} />
    </div>
  );
}
