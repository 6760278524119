import React, { useEffect, useState } from "react";

import { DataGrid } from "@material-ui/data-grid";
import {
  Grid,
  IconButton,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList, faPlus } from "@fortawesome/free-solid-svg-icons";

import { supabase } from "../../../../../supabase";

import { useAuth } from "../../../../../contexts/AuthContext";

import ModalCard from "../../../../shared/ModalCard";
import AgentAssistViewTicket from "../../../../admin-section/admin-agent-assist/AgentAssistViewTicket";
import AddAgentAssistTicketModal from "./AddAgentAssistTicketModal";

import sqlToStandardDate from "../../../../shared/functions/sqlToStandardDate";
import formatTicketNumber from "../../../../shared/functions/formatTicketNumber";

/**
 * Checks if there are any unread messages in the activity.
 *
 * @param {Array<{read_user: string|null, read_admin: string|null}>} activity - The array of activity objects, where each object has read status fields.
 * @param {boolean} isAdmin - A flag indicating if the check is for admin (true) or user (false).
 * @returns {boolean} - Returns true if there is at least one unread message, otherwise false.
 */
function hasUnread(activity, isAdmin) {
  const field = isAdmin ? "read_admin" : "read_user";

  return activity?.some(item => item[field] === null);
}

/**
 * Component to display a list of leads for a supplier, with the ability to filter
 * by status and view a lead's details.
 *
 * @param {object} props - The component props.
 * @param {object} props.Supplier - The supplier object.
 * @returns {React.ReactElement} - The AgentAssist component.
 */
export default function AgentAssist({ Supplier }) {
  // Get the current authenticated user
  const { currentUser } = useAuth();

  // State to manage loading status
  const [loading, setLoading] = useState(false);

  // State to store the list of leads
  const [tickets, setTickets] = useState([]);

  // State to manage filter options for displaying leads
  const [displayFilters, setDisplayFilters] = useState({
    open: true,
    inProgress: true,
    closed: false,
  });

  // State to manage the modal for viewing a lead
  const [viewTicket, setViewTicket] = useState({
    isOpen: false,
    id: null,
  });

  // Function to handle viewing a lead
  const handleViewTicket = leadId => {
    setViewTicket({ id: leadId, isOpen: true });
  };

  // Function to close the view lead modal
  const closeViewTicket = () => {
    setViewTicket({ id: null, isOpen: false });
    getData(true);
  };

  const [addTicketModal, setAddTicketModal] = useState(false);

  const toggleAddTicketModal = () => {
    setAddTicketModal(prev => !prev);
    getData();
  };

  // Function to fetch leads data from the database
  const getData = async (showLoading = true) => {
    if (showLoading) setLoading(true); // Set loading to true before fetching data

    const { data: assistTickets, error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("agent_assist")
      .select("*, agent_assist_activity(read_user, read_admin)")
      .eq("auth_id", currentUser.auth_id) // Filter by the current user's dealer code
      .eq("supplier", Supplier) // Filter by the supplier
      .eq("is_active", true)
      .order("created_at", { ascending: false }); // Order by date submitted, newest first

    if (error) {
      console.error(error); // Log any errors
    } else {
      const formattedTickets = assistTickets.map(ticket => ({
        ...ticket,
        id: ticket.id,
        ticket_number: formatTicketNumber(ticket.id, "ZAA"),
        unread: hasUnread(ticket.sales_assist_activity, currentUser.rank === 1),
      }));
      setTickets(formattedTickets); // Update leads state with the fetched data
    }
    if (showLoading) setLoading(false); // Set loading to false after fetching data
  };

  // Function to handle changes to the filter options
  const handleClick = event => {
    const { name } = event.target;
    setDisplayFilters(old => ({
      ...old,
      [name]: !old[name],
    }));
  };

  // Filter leads based on the selected filter options
  const openFilter = displayFilters.open ? "Open" : undefined;
  const inProgressFilter = displayFilters.inProgress
    ? "In Progress"
    : undefined;
  const closedFilter = displayFilters.closed ? "Closed" : undefined;

  const displayTickets = tickets?.filter(
    lead =>
      lead.status === openFilter ||
      lead.status === inProgressFilter ||
      lead.status === closedFilter ||
      lead.status === null
  );

  // Columns configuration for the DataGrid
  const columns = [
    {
      field: "View/Upload",
      width: 120,
      renderCell: params => (
        <Grid spacing={1} container direction="row">
          <Grid item xs={6}>
            <Button
              variant="text"
              color={params.row.unread ? "secondary" : "primary"}
              size="small"
              onClick={() => handleViewTicket(params.id)}
            >
              <FontAwesomeIcon icon={faClipboardList} />
            </Button>
          </Grid>
        </Grid>
      ),
    },
    { field: "ticket_number", headerName: "Ticket Number", width: 110 },
    { field: "created_at", headerName: "Created At", width: 110 },
    { field: "category", headerName: "Category", width: 170 },
    { field: "status", headerName: "Status", width: 180 },
    { field: "status_feedback", headerName: "Status Feedback", width: 250 },
    {
      field: "supplier_ticket_number",
      headerName: "Supplier Ticket",
      width: 250,
    },
    { field: "user_note", headerName: "User Note", width: 250 },
  ];

  // Transform the leads data into rows for the DataGrid
  const rows = displayTickets?.map(row => {
    const statusFeedback =
      row.status === "In Progress"
        ? row.ticket_in_progress_status
        : row.ticket_closed_status;

    const date = sqlToStandardDate(row.created_at); // Convert SQL date to standard date format

    return {
      id: row.id,
      ticket_number: row.ticket_number,
      created_at: date,
      category: row.category,
      status: row.status,
      status_feedback: statusFeedback,
      supporting_document_ref: row.supporting_document_ref,
      user_note: row.user_note,
      unread: row.unread,
      supplier_ticket_number: row.supplier_ticket_number,
    };
  });

  // Fetch leads data when the component mounts or when the Supplier prop changes
  useEffect(() => {
    getData();
  }, [Supplier]);

  useEffect(() => {
    const subscription = supabase
      .channel("agent_assist_activity_channel")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: process.env.REACT_APP_SB_SCHEMA,
          table: "agent_assist_activity",
        },
        () => getData(false)
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [viewTicket.isOpen, addTicketModal]);

  return (
    <Grid spacing={3} container direction="column">
      <Grid item container row>
        <Grid item md={6}>
          {/* Checkbox filters for displaying leads based on their status */}
          <FormGroup row>
            <FormControlLabel
              label="Open"
              control={
                <Checkbox
                  color="primary"
                  checked={openFilter}
                  onClick={handleClick}
                  name="open"
                />
              }
            />
            <FormControlLabel
              label="In Progress"
              control={
                <Checkbox
                  color="primary"
                  checked={inProgressFilter}
                  onClick={handleClick}
                  name="inProgress"
                />
              }
            />
            <FormControlLabel
              label="Closed"
              control={
                <Checkbox
                  color="primary"
                  checked={closedFilter}
                  onClick={handleClick}
                  name="closed"
                />
              }
            />
          </FormGroup>
        </Grid>
        <Grid item container justifyContent="flex-end" md={6}>
          <IconButton
            color="primary"
            style={{ marginTop: -10 }}
            onClick={toggleAddTicketModal}
          >
            <FontAwesomeIcon icon={faPlus} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item style={{ height: 500 }}>
        {/* DataGrid to display the leads */}
        <DataGrid rows={rows} columns={columns} loading={loading} />
      </Grid>
      {/* Modal for viewing a lead */}
      <ModalCard
        open={viewTicket.isOpen}
        onClose={closeViewTicket}
        closeButtonText="Close"
      >
        <AgentAssistViewTicket ticketId={viewTicket.id} />
      </ModalCard>

      <ModalCard open={addTicketModal} onClose={toggleAddTicketModal}>
        <AddAgentAssistTicketModal
          supplier={Supplier}
          onClose={toggleAddTicketModal}
          open={addTicketModal}
        />
      </ModalCard>
    </Grid>
  );
}
