/* eslint-disable react/destructuring-assignment */
import * as React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";

/**
 * Escapes special characters in a string to be used in a regular expression.
 *
 * This function takes a string and replaces characters that have special meaning
 * in regular expressions with their escaped counterparts. This ensures that the
 * string can be safely used within a regular expression without causing syntax errors.
 *
 * @param {string} string - The input string containing characters to be escaped.
 * @returns {string} - The escaped string, safe for use in regular expressions.
 */
function escapeRegExp(string) {
  return string.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: "space-between",
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  textField: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    margin: theme.spacing(1, 0.5, 1.5),
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(0.5),
    },
    "& .MuiInput-underline:before": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
}));

/**
 * QuickSearchToolbar component renders a search toolbar with a text field and a grid toolbar.
 *
 * @param {object} props - The properties passed to the component.
 * @param {string} props.value - The current value of the search input.
 * @param {Function} props.onChange - The function to call when the search input value changes.
 * @param {Function} props.clearSearch - The function to call when the clear button is clicked.
 *
 * @returns {React.Element} The rendered QuickSearchToolbar component.
 */
function QuickSearchToolbar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
      <div>
        <GridToolbar />
      </div>
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

/**
 * SimDataGrid component renders a data grid with search functionality.
 *
 * @param {object} props - The component props.
 * @param {Array} props.data - The data to be displayed in the grid.
 * @param {Array} props.columns - The column definitions for the grid.
 * @param {boolean} props.hideToolbar - Flag to hide or show the toolbar.
 *
 * @returns {React.Element} The rendered data grid component.
 */
export default function SimDataGrid({ data, columns, hideToolbar }) {
  const [searchText, setSearchText] = React.useState("");
  const [rows, setRows] = React.useState(data);

  const requestSearch = searchValue => {
    if (data[0]) setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = data.filter(row =>
      Object.keys(row).some(field => {
        if (field === "parent_dealer" || field === "submitted_by") {
          return undefined;
        }
        return searchRegex.test(row[field] && row[field].toString());
      })
    );
    setRows(filteredRows);
  };

  React.useEffect(() => {
    setRows(data);
  }, [data]);

  return (
    <div style={{ height: 750, width: "100%" }}>
      <DataGrid
        components={!hideToolbar ? { Toolbar: QuickSearchToolbar } : ""}
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        componentsProps={{
          toolbar: {
            value: searchText,
            onChange: event => requestSearch(event.target.value),
            clearSearch: () => requestSearch(""),
          },
        }}
      />
    </div>
  );
}
