import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { ArrowBack, Event, Mail } from "@material-ui/icons";
// import moment from "moment";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { textFieldProps } from "../../../shared/constants";
// import { useAuth } from "../../../../contexts/AuthContext";

/**
 * A component for viewing and managing leads.
 *
 * This component displays a list of leads, their contact information, and status.
 * It also allows users to search for leads and view their details.
 *
 * @returns A JSX element representing the LeadManagement component.
 */
export default function LeadManagement() {
  const history = useHistory();
  // const { currentUser } = useAuth();
  // const [startDate, setStartDate] = useState(moment().subtract(1, "month"));
  // const [endDate, setEndDate] = useState(moment());
  const [leadData, setLeadData] = useState([]);

  useEffect(async () => {
    // Get leads from database
    const response = axios({
      url: "https://us-central1-zawadi-dev.cloudfunctions.net/getLeadManagementData",
      method: "post",
    });
    // eslint-disable-next-line no-console
    console.log(await response);
    const data = await (await response).data;
    setLeadData(data);
  }, []);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.goBack()}
        startIcon={<ArrowBack />}
      >
        Back
      </Button>
      <h3>Lead Management:</h3>
      <Card>
        <CardContent>
          <TextField {...textFieldProps} placeholder="Search" />
          <div>
            {leadData.map(lead => {
              return (
                <Card
                  style={{
                    backgroundColor: "#267353",
                    color: "#ffffff",
                    padding: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Grid
                    container
                    spacing={0}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={10} sm={10}>
                      <Typography display="block">
                        Name: {lead.leadName}
                      </Typography>
                      <Typography display="block">
                        Phone: {lead.leadPhone}
                      </Typography>
                      <Typography display="block">
                        Address: {lead.leadAddress}
                      </Typography>
                      <Typography display="block">
                        Status: {lead.leadStatus}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                      <IconButton>
                        <Mail style={{ color: "#ffffff" }} />
                      </IconButton>
                      <br />
                      <IconButton>
                        <Event style={{ color: "#ffffff" }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Card>
              );
            })}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
