// #useAuthUpdated

import { Grid } from "@material-ui/core";
import React from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import { useCompany } from "../../../../contexts/CompanyContext";

import SupplierTile from "./SupplierTile";

/**
 * @description
 * A component that renders a list of SupplierTiles.
 * Will only render if the user is an admin or if the user is registered for the supplier.
 * Will not render if the supplier is cancelled.
 *
 * @param {object} props - The props object.
 * @param {string} props.url - The url to use for the SupplierTile.
 *
 * @example
 * <SupplierList url="/suppliers" />
 */
export default function SupplierList({ url }) {
  const { currentUser } = useAuth();
  const { activeSuppliers } = useCompany();

  const { selected_suppliers: selectedSuppliers, rank } = currentUser;

  const supplierMap = activeSuppliers.map(supplier => {
    // If user is admin or if user is registered for the supplier
    const isActive = rank === 1 || selectedSuppliers?.indexOf(supplier.id) > -1;

    /* Display when: 
    - status is not cancelled. (Don't display cancelled suppliers)
    - isActive is true (Display all other statusses if you are admin or registered)
    - supplier is active (Display all active suppliers - even if not active)
     */

    const display =
      supplier.supplierStatus !== "Cancelled" &&
      (isActive || supplier.supplierStatus === "Active");

    // return if display is true
    return display ? (
      <SupplierTile
        url={url}
        supplierObject={supplier}
        isActive={isActive}
        key={supplier.id}
      />
    ) : null;
  });

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Grid container xs={12} spacing={2} justifyContent="center">
        {supplierMap}
      </Grid>
    </div>
  );
}
