// #useAuthUpdated

import { Button, Snackbar } from "@material-ui/core";
import { PersonAdd } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
// import Select from "react-select";
// import { selectFieldProps } from "../shared/constants";
import CreateDealerForm from "../manage-dealers/CreateDealerForm";
import DealerLevelTabs from "../tabs/DealerLevelTabs";
import { pageTitleStyles } from "../../mui/styles";
import DealersDownstream from "./subdealers/DealersDownstream";
import { useSB } from "../../contexts/SupabaseContext";
// import DownstreamTable from "../tables/DownstreamTable";

/**
 * SubDealerManage component renders the content for the Manage Teams page for subdealers.
 * It displays a list of dealer levels below the current user's rank, and allows users to create new dealers or view/search all dealers.
 * It also displays a button to view all approvals.
 *
 * @component
 * @example
 * <SubDealerManage />
 *
 * @function
 * @name SubDealerManage
 *
 * @description
 * This component uses several hooks:
 * - `useHistory` from `react-router-dom` to navigate back to the settings page.
 * - `useState` to manage component state for dialog open/close, level name, level, loading, and error.
 * - `useCompany` custom hook to get dealer levels and upsertDealerLevel function.
 *
 * @typedef {object} DealerLevel
 * @property {string} id - The unique identifier of the dealer level.
 * @property {string} level_name - The name of the dealer level.
 *
 * @typedef {object} UpsertDealerLevelParams
 * @property {number} level - The level number.
 *
 * @async
 * @function handleSubmit
 * @description Handles the submission of the new dealer level form.
 * It sets the loading state, calls the upsertDealerLevel function, and handles any errors.
 *
 * @returns {Promise<void>}
 */
export default function SubDealerManage() {
  const [snackbarIsOpen, setSnackbarIsOpen] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [listLevel, setListLevel] = useState();
  const { GetTableWhere } = useSB();
  const { currentUser } = useAuth();
  const [levels, setLevels] = useState([]);

  useEffect(() => {
    // Get Levels Below
    GetTableWhere("dealer_levels", ["level", "gt", currentUser.rank]).then(
      data => {
        setLevels(
          data.map(item => ({
            level_name: item.level_name,
            level: item,
          }))
        );
      }
    );
  }, []);

  useEffect(() => {
    if (levels.length > 0) {
      setListLevel(levels[0].level);
    }
  }, [levels]);

  const openSnackbar = () => {
    setSnackbarIsOpen(true);
  };

  const closeDialog = () => {
    setDialogIsOpen(false);
  };

  const openDialog = () => {
    setDialogIsOpen(true);
  };

  if (currentUser.rank === 8) {
    return <div>You don't have access to this page</div>;
  }

  return (
    <div>
      <h1 style={pageTitleStyles}>Manage Teams</h1>
      <Button
        onClick={openDialog}
        variant="contained"
        color="primary"
        startIcon={<PersonAdd />}
      >
        Create
      </Button>
      &nbsp;
      <Link to="/approvals">
        <Button variant="outlined" color="primary" startIcon={<PersonAdd />}>
          View Pending Requests
        </Button>
      </Link>
      <br />
      <br />
      <DealerLevelTabs levels={levels} setListLevel={setListLevel} />
      {listLevel && <DealersDownstream rank={listLevel} />}
      <CreateDealerForm
        dialogIsOpen={dialogIsOpen}
        closeDialog={closeDialog}
        openSnackbar={openSnackbar}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarIsOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarIsOpen(false)}
        message="Person Added!"
      />
    </div>
  );
}
