// #useAuthUpdated

import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

/**
 * A higher-order component that wraps a given component and ensures that the user is authenticated
 * before rendering it. If the user is not authenticated, it redirects to the login page.
 *
 * @param {object} props - The props object.
 * @param {React.ComponentType} props.component - The component to render if the user is authenticated.
 * @returns {React.Element} A Route component that conditionally renders the given component or redirects to the login page.
 */
export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth();

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={
        props => {
          return currentUser ? (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          );
        }
        // eslint-disable-next-line react/jsx-curly-newline
      }
    />
  );
}
