import {
  Avatar,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  styled,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Public } from "@material-ui/icons";
import { linearProgressClasses } from "@mui/material";
import React from "react";

const BigAvatar = withStyles({
  root: {
    width: 50,
    height: 50,
    backgroundColor: "#FFFFFF",
  },
})(Avatar);

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#D3D3D3",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#3D7155",
  },
}));

/**
 * A card component for displaying a user's leaderboard information,
 * with a large rank icon, name, fiber sales, and a progress bar.
 *
 * @param {object} props - The properties for the component.
 * @param {number} props.rank The user's rank.
 * @param {string} props.name The user's name.
 * @param {number} props.percentage The user's progress towards their target.
 * @param {string} props.fiber The user's fiber sales.
 * @param {string} [props.userImg] The user's profile image.
 * @param {"none"|"dotted"|"dashed"|"solid"|"double"|"groove"|"ridge"|"inset"|"outset"} [props.outlined] The style of the outline around the card.
 * @param {string} [props.outlineColors] The color of the outline around the card.
 * @returns {React.ReactElement} A JSX element representing the card.
 */
export default function MobileUserCard({
  rank,
  name,
  percentage,
  fiber,
  userImg,
  outlined,
  outlineColors,
}) {
  return (
    <Card
      style={{
        outlineStyle: outlined,
        outlineColor: outlineColors,
        outlineWidth: "4px",
      }}
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid item style={{ width: 60, marginLeft: -10 }}>
            {userImg ? (
              <BigAvatar src={userImg} />
            ) : rank < 100 ? (
              <Typography variant="h3" color="textPrimary" align="center">
                {rank}
              </Typography>
            ) : (
              <Typography
                variant="h4"
                color="textPrimary"
                align="center"
                style={{ marginTop: 10 }}
              >
                {rank}
              </Typography>
            )}
          </Grid>
          <Grid item style={{ flex: 1 }}>
            <Typography variant="body1" style={{ fontWeight: "550" }}>
              {name}
            </Typography>
            <BorderLinearProgress variant="determinate" value={percentage} />
            <Grid item style={{ marginBottom: "-16px" }}>
              <Grid container direction="row">
                <Typography align="right" variant="subtitle1">
                  <Public fontSize="inherit" color="primary" />
                </Typography>

                <Typography variant="subtitle2" align="left">
                  :&nbsp;{fiber}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
