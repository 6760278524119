// reusable ?

import React from "react";

import { Grid, Typography, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import sqlToStandardDate from "../functions/sqlToStandardDate";

const useStyles = makeStyles(theme => ({
  card: {
    paddingTop: "7px",
    paddingRight: "10px",
    paddingBottom: "7px",
    paddingLeft: "10px",
    maxWidth: "95%",
    minWidth: "33%",
  },
  updateCard: {
    paddingTop: "7px",
    paddingRight: "10px",
    paddingBottom: "7px",
    paddingLeft: "10px",
    marginTop: "10px",
    marginBottom: "10px",
    width: "100%",
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  internalCard: {
    paddingTop: "7px",
    paddingRight: "10px",
    paddingBottom: "7px",
    paddingLeft: "10px",
    maxWidth: "95%",
    minWidth: "33%",
    background: theme.palette.grey[200],
  },
  dateColor: {
    color: theme.palette.grey[600],
  },
}));

/**
 * Component to render different types of activity bubbles for assist tickets.
 *
 * @param {object} props - The component props.
 * @param {object} props.item - The activity item object.
 * @param {object} props.currentUser - The current user object.
 * @param {string} [props.ticketUser="Agent"] - The user associated with the ticket.
 * @returns {React.Element|null} The rendered activity bubble or null if the activity type is not recognized.
 */
function AssistActivityBubble({ item, currentUser, ticketUser = "Agent" }) {
  const classes = useStyles();

  const itemText = (
    <Typography variant="body2">
      {item.text.split("\n").map((line, index) => (
        // eslint-disable-next-line
        <span key={index}>
          {line}
          <br />
        </span>
      ))}
    </Typography>
  );

  switch (item.activity_type) {
    case "admin": {
      return (
        <Grid
          container
          item
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-end"
          style={{
            marginBottom: "0",
            minWidth: "100%",
          }}
        >
          <Card className={classes.card}>
            <Grid item container direction="column">
              <Grid item>
                <Typography variant="subtitle2">Admin</Typography>
              </Grid>
              <Grid item>{itemText}</Grid>
              <Grid item>
                <Typography variant="caption" className={classes.dateColor}>
                  {sqlToStandardDate(item.created_at, true)}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      );
    }
    case "user": {
      return (
        <Grid
          container
          item
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          style={{
            marginBottom: "0",
            minWidth: "100%",
          }}
        >
          <Card className={classes.card}>
            <Grid item container direction="column">
              <Grid item>
                <Typography variant="subtitle2" className={classes.agentTitle}>
                  {ticketUser}
                </Typography>
              </Grid>
              <Grid item>{itemText}</Grid>
              <Grid item>
                <Typography variant="caption" className={classes.dateColor}>
                  {sqlToStandardDate(item.created_at, true)}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      );
    }
    case "internal": {
      if (currentUser.rank === 1) {
        return (
          <Grid
            container
            item
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-end"
            style={{
              marginBottom: "0",
              minWidth: "100%",
            }}
          >
            <Card className={classes.internalCard}>
              <Grid item container direction="column">
                <Grid item>
                  <Typography variant="subtitle2">Internal</Typography>
                </Grid>
                <Grid item>{itemText}</Grid>
                <Grid item>
                  <Typography variant="caption" className={classes.dateColor}>
                    {sqlToStandardDate(item.created_at, true)}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        );
      }
      return null;
    }
    case "update": {
      return (
        <Grid
          container
          item
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          style={{
            marginBottom: "0",
            minWidth: "100%",
          }}
        >
          <Card className={classes.updateCard}>
            <Grid item container direction="column">
              <Grid item>
                <Typography variant="subtitle1">Ticket Update</Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">
                  {sqlToStandardDate(item.created_at, true)}
                </Typography>
              </Grid>
              <Grid item>{itemText}</Grid>
            </Grid>
          </Card>
        </Grid>
      );
    }
    default: {
      return null;
    }
  }
}
export default AssistActivityBubble;
