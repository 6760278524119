// #useAuthUpdated

import {
  Button,
  Dialog,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { Save } from "@material-ui/icons";
import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { selectFieldProps } from "../../shared/constants";
import { useSB } from "../../../contexts/SupabaseContext";
import { useAuth } from "../../../contexts/AuthContext";
import { supabase } from "../../../supabase";

/**
 * Dialog component to change the parent dealer of a dealer.
 * @param {object} props - The props object.
 * @param {boolean} props.open - Whether the dialog is open or not.
 * @param {Function} props.close - Function to call when the dialog should be closed.
 * @param {object} props.dealer - The dealer object.
 */
export default function ChangeSubDealerParent({ open, close, dealer }) {
  const { updateRow, GetTableWhere } = useSB();
  const { currentUser } = useAuth();
  const [children, setChildren] = useState([]);
  const [dealersAbove, setDealersAbove] = useState([]);
  const [newParent, setNewParent] = useState();
  const [checked, setChecked] = useState(true);
  const [state, setState] = useState({
    loading: false,
    error: null,
  });

  const handleCheck = event => {
    setChecked(event.target.checked);
  };

  useEffect(async () => {
    const result = await GetTableWhere("dealers", [
      "parent_dealer_id",
      "eq",
      dealer.id,
    ]);
    setChildren(result.map(child => child.id));
  }, [dealer, open, close]);

  // call useEffect on any change in used dealer props
  useEffect(async () => {
    // validation
    if (
      (dealer.rank, dealer.selected_product_types, dealer.selected_suppliers)
    ) {
      // get all active dealers with a higher level than current dealer
      const allDealersAbove = await GetTableWhere(
        "dealers",
        ["rank", "lt", dealer.rank],
        ["status", "eq", 2]
      );

      // Filter the options to only contain dealers that has all the selected_product_types and selected_suppliers than selected dealer
      const filteredDealersAbove = await allDealersAbove?.filter(
        dealerAbove => {
          const hasSelectedSuppliers = dealer.selected_suppliers.every(
            supplier => dealerAbove.selected_suppliers.includes(supplier)
          );
          const hasSelectedProductType = dealer.selected_product_types.every(
            productType =>
              dealerAbove.selected_product_types.includes(productType)
          );

          return hasSelectedProductType && hasSelectedSuppliers;
        }
      );

      const dealerAbove =
        currentUser.rank === 1 ? allDealersAbove : filteredDealersAbove;

      if (dealerAbove.length > 0) {
        // map all filtered dealers
        const filteredDealersMap = dealerAbove.map(user => ({
          label: `${user.dealer_code}: ${user.contact_name} ${user.contact_surname}`,
          value: user.dealer_code,
          id: user.id,
        }));

        // set options to select
        setDealersAbove(filteredDealersMap);
      }
    }
  }, [dealer.rank, dealer.selected_product_types, dealer.selected_suppliers]);

  // Handle close dialog
  const handleClose = () => {
    setState({
      loading: false,
      error: null,
    });
    setNewParent(null);
    close();
    window.location.reload();
  };

  // handle new parent selection
  const handleSelect = v => {
    setNewParent(v);
  };
  // handle confirmation
  const handleConfirm = async () => {
    setState({
      loading: true,
      error: null,
    });
    if (!checked) {
      const { error } = await supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("dealers")
        .update({
          parent_dealer_id: dealer.parent_dealer_id,
          parent_dealer: dealer.parent_dealer,
          last_updated_by: currentUser.auth_id,
        })
        .in("id", [children]);
      if (error) {
        console.error("Error updating rows:", error);
      }
    }
    try {
      updateRow("dealers", dealer.id, {
        parent_dealer: newParent.value,
        parent_dealer_id: newParent.id,
        last_updated_by: currentUser.auth_id,
      }).then(result => {
        if (result.error) {
          console.error(result.error.message);
        } else {
          handleClose();
        }
      });
    } catch (err) {
      setState({
        loading: false,
        error: err.message,
      });
    }
  };

  // return dialog
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Change Manager</DialogTitle>
      <DialogContent>
        <Select
          {...selectFieldProps}
          options={dealersAbove}
          onChange={handleSelect}
        />
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={handleCheck} />}
          style={{ marginTop: "0.5rem" }}
          label="Move with children"
        />
      </DialogContent>
      <DialogActions>
        {state.error && (
          <Typography color="secondary">{state.error}</Typography>
        )}
        <Button
          color="secondary"
          onClick={handleClose}
          disabled={state.loading}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<Save />}
          disabled={state.loading || !newParent}
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
