/* eslint-disable react/forbid-prop-types */
import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import BackLink from "../../shared/BackLink";
import Approvals from "./Approvals";

/**
 * TabPanel component renders the content of a tab.
 *
 * @param {object} props - The properties object.
 * @param {React.ReactNode} props.children - The content to be displayed within the tab panel.
 * @param {number} props.value - The current value of the tab.
 * @param {number} props.index - The index of the tab panel.
 * @param {object} [props.other] - Additional properties to be passed to the div element.
 * @returns {React.Element} The rendered tab panel component.
 */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

/**
 * Generates accessibility properties for a tab element.
 *
 * @param {number} index - The index of the tab.
 * @returns {object} An object containing the id and aria-controls properties.
 */
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/**
 * DealerApprovals component renders a tabbed interface for managing dealer approvals.
 *
 * @component
 * @example
 * return (
 *   <DealerApprovals />
 * )
 *
 * @returns {React.Element} The DealerApprovals component.
 */
export default function DealerApprovals() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <h1>Approvals</h1>
      <BackLink />
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        <Tab label="Pending" {...a11yProps(0)} />
        <Tab label="Declined" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Approvals type={1} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Approvals type={5} />
      </TabPanel>
    </div>
  );
}
