import React, { useState, useEffect } from "react";
import { Button, IconButton } from "@material-ui/core";
import { ArrowBack, People, Search } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import SimDataGrid from "../../shared/data-grid/SimDataGrid";
import { useSB } from "../../../contexts/SupabaseContext";

/**
 * AllDealers component fetches and displays a list of dealers in a table format.
 *
 * @component
 * @returns {React.Element} The AllDealers component.
 *
 * @example
 * return <AllDealers />
 *
 * @function
 * @name AllDealers
 *
 * @description
 * This component uses the `useHistory` hook to navigate back to the previous page,
 * and the `useSB` hook to fetch data from the "dealers" table. It maps the fetched
 * data to create rows for the table, and defines columns for the table display.
 *
 * @returns {React.Element} A React component that renders a table of dealers with
 * columns for dealer code, ID/Passport, name, phone, email, and action buttons
 * for detail and downstream views.
 */
export default function AllDealers() {
  const history = useHistory();
  const { GetTableWhere } = useSB();
  const [dealers, setDealers] = useState([]);

  useEffect(() => {
    /**
     * Fetches data from the "dealers" table and updates the state with the retrieved data.
     * If an error occurs during the fetch, it logs the error to the console.
     *
     * @async
     * @function fetchData
     * @returns {Promise<void>} A promise that resolves when the data has been fetched and state updated.
     */
    async function fetchData() {
      try {
        const data = await GetTableWhere("dealers");
        setDealers(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  const rows = dealers?.length
    ? dealers.map(d => ({
        ...d,
        id: d.id,
        name: `${d.contact_name} ${d.contact_surname}`,
        phone: `0${d.phone.slice(3)}`,
      }))
    : [];

  const columns = [
    { field: "dealer_code", headerName: "Code", width: 120 },
    { field: "contact_id_no", headerName: "ID/Passport", width: 120 },
    { field: "name", headerName: "Name", width: 220 },
    { field: "phone", headerName: "Phone", width: 120 },
    { field: "email", headerName: "Email", width: 200 },
    {
      field: "action",
      headerName: "Detail / Downstream",
      width: 200,
      renderCell: params => (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ cursor: "pointer" }}
        >
          <Link to={`/dealer-detail/${params.row.id}`}>
            <IconButton>
              <Search />
            </IconButton>
          </Link>
          <Link to={`child-dealer-downstream/${params.row.id}`}>
            <IconButton>
              <People />
            </IconButton>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="back-link">
        <Button
          color="primary"
          startIcon={<ArrowBack />}
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </div>
      <SimDataGrid data={rows} columns={columns} />
    </div>
  );
}
