/* eslint-disable jsdoc/check-param-names */
import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import * as yup from "yup";
import checkEmailIsUsed from "../shared/functions/checkEmailIsUsed";

const emailSchema = yup
  .string()
  .email("Must be a valid email address")
  .required("Email address is required");

/**
 * Renders a form to change the user's email address. The form will validate the
 * email address and check if it is already in use. If the email is valid and not
 * in use, it will call the setNewEmail prop with the new email address.
 *
 * @param {string} currentEmail - The user's current email address.
 * @param {Function} setNewEmail - A function to call with the new email address.
 * @returns {React.Element} A form to change the user's email address.
 */
export default function ChangeEmail({ currentEmail, setNewEmail }) {
  const [email, setEmail] = useState(currentEmail);
  const [error, setError] = useState(null);

  const handleBlur = async () => {
    setError(null);
    try {
      if (email !== currentEmail) {
        await emailSchema.validate(email);

        const emailIsUsed = await checkEmailIsUsed(email);
        if (emailIsUsed) {
          setError("Email Address is already in use.");
        } else if (email !== currentEmail) {
          setNewEmail(email);
        } else {
          // Don't change the phone.
        }
      }
    } catch (validationError) {
      setError(validationError.message);
    }
  };

  return (
    <div style={{ maxWidth: 400, margin: "20px 20px 20px 0px" }}>
      <TextField
        fullWidth
        id="email"
        name="email"
        label="Email Address"
        variant="outlined"
        value={email}
        onBlur={handleBlur}
        onChange={e => {
          setEmail(e.target.value);
        }}
        error={error || null}
        margin="normal"
        helperText={
          error ||
          "You will need to verify your email address if you make any changes"
        }
      />
    </div>
  );
}
