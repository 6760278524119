// reusable ?

import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";

/**
 * AssistDocuments component renders a section for displaying and uploading supporting documents.
 *
 * @param {object} props - The component props.
 * @param {object} props.lead - The lead object containing information about the supporting documents.
 * @param {React.ReactNode} props.viewFiles - The component or elements to display the uploaded files.
 * @param {Function} props.toggleUploadFilesModal - The function to toggle the upload files modal.
 *
 * @returns {React.Element} The rendered AssistDocuments component.
 */
function AssistDocuments({ lead, viewFiles, toggleUploadFilesModal }) {
  return (
    <Grid container item direction="column" xs={12} spacing={2}>
      <Grid container item>
        <Typography variant="subtitle1" gutterBottom>
          Supporting Documents:
        </Typography>
        <Grid container item>
          {lead.supporting_doc_ref ? (
            <Grid item container direction="rows">
              {viewFiles}
            </Grid>
          ) : (
            <Grid>
              <Typography variant="caption">No Files Uploaded</Typography>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={toggleUploadFilesModal}
          >
            Add Supporting Documents
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AssistDocuments;
