import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { DialogTitle, TextField } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSB } from "../../../contexts/SupabaseContext";
import { useAuth } from "../../../contexts/AuthContext";

import { textFieldProps } from "../../shared/constants";

/**
 * DeclineRequest component handles the decline request dialog.
 *
 * @param {object} props - The component props.
 * @param {boolean} props.open - Determines if the dialog is open.
 * @param {Function} props.close - Function to close the dialog.
 * @param {string} props.reqId - The request ID to be declined.
 *
 * @returns {React.Element} The DeclineRequest component.
 */
export default function DeclineRequest({ open, close, reqId }) {
  const history = useHistory();
  const { updateRow } = useSB();
  const { currentUser } = useAuth();

  const [state, setState] = useState({
    loading: false,
    error: null,
    data: {},
  });

  const handleClose = () => {
    setState({
      loading: false,
      error: null,
    });
    close();
  };

  const handleChange = e => {
    setState({
      ...state,
      data: {
        ...state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleConfirm = async () => {
    setState({
      ...state,
      loading: true,
      error: null,
    });

    try {
      await updateRow("dealers", reqId, {
        status: 5,
        decline_reason: state.data.declineReason,
        last_updated_by: currentUser.auth_id,
      });
      handleClose();
      history.push("/approvals");
    } catch (err) {
      setState({
        loading: false,
        error: err.message,
      });
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
      <DialogTitle>Decline Request</DialogTitle>
      <DialogContent>
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...textFieldProps}
          label="Please Provide Reason for Decline"
          multiline
          rows={3}
          name="declineReason"
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        {state.error && (
          <Typography color="secondary">{state.error}</Typography>
        )}
        <Button
          color="secondary"
          onClick={handleClose}
          disabled={state.loading}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleConfirm}
          disabled={state.loading}
        >
          Decline Request
        </Button>
      </DialogActions>
    </Dialog>
  );
}
