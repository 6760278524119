/* eslint-disable no-throw-literal */
import React, { useContext, useState, useEffect, createContext } from "react";

import { supabase } from "../supabase";

// create context

const AuthContext = createContext({
  currentUser: {},
  login: () => {},
  logout: () => {},
  userLevel: "",
  forgotPassword: () => {},
  loginWithPhone: () => {},
  verifyOtp: () => {},
  resetPasswordForEmail: () => {},
});

// export

/**
 * Custom hook that provides access to the authentication context.
 * @returns {object} The current authentication context value.
 */
export function useAuth() {
  return useContext(AuthContext);
}

// auth provider

/**
 * Provides a context for authentication state and functions.
 * @function
 * @param {React} children The child component(s) to render.
 * @returns {React} The authentication context provider component.
 */
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [userLevel, setUserLevel] = useState();
  const [loading, setLoading] = useState(true);
  const [authSession, setAuthSession] = useState(null);

  // Check session

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session }, error }) => {
      if (error) console.error(error);

      setAuthSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setAuthSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  /**
   * Signs in a user using the provided email and password.
   * @function
   * @param {string} email The email address of the user to sign in.
   * @param {string} password The password of the user to sign in.
   * @returns {object} The error object if there is an issue, otherwise
   *   undefined.
   */
  async function login(email, password) {
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      console.error(error);
      // eslint-disable-next-line no-alert
      alert(error.message);
      return error;
    }
  }

  /**
   * Signs out the currently signed in user.
   * @function
   * @returns {Promise<void>} A promise which resolves when the user has been signed out.
   */
  async function logout() {
    return await supabase.auth.signOut().then(() => {
      setCurrentUser(null);
    });
  }

  /**
   * Starts the password reset process for the provided email address.
   * @function
   * @param {string} email The email address of the user to reset the password for.
   * @returns {Promise<object>} A promise which resolves with the result of the
   *   password reset request.
   */
  async function forgotPassword(email) {
    return await supabase.auth.resetPasswordForEmail(email);
  }

  /**
   * Creates a new user using the provided email address, phone number, and password.
   * @function
   * @param {string} email The email address of the user to create.
   * @param {string} phone The phone number of the user to create.
   * @param {string} password The password of the user to create.
   * @returns {Promise<object>} A promise which resolves with the result of the
   *   user creation request.
   */
  async function createUser(email, phone, password) {
    return await supabase.auth.signUp({
      email,
      password,
      phone,
    });
  }

  /**
   * Resets the password for the currently signed-in user.
   * @function
   * @param {string} password - The new password to set for the user.
   * @returns {Promise<object>} A promise which resolves with the result of the
   *   password update request.
   */
  async function resetPasswordForEmail(password) {
    return await supabase.auth.updateUser({
      email: currentUser.email,
      password,
    });
  }

  /**
   * Verifies a one time password (OTP) sent to the given phone number.
   * @function
   * @param {string} phone The phone number to verify the OTP for.
   * @param {string} token The OTP to verify.
   * @returns {Promise<object>} A promise which resolves with the result of the
   *   OTP verification request.
   */
  async function verifyOtp(phone, token) {
    return await supabase.auth.verifyOtp({
      phone,
      token,
      type: "sms",
    });
  }

  /**
   * Signs in a user using the provided phone number.
   * @function
   * @param {string} phone The phone number of the user to sign in.
   * @returns {Promise<boolean>} A promise which resolves with true if the user
   *   was signed in successfully, otherwise false.
   */
  async function loginWithPhone(phone) {
    const { error } = await supabase.auth.signInWithOtp({
      phone,
      type: "sms",
      options: { shouldCreateUser: false },
    });

    if (error) {
      return false;
    }
    return true;
  }

  /**
   * Retrieves the user document associated with the given UID from the dealers table.
   * @function
   * @param {string} uid The UID of the user to retrieve the document for.
   * @returns {Promise<object|null>} A promise which resolves with the user document
   *   if it exists, otherwise null.
   * @throws If there is an issue retrieving the document, will throw an error.
   */
  async function getUserDoc(uid) {
    const { data, error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("dealers")
      .select("*")
      .eq("auth_id", uid);

    if (error) {
      console.error("Error fetching user document:", error);
      throw new Error("Failed to retrieve user document.");
    }

    if (!data || data.length === 0) {
      console.error("No user document found for the given UID");
      return null;
    }

    return data[0];
  }

  // email should be switched for auth.users foreign key once transfer has been done

  const updateCurrentUser = async id => {
    const userDoc = await getUserDoc(id);

    if (!userDoc) {
      setCurrentUser(null);
    } else {
      setUserLevel(userDoc?.rank);
      setCurrentUser({ ...userDoc });

      // Listen for real-time changes to the user document
      supabase
        .channel("dealers")
        .on(
          "postgres_changes",
          {
            event: "*",
            schema: process.env.REACT_APP_SB_SCHEMA,
            table: "dealers",
            filter: `id=eq.${userDoc.id}`,
          },
          payload => {
            const updatedUser = payload.new;
            setUserLevel(updatedUser?.rank);
            setCurrentUser({ ...updatedUser });
          }
        )
        .subscribe();
    }
  };

  useEffect(() => {
    if (authSession) {
      updateCurrentUser(authSession.user.id);
    } else {
      setCurrentUser(null);
    }

    setLoading(false);
  }, [authSession]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    currentUser,
    login,
    logout,
    userLevel,
    forgotPassword,
    loginWithPhone,
    verifyOtp,
    resetPasswordForEmail,
    createUser,
    authSession,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
