import React, { useState, useEffect } from "react";
import { Paper } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import { supabase } from "../../../supabase";
import sqlToStandardDate from "../../shared/functions/sqlToStandardDate";
import QuickSearchToolbar from "./QuickSearchToolBar";

/**
 * The UnmatchedOrders component manages the display of unmatched orders for a selected supplier.
 * It fetches unmatched orders from the database, filters the results based on a search query,
 * and allows the user to assign a new agent from a list of available agents.
 *
 * The component renders a DataGrid with the unmatched orders data.
 * The DataGrid is searchable and includes a toolbar with a search input.
 * The component also renders a dropdown list of agents that can be assigned to an unmatched order.
 * The component fetches the list of agents from the database and updates the list when the user selects a new supplier.
 *
 * @param {object} props - The properties passed to the component.
 * @param {number} props.selectedSupplierId - The ID of the selected supplier.
 * @param {boolean} props.refreshListener - this is a boolean value that gets toggled to to trigger a refresh
 * @returns {React.ReactElement} - The rendered component with the unmatched orders data.
 */
function MatchedRecords({ selectedSupplierId, refreshListener }) {
  const [searchUnmatchedOrdersText, setSearchUnmatchedOrdersText] =
    useState("");
  const [loading, setLoading] = useState(false);
  const [unmatchedOrders, setUnmatchedOrders] = useState([]);
  const [unmatchedOrdersStatic, setUnmatchedOrdersStatic] = useState([]);

  const matchedColumns = () => [
    {
      field: "created_at",
      headerName: "Created Date",
      width: 200,
    },
    {
      field: "ref_num",
      headerName: "Reference Number",
      width: 200,
    },
    { field: "original_agent_value", headerName: "Original Value", width: 200 },
    {
      field: "assigned_agent",
      headerName: "Assigned Agent",
      minWidth: 200,
      flex: 1,
    },
  ];

  const refreshData = async () => {
    if (selectedSupplierId) {
      setLoading(true);

      const cutoffDate = new Date();
      cutoffDate.setDate(cutoffDate.getDate() - 60);

      const cutoffDateIso = cutoffDate.toISOString();

      const { data: orderOverrideData, error: overrideError } = await supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("fibre_order_master")
        .select(
          "id: order_number_1, fibre_order_override!inner (created_at, ref_num, original_agent_value, dealers(dealer_code, contact_name, contact_surname))"
        )
        .eq("supplier_1", selectedSupplierId)
        .gte("order_created_date_1", cutoffDateIso);

      if (overrideError) {
        console.error(overrideError.message);
      } else {
        const orderOverrideDataSorted = orderOverrideData.sort((a, b) => {
          const dateA = new Date(a.fibre_order_override.created_at);
          const dateB = new Date(b.fibre_order_override.created_at);
          return dateB - dateA;
        });

        const orderOverrideDataMap = orderOverrideDataSorted.map(
          ({ fibre_order_override: orderOverride }) => {
            const {
              dealers: user,
              created_at,
              ref_num,
              original_agent_value,
            } = orderOverride;
            return {
              id: ref_num,
              created_at: sqlToStandardDate(created_at, true),
              ref_num,
              original_agent_value,
              assigned_agent: `${user.dealer_code}: ${user.contact_name} ${user.contact_surname}`,
            };
          }
        );

        setUnmatchedOrders(orderOverrideDataMap);
        setUnmatchedOrdersStatic(orderOverrideDataMap);
      }
      setLoading(false);
    }
  };

  /**
   * Escapes special characters in a string to be used in a regular expression.
   *
   * @param {string} string - The string to escape.
   * @returns {string} - A new string with special characters escaped.
   */
  function escapeRegExp(string) {
    return string.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
  }

  const requestUnmatchedDealersSearch = searchValue => {
    setSearchUnmatchedOrdersText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = unmatchedOrdersStatic.filter(row => {
      return Object.keys(row).some(field => {
        return searchRegex.test(row[field] && row[field].toString());
      });
    });
    setUnmatchedOrders(filteredRows);
  };

  useEffect(() => {
    refreshData(true);
  }, [selectedSupplierId, refreshListener]);

  return (
    <Paper style={{ padding: 10 }}>
      <Typography sx={{ mt: 4, mb: 2 }} variant="h4" component="div">
        Reallocated Orders
      </Typography>
      <Typography sx={{ mb: 2 }} variant="subtitle1" component="div">
        This table contains records of orders that have been reassigned to a new
        agent within the last 60 days. Each record includes details about the
        original agent, the new agent, and the reallocation date.
      </Typography>

      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          pageSize={50}
          loading={loading}
          rows={unmatchedOrders}
          columns={matchedColumns()}
          components={{
            Toolbar: QuickSearchToolbar,
          }}
          componentsProps={{
            toolbar: {
              value: searchUnmatchedOrdersText,
              onChange: event =>
                requestUnmatchedDealersSearch(event.target.value),
              clearSearch: () => requestUnmatchedDealersSearch(""),
            },
          }}
        />
      </div>
    </Paper>
  );
}

export default MatchedRecords;
