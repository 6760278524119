/* eslint-disable jsdoc/require-param-name */
/* eslint-disable jsdoc/check-param-names */
/* eslint-disable jsdoc/require-param */
import { supabase } from "../../../supabase";

/**
 * Sends a sales assist message.
 *
 * @param {string} leadId - Sales assist lead ID.
 * @param {string} text - Message text.
 * @param {'admin' | 'user' | 'internal' | 'update'} type - Message type.
 * @param {'sales_assist_activity' | 'agent_assist_activity'}
 *   - 'admin': If the message is sent by an admin.
 *   - 'user': If the message is sent by a user.
 *   - 'internal': If the message is marked as internal.
 *   - 'update': If the message is an update message (sent by an admin).
 * @returns {{error: any, data: any}} - Result of the message insert operation.
 */
export default async function sendAssistMessage(leadId, text, type, table) {
  // Get the current timestamp
  const now = new Date().toISOString();

  // Check the message type to set the read_user and read_admin fields
  let readUser = null;
  let readAdmin = null;
  if (type === "admin") {
    readAdmin = now;
  } else if (type === "user") {
    readUser = now;
  } else if (type === "internal") {
    readUser = now;
  } else if (type === "update") {
    readAdmin = now;
  }

  // Build the message object to be inserted
  const messageObject = {
    text,
    read_user: readUser,
    read_admin: readAdmin,
    lead_id: leadId,
    activity_type: type,
  };

  // Insert the message into the database
  const { data, error } = await supabase
    .schema(process.env.REACT_APP_SB_SCHEMA)
    .from(table)
    .insert(messageObject)
    .select();

  // Log any errors
  if (error) {
    console.error(error);
  }

  // Return the result of the insert operation
  return {
    data,
    error,
  };
}
