import React, { useContext, useState, useEffect, useMemo } from "react";
import { supabase } from "../supabase";
import { useAuth } from "./AuthContext";

const StructureContext = React.createContext({
  downstream: [],
});

/**
 * Custom hook to access the current value of the StructureContext.
 *
 * @returns {object} The current context value, typically containing the downstream data.
 */
export function useStructure() {
  return useContext(StructureContext);
}

/**
 * Provides the structure context to its children components, managing the state and retrieval of
 * downstream and upstream dealer data from the database. It fetches and updates downstream data
 * whenever changes occur in the "dealer_downstream" table and also provides functions to fetch
 * downstream and upstream data for specific dealers.
 *
 * @param {object} props - The component props.
 * @param {React} props.children - The child components that can access the structure context.
 */
export function StructureProvider({ children }) {
  const [downstream, setDownstream] = useState([]);

  const { currentUser } = useAuth();

  const fetchDownstream = async () => {
    try {
      const { data: downstreamData, error } = await supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("dealer_downstream")
        .select("*")
        .eq("dealer_code", currentUser.dealer_code);

      if (error) throw error;

      const strDownstream = downstreamData[0]?.downstream || "";
      const arrDownstream = strDownstream.split(",");

      const chunkSize = 1000; // Process 1000 records per chunk
      const chunks = [];

      for (let i = 0; i < arrDownstream.length; i += chunkSize) {
        const chunk = arrDownstream.slice(i, i + chunkSize);
        chunks.push(chunk);
      }

      const fetchChunkData = async chunk => {
        const { data: userData, error: userError } = await supabase
          .schema(process.env.REACT_APP_SB_SCHEMA)
          .from("dealers")
          .select("*")
          .in("dealer_code", chunk)
          .neq("status", 4);

        if (userError) throw userError;
        return userData;
      };

      const chunkDataPromises = chunks.map(chunk => fetchChunkData(chunk));
      const chunkDataResults = await Promise.all(chunkDataPromises);

      setDownstream(chunkDataResults.flat());
    } catch (error) {
      console.error("Error fetching downstream data:", error);
    }
  };

  const fetchOtherDealerDownstream = async parent_dealer_id => {
    try {
      const { data: downstreamData, error } = await supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("dealer_downstream")
        .select("*")
        .eq("dealer_id", parent_dealer_id);

      if (error) throw error;

      const strDownstream = downstreamData[0]?.downstream || "";
      const arrDownstream = strDownstream.split(",");

      const chunkSize = 1000; // Process 1000 records per chunk
      const chunks = [];

      for (let i = 0; i < arrDownstream.length; i += chunkSize) {
        const chunk = arrDownstream.slice(i, i + chunkSize);
        chunks.push(chunk);
      }

      const fetchChunkData = async chunk => {
        const { data: userData, error: userError } = await supabase
          .schema(process.env.REACT_APP_SB_SCHEMA)
          .from("dealers")
          .select("*")
          .in("dealer_code", chunk)
          .neq("status", 4);

        if (userError) throw userError;
        return userData;
      };

      const chunkDataPromises = chunks.map(chunk => fetchChunkData(chunk));
      const chunkDataResults = await Promise.all(chunkDataPromises);

      return chunkDataResults.flat();
    } catch (error) {
      console.error("Error fetching downstream data:", error);
    }
  };

  const fetchOtherDealerUpstream = async dealer_code => {
    try {
      // Fetch data from the "dealer_downstream" table where the dealer_code matches the specified dealer_code
      const { data: downstreamData, error } = await supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("dealer_downstream")
        .select("*")
        .eq("dealer_code", dealer_code);

      // If there's an error during the fetch, throw it
      if (error) throw error;

      // Extract the upstream data from the fetched data; if not available, default to an empty string
      const strUpstream = downstreamData[0]?.upstream || "";
      // Split the upstream data string into an array using commas as the delimiter
      const arrUpstream = strUpstream.split(",");

      // Define the size of each chunk to process
      const chunkSize = 1000;
      // Initialize an array to hold the chunks
      const chunks = [];

      // Loop through the upstream data array and divide it into chunks of the specified size
      for (let i = 0; i < arrUpstream.length; i += chunkSize) {
        const chunk = arrUpstream.slice(i, i + chunkSize);
        chunks.push(chunk);
      }

      // Define an asynchronous function to fetch data for each chunk
      const fetchChunkData = async chunk => {
        // Fetch data from the "dealers" table where the dealer_code is in the chunk and status is not 4
        const { data: userData, error: userError } = await supabase
          .schema(process.env.REACT_APP_SB_SCHEMA)
          .from("dealers")
          .select("*")
          .in("dealer_code", chunk)
          .neq("status", 4);

        // If there's an error during the fetch, throw it
        if (userError) throw userError;
        // Return the fetched data
        return userData;
      };

      // Map each chunk to a fetch operation and await all fetch operations
      const chunkDataPromises = chunks.map(chunk => fetchChunkData(chunk));
      const chunkDataResults = await Promise.all(chunkDataPromises);

      // Flatten the array of chunk results and return it
      return chunkDataResults.flat();
    } catch (error) {
      // Log any errors that occur during the process
      console.error("Error fetching upstream data:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = () => {
      fetchDownstream();

      supabase
        .channel("dealer_downstream")
        .on(
          "postgres_changes",
          { event: "*", schema: "public", table: "dealer_downstream" },
          () => {
            fetchDownstream();
          }
        )
        .subscribe();
    };

    return unsubscribe();
  }, []);

  const value = {
    downstream,
    fetchOtherDealerDownstream,
    fetchOtherDealerUpstream,
  };

  const memoizedValue = useMemo(() => value, [value]);

  return (
    <StructureContext.Provider value={memoizedValue}>
      {children}
    </StructureContext.Provider>
  );
}
