import React, { useEffect } from "react";
import { TextField, IconButton } from "@material-ui/core";

import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";

/**
 * DataGridSearchBar component provides a search bar for filtering data in a data grid.
 *
 * @component
 * @param {object} props - The component props.
 * @param {Function} props.requestSearch - Function to call when a search is requested.
 * @param {string} [props.searchText=""] - The current search text.
 * @param {Function} props.clearSearch - Function to call when the search text needs to be cleared.
 *
 * @example
 * <DataGridSearchBar
 *   requestSearch={handleSearch}
 *   searchText={searchText}
 *   clearSearch={handleClearSearch}
 * />
 */
function DataGridSearchBar({
  requestSearch = () => {},
  searchText = "",
  clearSearch = () => {},
}) {
  const handleSearch = e => {
    requestSearch(e.target.value);
  };

  useEffect(() => {
    requestSearch(searchText);
  }, [searchText]);

  return (
    <TextField
      fullWidth
      variant="standard"
      value={searchText}
      onChange={handleSearch}
      placeholder="Search…"
      InputProps={{
        startAdornment: <SearchIcon fontSize="small" />,
        endAdornment: (
          <IconButton
            title="Clear"
            aria-label="Clear"
            size="small"
            style={{ visibility: searchText ? "visible" : "hidden" }}
            onClick={clearSearch}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        ),
      }}
    />
  );
}

export default DataGridSearchBar;
