import { supabase } from "../../../supabase";

/**
 * Checks if the given ID number is already used in the database.
 *
 * This function trims the input ID number and generates four variations of it:
 * - The trimmed ID number.
 * - The trimmed ID number in lowercase.
 * - The trimmed ID number with the first character in uppercase and the rest in lowercase.
 * - The trimmed ID number in uppercase.
 *
 * It then queries the "dealers" table in the database to check if any of these variations
 * are already present in the "contact_id_no" column.
 *
 * @param {string} idNumber - The ID number to check.
 * @returns {Promise<boolean>} A promise that resolves to `true` if the ID number is already used, `false` otherwise.
 */
export default function checkIdNumberIsUsed(idNumber) {
  return new Promise(resolve => {
    const trimmedId = idNumber.trim();

    const idNumberOne = trimmedId;
    const idNumberTwo = trimmedId.toLowerCase();
    const idNumberThree = `${trimmedId[0]}${trimmedId.slice(1).toLowerCase()}`;
    const idNumberFour = trimmedId.toUpperCase();

    supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("dealers")
      .select()
      .in("contact_id_no", [
        idNumberOne,
        idNumberTwo,
        idNumberThree,
        idNumberFour,
      ])
      .then(result => {
        resolve(result.data.length > 0);
      })
      .catch(err => {
        console.error(err);
        // Return true if error for safety.
        resolve(true);
      });
  });
}
