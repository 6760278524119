/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import { Paper } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import Select from "react-select";
import { supabase } from "../../../supabase";
import sqlToStandardDate from "../../shared/functions/sqlToStandardDate";
import QuickSearchToolbar from "./QuickSearchToolBar";
/**
 * NewAgentSelector is a React component that allows selection of a new agent from a list.
 * It displays a dropdown of agent options and updates the selected agent in the database.
 *
 * @param {object} props - The component props.
 * @param {object} props.row - The data row containing agent information.
 * @param {Array} props.agents - List of available agents to select from.
 * @param {Function} props.setLoading - Function to manage loading state.
 * @param {Function} props.refreshData - Callback to refresh data after agent selection.
 *
 * @returns {React.ReactElement} - The rendered component with a dropdown to select a new agent.
 */
function NewAgentSelector({ row, agents, setLoading, refreshData }) {
  const { newly_assigned_agent: newlyAssignedAgent } = row;

  const [currentValue, setCurrentValue] = useState("");

  // Fetch agent name if exists
  const getAgentName = async () => {
    if (newlyAssignedAgent) {
      const currentValueArr = agents.filter(
        agent => newlyAssignedAgent === agent.value
      );
      if (currentValueArr[0]) {
        setCurrentValue(currentValueArr[0]);
      }
    }
  };

  // Fetch agent name on component update
  useEffect(() => {
    if (newlyAssignedAgent) getAgentName();
  }, [agents]);

  // Handle agent selection
  const handleSelect = async e => {
    setLoading(true);
    const { id, agent_identification_1: agentId } = row;

    setCurrentValue(e);

    const { error } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("fibre_order_override")
      .upsert(
        [
          {
            ref_num: id,
            original_agent_value: agentId,
            newly_assigned_agent: e.value,
          },
        ],
        { onConflict: "ref_num" }
      )
      .select();

    if (error) {
      console.error(error.message);
    } else {
      refreshData();
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Select
        xs={12}
        required
        menuPortalTarget={document.body}
        styles={{
          menuPortal: base => ({
            ...base,
            zIndex: 9999,
          }),
        }}
        options={agents}
        defaultValue={currentValue}
        value={currentValue}
        onChange={e => {
          handleSelect(e);
        }}
        placeholder={
          row.commission_paid ? "Commission Paid" : "Select New Agent"
        }
        isDisabled={row.commission_paid}
      />
    </div>
  );
}

/**
 * The UnmatchedOrders component manages the display of unmatched orders for a selected supplier.
 * It fetches unmatched orders from the database, filters the results based on a search query,
 * and allows the user to assign a new agent from a list of available agents.
 *
 * The component renders a DataGrid with the unmatched orders data.
 * The DataGrid is searchable and includes a toolbar with a search input.
 * The component also renders a dropdown list of agents that can be assigned to an unmatched order.
 * The component fetches the list of agents from the database and updates the list when the user selects a new supplier.
 *
 * @param {object} props - The properties passed to the component.
 * @param {number} props.selectedSupplierId - The ID of the selected supplier.
 * @param {boolean} props.refreshListener - this is a boolean value that gets toggled to to trigger a refresh
 * @param {Function} props.fetchAgents - Function to fetch the list of agents for a supplier.
 * @returns {React.ReactElement} - The rendered component with the unmatched orders data.
 */
function UnmatchedOrders({ selectedSupplierId, fetchAgents, refreshListener }) {
  const [searchUnmatchedOrdersText, setSearchUnmatchedOrdersText] =
    useState("");
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [unmatchedOrders, setUnmatchedOrders] = useState([]);
  const [unmatchedOrdersStatic, setUnmatchedOrdersStatic] = useState([]);

  const unmatchedColumns = () => [
    {
      field: "order_created_date_1",
      headerName: "Order Date",
      width: 200,
      renderCell: params => (
        <p>{sqlToStandardDate(params.row.order_created_date_1)}</p>
      ),
    },
    {
      field: "unique_premises_id_0",
      headerName: "Premises ID",
      width: 200,
    },
    { field: "order_number_1", headerName: "Ref Num", width: 200 },
    {
      field: "zawadi_agent_code_2",
      headerName: "Original Agent Code",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "agent_identification_1",
      headerName: "Original Agent Name",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "newly_assigned_agent",
      headerName: "Assigned Agent",
      minWidth: 200,
      flex: 1,
      renderCell: params => (
        <NewAgentSelector
          row={params.row}
          agents={agents}
          setLoading={setLoading}
          refreshData={() => {
            refreshData(false);
          }}
          disabled={false}
        />
      ),
    },
  ];

  const refreshData = async fetchUnmatchedOrders => {
    if (selectedSupplierId) {
      setLoading(true);

      const cutoffDate = new Date();
      cutoffDate.setDate(cutoffDate.getDate() - 60);

      const cutoffDateIso = cutoffDate.toISOString();

      const { data: orderOverrideData, error: overrideError } = await supabase
        .schema(process.env.REACT_APP_SB_SCHEMA)
        .from("fibre_order_master")
        .select(
          "id: order_number_1, agent_identification_1, order_created_date_1, order_number_1, supplier_1, unique_premises_id_0, zawadi_agent_code_2, fibre_order_override (original_agent_value, newly_assigned_agent), order_status (agent_activation_comm_paid_date_1) "
        )
        .eq("supplier_1", selectedSupplierId)
        .or(
          `order_created_date_1.gte.${cutoffDateIso}, order_activation_date_2.gte.${cutoffDateIso}, order_paid_date_1.gte.${cutoffDateIso}`
        )
        .not("order_paid_date_1", "is", null)
        .or(
          "agent_identification_1.not.like.%Z-%,zawadi_agent_code_2.is.null,zawadi_agent_code_2.eq.NOT FOUND"
        )
        .order("order_created_date_1", {
          ascending: false,
        })
        .order("order_number_1", {
          ascending: false,
        });

      if (overrideError) {
        console.error(overrideError.message);
      } else {
        const unmatchedOrdersFiltered = orderOverrideData.map(row => {
          const commissionPaid =
            !!row.order_status?.agent_activation_comm_paid_date_1;

          return {
            agent_identification_1: row.agent_identification_1,
            id: row.id,
            newly_assigned_agent:
              row.fibre_order_override?.newly_assigned_agent,
            order_created_date_1: row.order_created_date_1,
            order_number_1: row.order_number_1,
            original_agent_value:
              row.fibre_order_override?.original_agent_value,
            supplier_1: row.supplier_1,
            unique_premises_id_0: row.unique_premises_id_0,
            zawadi_agent_code_2: row.zawadi_agent_code_2,
            commission_paid: commissionPaid,
          };
        });

        setUnmatchedOrders(unmatchedOrdersFiltered);
        setUnmatchedOrdersStatic(unmatchedOrdersFiltered);

        if (fetchUnmatchedOrders && selectedSupplierId) {
          const agentsByService = await fetchAgents(selectedSupplierId);
          setAgents(agentsByService);
        }
      }
      setLoading(false);
    }
  };

  /**
   * Escapes special characters in a string to be used in a regular expression.
   *
   * @param {string} string - The string to escape.
   * @returns {string} - A new string with special characters escaped.
   */
  function escapeRegExp(string) {
    return string.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
  }

  const requestUnmatchedDealersSearch = searchValue => {
    setSearchUnmatchedOrdersText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = unmatchedOrdersStatic.filter(row => {
      return Object.keys(row).some(field => {
        return searchRegex.test(row[field] && row[field].toString());
      });
    });
    setUnmatchedOrders(filteredRows);
  };

  useEffect(() => {
    refreshData(true);
  }, [selectedSupplierId, refreshListener]);

  return (
    <Paper style={{ padding: 10 }}>
      <Typography sx={{ mt: 4, mb: 2 }} variant="h4" component="div">
        Unmatched Orders
      </Typography>
      <Typography sx={{ mb: 2 }} variant="subtitle1" component="div">
        This table contains records of all orders that could not be matched to
        an agent. Each record includes relevant order details and metadata
        indicating the unmatched status.
      </Typography>

      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          pageSize={50}
          loading={loading}
          rows={unmatchedOrders}
          columns={unmatchedColumns()}
          components={{
            Toolbar: QuickSearchToolbar,
          }}
          componentsProps={{
            toolbar: {
              value: searchUnmatchedOrdersText,
              onChange: event =>
                requestUnmatchedDealersSearch(event.target.value),
              clearSearch: () => requestUnmatchedDealersSearch(""),
            },
          }}
        />
      </div>
    </Paper>
  );
}

export default UnmatchedOrders;
