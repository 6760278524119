import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

/**
 * A React component that dynamically appends a script tag to the document body
 * based on the current location's pathname.
 *
 * @param {object} props - The properties object.
 * @param {string} props.src - The source URL of the script to be appended.
 * @param {string} props.path - The path to check against the current location's pathname.
 *
 * This component uses a useEffect hook to append the script only once when
 * the current location's pathname includes the specified path. If the pathname
 * no longer includes the path, it reloads the page.
 */
function ScriptTag(props) {
  const { src, path } = props;
  const [count, setCount] = useState(0);
  const location = useLocation();
  const id = location.pathname;
  const result = id.includes(path);
  useEffect(() => {
    if (result && count === 0) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.nonce = "{6JDFIvPbrWANKpSJ8vlv6b==}";
      script.src = src;
      script.defer = true;
      document.body.appendChild(script);
      setCount(1);
    }
    if (!result && count === 1) {
      window.location.reload(true);
      setCount(0);
    }
  }, [id, path, src]);

  return null;
}

export default ScriptTag;
