const agentLevelOptions = [
  { label: "Admin (1)", value: 1 },
  { label: "Super Dealer (2)", value: 2 },
  { label: "Dealer (3)", value: 3 },
  { label: "Sales Manager (4)", value: 4 },
  { label: "Tier 2 Team Leader (5)", value: 5 },
  { label: "Tier 1 Team Leader (6)", value: 6 },
  { label: "Assistant Team Leader (7)", value: 7 },
  { label: "Agent (8)", value: 8 },
];

/**
 * Transforms a given value into its corresponding rank label.
 *
 * @param {string} value - The value to be transformed.
 * @returns {string} - The label of the rank if found, otherwise an empty string.
 */
function transformRank(value) {
  const rank = agentLevelOptions.find(option => option.value === value);
  return rank ? rank.label : "";
}

module.exports = transformRank;
