import { Button } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React from "react";
import { useParams, useHistory } from "react-router";
import { useDb } from "../../../contexts/DatabaseContext";
import SimDataGrid from "../../shared/data-grid/SimDataGrid";

/**
 * DealerAgents component fetches and displays a list of dealer agents.
 *
 * @component
 * @returns {React.Element} The rendered component.
 *
 * @example
 * return <DealerAgents />
 *
 * @description
 * This component uses the `useParams` hook to get the dealer ID from the URL,
 * the `useHistory` hook to navigate back to the previous page, and a custom
 * `useDb` hook to fetch the dealer agents from the database. It then maps the
 * agents to rows and defines the columns for a data grid. The component renders
 * a back button and a data grid with the dealer agents' information.
 *
 * @function
 * @name DealerAgents
 */
export default function DealerAgents() {
  const { dealerId } = useParams();
  const history = useHistory();
  const { GetDealerAgents } = useDb();
  const agents = GetDealerAgents(dealerId);

  const rows =
    agents &&
    agents.map(a => ({
      id: a.id,
      dealerName: a.dealerName,
      phone: a.phone,
      street: a.street,
      city: a.city,
      suburb: a.suburb,
    }));

  const columns = [
    { field: "dealerName", headerName: "Name", width: 200 },
    { field: "phone", headerName: "Phone", width: 150 },
    { field: "street", headerName: "Street", width: 200 },
    { field: "suburb", headerName: "Suburb", width: 200 },
    { field: "city", headerName: "City", width: 200 },
  ];

  return (
    <div>
      <div className="back-link">
        <Button
          variant="contained"
          startIcon={<ArrowBack />}
          onClick={() => history.goBack()}
          color="primary"
        >
          Back
        </Button>
      </div>
      <SimDataGrid data={rows} columns={columns} />
    </div>
  );
}
