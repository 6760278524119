import React, { useState, useEffect } from "react";
import { CircularProgress, Box, Typography } from "@material-ui/core";

/**
 * A Loading component that will conditionally render a loading overlay
 * on top of its children. The loading overlay is rendered if the
 * `loading` prop is true or if the component is mounted and the
 * `loading` prop is not provided.
 *
 * The loading overlay includes a semi-transparent background and
 * a centered loading animation with a text label.
 *
 * The component will automatically simulate loading with a 1.8s
 * delay if the `loading` prop is not provided. You can use this
 * component to wrap a component that needs to fetch data on mount.
 *
 * @example
 * <Loading>
 *  <MyComponent />
 * </Loading>
 *
 * @param {object} props - JSX props
 * @param {React.ReactNode} props.children The children elements to render
 * @param {boolean} [props.loading] Whether or not to render the loading overlay
 * @returns {React.ReactElement} The Loading component with the children
 *  and optional loading overlay
 */
function Loading({ children, loading: propLoading }) {
  const [loading, setLoading] = useState(true); // State to track if the page is loading

  useEffect(() => {
    let timer;

    if (propLoading) {
      // If the loading prop is true, set loading to true and don't set a timeout
      setLoading(true);
    } else {
      // Otherwise, simulate loading with a delay
      timer = setTimeout(() => {
        setLoading(false); // Set loading to false after the delay
      }, 1800);
    }

    // Cleanup the timer if the component unmounts before the timeout completes
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [propLoading]);

  return (
    <Box position="relative" height="100vh" width="100%">
      {/* Render the children */}
      <div>{children}</div>

      {/* Conditionally render the loading overlay */}
      {loading && (
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="#f9f9f9" // Semi-transparent background
          zIndex="1000" // Ensure the overlay is above the content
        >
          <Box textAlign="center">
            <CircularProgress style={{ color: "#e68625" }} />

            <Typography variant="h6" mt={2}>
              Loading...
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Loading;
