/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-curly-newline */
// #useAuthUpdated
/* eslint-disable no-unreachable */
/* eslint-disable no-console */
/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Select from "react-select";
import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Alert from "@material-ui/lab/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useHistory } from "react-router-dom";
import {
  provinces,
  countryCodes,
  banks,
  bankAccountTypes,
  tShirtSizes,
} from "../shared/constants";
import snakeToCamel from "../shared/functions/snakeToCamel";
import { useSB } from "../../contexts/SupabaseContext";
import ChangePhoneNumber from "./ChangePhone";
import ChangeEmail from "./ChangeEmail";
import * as API from "../../api";

/**
 * Handles all the logic for editing a dealer's profile.
 * @param {object} props Component props.
 * @param {boolean} props.dialogIsOpen Whether the dialog is open or not.
 * @param {Function} props.closeDialog Function to close the dialog.
 * @param {boolean} props.editing Whether the user is editing their own profile or not.
 * @param {Function} props.openSnackbar Function to open a snackbar.
 * @param {object} props.dealerProp The dealer object to edit.
 * @returns {React.ReactElement} The JSX element to render.
 */
export default function EditProfile(props) {
  const { dialogIsOpen, closeDialog, editing, openSnackbar, dealerProp } =
    props;
  const { updateRow } = useSB();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [bankAccountNumberHelperText, setBankAccountNumberHelperText] =
    useState("");
  const [bankAccountNumberIsInvalid, setBankAccountNumberIsInvalid] =
    useState(false);
  const [dealerState, setDealerState] = useState([]);
  const [dealer, setDealer] = useState([]);
  const [phoneChanged, setPhoneChanged] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const transformedDealerProp = snakeToCamel(dealerProp);
    setDealerState(transformedDealerProp);
    setDealer(transformedDealerProp);
  }, []);

  /**
   * Checks for any changes in the payment information of the dealer.
   *
   * @returns {boolean} Returns true if there are any changes in the dealer's payment type,
   * bank account type, branch code, bank account number, bank, or phone number
   * (when the payment type is "eWallet"), compared to their initial state.
   */
  /** @todo f4d385ce-48e3-4f7a-9459-86ca5eaa7dea  */
  const checkForPaymentChanges = () => {
    return (
      dealer.paymentType !== dealerState.paymentType ||
      dealer.bankAccountType !== dealerState.bankAccountType ||
      dealer.branchCode !== dealerState.branchCode ||
      dealer.bankAccountNumber !== dealerState.bankAccountNumber ||
      dealer.bank !== dealerState.bank ||
      dealer.phone !== dealerState.phone
    );
  };

  /**
   * Checks if the dealer's email has changed and if the dealer was previously linked
   * to SADV (selectedSuppliers includes "1f95b163-7663-4acf-9041-d9d8804db396").
   *
   * @returns {boolean} True if the dealer's email has changed and the dealer was linked to SADV
   */
  const checkForSadvEmailChange = () => {
    return (
      dealerState.selectedSuppliers.includes(
        "1f95b163-7663-4acf-9041-d9d8804db396"
      ) && dealer.email !== dealerState.email
    );
  };
  /**
   * Handles the submission process for updating a dealer's profile.
   * Validates the dealer's address and payment details, and updates
   * the dealer information in the database. If any changes in the
   * payment details or contact information are detected, it sends
   * notifications and updates accordingly.
   *
   * @async
   * @returns {void}
   */
  const handleSubmit = async () => {
    setError("");
    setLoading(true);

    if (bankAccountNumberIsInvalid) {
      setError(bankAccountNumberHelperText);
      setLoading(false);
      return;
    }
    // Transforms:
    const dealerObjectToSubmit = {
      // Address details
      province: dealerState.province,
      street: dealerState.street,
      suburb: dealerState.suburb,
      postal_code: dealerState.postalCode,
      city: dealerState.city,
      country: dealerState.country,
      // Payment details
      payment_type: dealerState.paymentType,
      bank_account_type: dealerState.bankAccountType || null,
      branch_code: dealerState.branchCode || null,
      bank_account_number: dealerState.bankAccountNumber
        ? String(dealerState.bankAccountNumber)
        : null,
      bank: dealerState.bank || null,
      t_shirt_size: dealerState.tShirtSize || "",
      agreed_to_rica_terms: dealerState.agreedToRicaTerms,
    };

    if (!dealerObjectToSubmit.street?.length) {
      setError("Please add a Street Address");
      setLoading(false);
      return;
    }
    if (!dealerObjectToSubmit.suburb?.length) {
      setError("Please add a Suburb");
      setLoading(false);
      return;
    }
    if (!dealerObjectToSubmit.postal_code?.length) {
      setError("Please add a Postal Code");
      setLoading(false);
      return;
    }
    if (!dealerObjectToSubmit.city?.length) {
      setError("Please add a City");
      setLoading(false);
      return;
    }
    if (dealerObjectToSubmit.payment_type === "bank") {
      if (!dealerObjectToSubmit.bank?.length) {
        setError("Please select a bank");
        setLoading(false);
        return;
      }
      if (!dealerObjectToSubmit.bank_account_number?.length) {
        setError("Please add a bank account number");
        setLoading(false);
        return;
      }
      if (!dealerObjectToSubmit.bank_account_type) {
        setError("Please select a bank account type");
        setLoading(false);
        return;
      }
    }

    if (!dealerObjectToSubmit.agreed_to_rica_terms) {
      setError("Please check RICA And POPIA Training");
      setLoading(false);
      return;
    }

    if (phoneChanged) {
      dealerObjectToSubmit.phone = dealerState.phone;
      dealerObjectToSubmit.phone_verified = false;
    }

    if (emailChanged) {
      dealerObjectToSubmit.email = dealerState.email;
      dealerObjectToSubmit.email_verified = false;
    }

    try {
      await updateRow("dealers", dealerState.id, dealerObjectToSubmit);
      /**
       * Sends an email notification if payment details have changed.
       *
       * This function checks for changes in payment details. If changes are detected, it triggers an email
       * notification to a designated recipient, depending on the selected supplier.
       *
       * @async
       * @function sendPaymentChangeNotification
       * @param {Function} checkForPaymentChanges - Callback function to check if payment details have changed.
       * @param {Function} sendEmail - Asynchronous function to send the email notification.
       *
       * @description
       * The recipient email is selected based on the presence of a specific supplier ID. If supplier ID
       * "1f95b163-7663-4acf-9041-d9d8804db396" is included in the dealer's suppliers, the email is sent to
       * "Lizmari@zawadi.africa"; otherwise, it goes to "Mariska@zawadi.africa".
       *
       * The email content varies depending on the `paymentType`. If `paymentType` is "eWallet", only the phone
       * number is included. Otherwise, the email includes bank details.
       *
       * @returns {Promise<void>} - Resolves when the email is successfully sent, or logs an error if sending fails.
       *
       * @example
       * sendPaymentChangeNotification(checkForPaymentChanges, sendEmail);
       */
      if (checkForPaymentChanges() && editing) {
        API.sendEmail({
          email: "mariska@zawadi.africa",
          subject: `ZIMS Agent Notification: Payment Details Changed for Agent Code ${dealerState.dealerCode}`,
          message: `
        Dear Mariska,
  
        Please note that the payment details for agent code ${
          dealerState.dealerCode
        } have been updated. Below are the new details:
        ${
          dealerState.paymentType === "eWallet"
            ? `
            - Payment Type: ${dealerState.paymentType} 
            - Phone: ${dealerState.phone} 
            `
            : `   
            - Payment Type: ${dealerState.paymentType} 
            - Bank: ${dealerState.bank} 
            - Bank Account Number: ${dealerState.bankAccountNumber}
            - Bank Account Type: ${dealerState.bankAccountType}
            - Branch Code: ${dealerState.branchCode}`
        }

         This change was detected on ${new Date().toLocaleDateString()}.
  
         Kind regards,
         ZIMS System Notification
      `,
        })
          .then(() => {
            console.log("Email sent successfully.");
          })
          .catch(mailError => {
            console.error("Error sending email:", mailError);
          });
      }

      if (checkForSadvEmailChange() && editing) {
        API.sendEmail({
          email: "lizmari@zawadi.africa",
          subject: `ZIMS Agent Notification: Email Address Changed for Agent Code ${dealerState.dealerCode}`,
          message: `
        Dear Lizmari,
  
        Please note that the email address for agent code ${
          dealerState.dealerCode
        } have been changed. Below are the details:

        Previous email address ${dealer.email}

        New email address ${dealerState.email}

         This change was detected on ${new Date().toLocaleDateString()}.
  
         Kind regards,
         ZIMS System Notification
      `,
        })
          .then(() => {
            console.log("Email sent successfully.");
          })
          .catch(mailError => {
            console.error("Error sending email:", mailError);
          });
      }

      if (phoneChanged || emailChanged) {
        const updateAndSendVerification = await API.updateEmailAndPhone({
          authId: dealerState.authId,
          email: dealerState.email,
          phone: dealerState.phone,
          emailChanged,
          phoneChanged,
        });

        if (updateAndSendVerification.data.code === 200) {
          setLoading(false);
          setDealerState([]);
          closeDialog();
          if (phoneChanged) {
            history.push("/verify-phone");
          }
        } else {
          setError(updateAndSendVerification.data.message);
          setLoading(false);
        }
      } else {
        setLoading(false);
        setDealerState([]);
        closeDialog();
      }

      if (!editing) {
        openSnackbar();
      }
    } catch (error2) {
      setError(error2.message);
      setLoading(false);
    }
  };

  // Handles bank account numbers
  const handleSetBankAccountNumber = async numberLocal => {
    setDealerState({ ...dealerState, bankAccountNumber: numberLocal });

    const numberLocalString = numberLocal.toString();
    if (numberLocalString.length < 6) {
      setBankAccountNumberHelperText("Account number is incomplete");
      setBankAccountNumberIsInvalid(true);
    } else {
      setBankAccountNumberHelperText("");
      setBankAccountNumberIsInvalid(false);
    }
  };

  const handleTrained = () => {
    setDealerState({
      ...dealerState,
      agreedToRicaTerms: !dealerState.agreedToRicaTerms,
    });
  };

  return (
    <div>
      <Dialog
        open={dialogIsOpen}
        onClose={() => {
          setDealerState([]);
          closeDialog();
        }}
        fullScreen={fullScreen}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: "16px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <DialogTitle
          style={{
            flex: 1,
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
          }}
        >
          Edit Profile
        </DialogTitle>
        <DialogContent style={{ margin: "15px" }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <form onSubmit={handleSubmit}>
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  color: "black",
                  marginBottom: "-10px",
                }}
              >
                Address
              </Typography>
              <div style={styles.inputContainer}>
                <FormLabel
                  component="legend"
                  style={{ fontSize: ".85rem", marginBottom: 16 }}
                >
                  Country of Residence
                </FormLabel>
                <Select
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: base => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  options={countryCodes}
                  defaultValue={countryCodes.find(
                    item => item.value === dealerState.country
                  )}
                  onChange={e => {
                    setDealerState({
                      ...dealerState,
                      country: e.value,
                    });
                  }}
                  placeholder="Country of Residence"
                />
              </div>
              <div style={styles.inputContainer}>
                <FormLabel
                  component="legend"
                  style={{ fontSize: ".85rem", marginBottom: 16 }}
                >
                  Province of Residence
                </FormLabel>
                <Select
                  xs={12}
                  required
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: base => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  options={provinces}
                  defaultValue={provinces.find(
                    item => item.value === dealerState.province
                  )}
                  onChange={e => {
                    setDealerState({
                      ...dealerState,
                      province: e.value,
                    });
                  }}
                  placeholder="Select Province"
                />
              </div>
              <div style={styles.inputContainer}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Street address"
                  variant="outlined"
                  fullWidth
                  value={dealerState.street}
                  onChange={e => {
                    setDealerState({
                      ...dealerState,
                      street: e.target.value,
                    });
                  }}
                  required
                />
              </div>
              <div style={styles.inputContainer}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Suburb"
                  variant="outlined"
                  fullWidth
                  value={dealerState.suburb}
                  onChange={e => {
                    setDealerState({
                      ...dealerState,
                      suburb: e.target.value,
                    });
                  }}
                  required
                />
              </div>
              <div style={styles.inputContainer}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Postal Code"
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={dealerState.postalCode}
                  onChange={e => {
                    if (e.target.value.length < 5) {
                      setDealerState({
                        ...dealerState,
                        postalCode: e.target.value,
                      });
                    }
                  }}
                />
              </div>
              <div style={styles.inputContainer}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="City"
                  variant="outlined"
                  fullWidth
                  value={dealerState.city}
                  onChange={e => {
                    setDealerState({
                      ...dealerState,
                      city: e.target.value,
                    });
                  }}
                  required
                />
              </div>
              <div style={styles.inputContainer}>
                <FormLabel
                  component="legend"
                  style={{ fontSize: ".85rem", marginBottom: 16 }}
                >
                  T-Shirt Size
                </FormLabel>
                <Select
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: base => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  options={tShirtSizes}
                  defaultValue={tShirtSizes.find(
                    item => item.value === dealerState.tShirtSize
                  )}
                  onChange={e => {
                    setDealerState({
                      ...dealerState,
                      tShirtSize: e.value,
                    });
                  }}
                />
              </div>
              <Divider style={{ margin: 20 }} />
              <div style={styles.inputContainer}>
                <FormControl component="fieldset" required>
                  <FormLabel
                    style={{
                      fontSize: "1rem",
                      fontWeight: "bold",
                      color: "black",
                      // paddingLeft: "4px",
                      paddingBottom: "10px",
                    }}
                  >
                    Payment Type
                  </FormLabel>
                  <RadioGroup
                    row
                    required
                    aria-label="payment-type"
                    name="payment-type"
                    value={dealerState.paymentType}
                    style={{ paddingLeft: "10px", marginBottom: "-10px" }}
                    onChange={e => {
                      setDealerState({
                        ...dealerState,
                        paymentType: e.target.value,
                      });
                    }}
                  >
                    <FormControlLabel
                      value="bank"
                      control={<Radio />}
                      label="Bank"
                    />
                    <FormControlLabel
                      value="eWallet"
                      control={<Radio />}
                      label="eWallet"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {dealerState.paymentType === "bank" && (
                <>
                  <div style={styles.inputContainer}>
                    <Select
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: base => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      options={banks}
                      onChange={e => {
                        setDealerState({
                          ...dealerState,
                          bank: e.value,
                          branchCode: banks.filter(
                            item => item.value === e.value
                          )[0].branchCode,
                        });
                      }}
                      defaultValue={
                        dealerState.bank
                          ? {
                              label: dealerState.bank,
                              value: dealerState.bank,
                            }
                          : null
                      }
                      placeholder="Bank"
                    />
                  </div>
                  <div style={styles.inputContainer}>
                    <Select
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: base => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      options={bankAccountTypes}
                      onChange={e => {
                        setDealerState({
                          ...dealerState,
                          bankAccountType: e.value,
                        });
                      }}
                      defaultValue={
                        dealerState.bankAccountType
                          ? {
                              label: bankAccountTypes.filter(
                                item =>
                                  item.value ===
                                  parseInt(dealerState.bankAccountType)
                              )[0].label,
                              value: dealerState.bankAccountType,
                            }
                          : null
                      }
                      placeholder="Account Type"
                    />
                  </div>
                  <div style={styles.inputContainer}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="number"
                      variant="outlined"
                      label="Account Number"
                      placeholder="Account Number"
                      helperText={bankAccountNumberHelperText}
                      error={bankAccountNumberIsInvalid}
                      value={dealerState.bankAccountNumber}
                      onChange={e => handleSetBankAccountNumber(e.target.value)}
                      //  onBlur={e => handleBankAccountNumberBlur(e.target.value)}
                      fullWidth
                      required
                      inputProps={{
                        minLength: 6,
                        maxLength: 20,
                      }}
                    />
                  </div>
                </>
              )}
              {dealerState.paymentType === "eWallet" && (
                <div style={styles.inputContainer}>
                  <Typography>
                    We will use <b>{dealerState.phone}</b> for eWallet payments.
                  </Typography>
                </div>
              )}
              <Divider style={{ margin: 20 }} />
              <Typography
                style={{ marginBottom: 10, marginTop: 20, fontWeight: "bold" }}
              >
                Change Contact Details
              </Typography>
              <ChangePhoneNumber
                currentPhone={dealerState.phone}
                setNewPhone={newPhone => {
                  setDealerState(prev => ({ ...prev, phone: newPhone }));
                  setPhoneChanged(true);
                }}
              />
              <ChangeEmail
                currentEmail={dealerState.email}
                setNewEmail={newEmail => {
                  setDealerState(prev => ({ ...prev, email: newEmail }));
                  setEmailChanged(true);
                }}
              />
              <Divider style={{ margin: 20 }} />
              <Grid item style={{ paddingTop: 30 }}>
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                    color: "black",
                    marginBottom: "10px",
                  }}
                >
                  T&Cs
                </Typography>
                <FormControl
                  component="fieldset"
                  required
                  disabled={editing && dealerState.agreedToRicaTerms}
                  style={{ marginLeft: "10px" }}
                >
                  {" "}
                  <FormLabel
                    component="legend"
                    style={{
                      fontSize: "0.85rem",
                      textOverflow: "ellipsis",
                      width: fullScreen ? "100%" : "65%",
                      paddingBottom: "10px",
                    }}
                  >
                    Please note that Zawadi does not take any responsibility for
                    incorrect bank details or cellphone numbers submitted. We
                    will not be held accountable if your commission is not
                    received due to these errors. Kindly double-check your
                    details before submission to avoid any issues.
                  </FormLabel>{" "}
                  <FormLabel
                    component="legend"
                    style={{
                      fontSize: "0.85rem",
                      textOverflow: "ellipsis",
                      width: fullScreen ? "100%" : "65%",
                      paddingBottom: "10px",
                    }}
                  >
                    I confirm that I have trained the agent on the
                    responsibilities of a agent and the responsibilities as
                    explained in the act.
                  </FormLabel>{" "}
                  <Grid direction="row">
                    <a
                      href="https://zmdjhwyplkwfgwmmtwak.supabase.co/storage/v1/object/public/terms_and_conditions/RICA_Terms_and_Conditions.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      RICA
                    </a>
                    {" , "}
                    <a
                      href="https://zmdjhwyplkwfgwmmtwak.supabase.co/storage/v1/object/public/terms_and_conditions/POPIA_Zawadi_mrg.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      POPIA
                    </a>
                  </Grid>
                  <FormControlLabel
                    control={<Checkbox required />}
                    label="I Agree"
                    name="agreedToRicaTerms"
                    checked={dealerState.agreedToRicaTerms}
                    onChange={() => handleTrained()}
                  />
                </FormControl>
              </Grid>
            </form>
          </MuiPickersUtilsProvider>
        </DialogContent>

        {error && (
          <Alert
            style={{ width: "100%", marginBottom: "1rem" }}
            severity="error"
          >
            {error}
          </Alert>
        )}
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              setDealerState([]);
              closeDialog();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const styles = {
  inputContainer: {
    marginTop: 30,
  },
};
