import React, { useState, useEffect } from "react";
import {
  Container,
  Button,
  Dialog,
  Snackbar,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Select from "react-select";
import { Alert } from "@material-ui/lab";
import { useCompany } from "../../../contexts/CompanyContext";
import { supabase } from "../../../supabase";
import UpdateFreezeWrapper from "../UpdateFreezeWrapper";
import FindOrder from "./FindOrder";
import UnmatchedOrders from "./UnmatchedOrders";
import MatchedRecords from "./MatchedRecords";

/**
 * CustomTabPanel component renders the content of a tab.
 *
 * @param {object} props - The properties object.
 * @param {React.ReactNode} props.children - The content to be displayed within the tab panel.
 * @param {number} props.value - The current value of the tab.
 * @param {number} props.index - The index of the tab panel.
 *
 * @returns {React.Element} - The rendered tab panel component.
 */
function CustomTabPanel({ children, value, index }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

/**
 * OrderOverride component allows users to manage orders by re-assigning them
 * to different agents. It fetches order data from the backend and provides
 * search functionality for both unmatched and all orders. The component
 * displays a list of suppliers for selection, which updates the orders
 * displayed. Users can search through orders using a search bar, and
 * unmatched orders can be re-assigned to agents using a dropdown selector.
 *
 * State variables are used to manage loading state, selected supplier,
 * unmatched and all orders data, agents list, and search text for orders.
 * Uses Supabase for data retrieval and updates order data upon supplier
 * selection or search input changes. Displays orders in a DataGrid with
 * QuickSearchToolbar for search and management.
 *
 * @returns {React.ReactElement} The rendered component with order management functionality.
 */
function OrderOverride() {
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const { activeSuppliers } = useCompany();
  const [suppliersStatic, setSuppliersStatic] = useState([]);
  const [showFindOrderModal, setShowFindOrderModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [refresh, setRefresh] = useState(false);

  const toggleRefresh = () => {
    setRefresh(prev => !prev);
  };

  useEffect(() => {
    if (!showFindOrderModal) {
      toggleRefresh();
    }
  }, [showFindOrderModal]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Update active suppliers
  useEffect(() => {
    const supplierArray = [];
    activeSuppliers.forEach((supplier, index) => {
      supplierArray.push({
        value: supplier.internal_identifier,
        label: supplier.supplier,
        internalId: supplier.id,
      });
      if (index + 1 === activeSuppliers.length) {
        setSuppliersStatic(supplierArray);
      }
    });
  }, [activeSuppliers]);

  const fetchAgents = async supplier => {
    const { data: agentsByService, error: agentsError } = await supabase
      .schema(process.env.REACT_APP_SB_SCHEMA)
      .from("dealers")
      .select("id, dealer_code, contact_name, contact_surname")
      .contains("selected_suppliers", [supplier])
      .eq("status", 2);

    if (agentsError) {
      console.error(agentsError.message);
    } else {
      const agentsByServiceMap = agentsByService.map(agent => ({
        value: agent.id,
        label: `${agent.dealer_code}: ${agent.contact_name} ${agent.contact_surname}`,
      }));
      return agentsByServiceMap;
    }
  };

  return (
    <Container>
      <UpdateFreezeWrapper>
        <Grid item xs={12} md={6}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div style={{ flex: 1 }}>
              <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                Order Override
              </Typography>
            </div>
            <Button
              style={{ maxWidth: 110 }}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                setShowFindOrderModal(true);
              }}
            >
              Find Order
            </Button>
          </div>
          <hr />
          <div style={styles.inputContainer}>
            <Typography style={{ marginBottom: 15 }}>
              Please select a supplier
            </Typography>
            <Select
              xs={12}
              required
              menuPortalTarget={document.body}
              styles={{
                menuPortal: base => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
              options={suppliersStatic}
              defaultValue={undefined}
              onChange={e => {
                setSelectedSupplier(e);
              }}
              placeholder="Supplier"
            />
            <Typography variant="caption">
              * Please note that no rows will display before a supplier has been
              selected
            </Typography>
          </div>
          <hr />

          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="basic tabs example"
              >
                <Tab label="Unmatched Orders" />
                <Tab label="Reallocated Orders" />
              </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
              <UnmatchedOrders
                selectedSupplierId={selectedSupplier?.internalId}
                fetchAgents={fetchAgents}
                refreshListener={refresh}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <MatchedRecords
                selectedSupplierId={selectedSupplier?.internalId}
                fetchAgents={fetchAgents}
                refreshListener={refresh}
              />
            </CustomTabPanel>
          </Box>
        </Grid>
      </UpdateFreezeWrapper>
      <Dialog open={showFindOrderModal} maxWidth="md" fullWidth>
        <FindOrder
          setShowFindOrderModal={setShowFindOrderModal}
          setShowSuccess={setShowSuccess}
          selectedSupplier={selectedSupplier}
          fetchAgents={fetchAgents}
        />
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showSuccess}
        autoHideDuration={3000}
        onClose={() => setShowSuccess(false)}
      >
        <Alert severity="success" onClose={() => setShowSuccess(false)}>
          Order Updated Successfully
        </Alert>
      </Snackbar>
    </Container>
  );
}

// Export the OrderOverride component as default
export default OrderOverride;

// Additional styles
const styles = {
  inputContainer: {
    marginTop: 30,
    marginBottom: 30,
  },
};
