import React from "react";

import {
  Grid,
  Backdrop,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

/**
 * ModalCard component renders a modal dialog with optional title and close button.
 *
 * @param {object} props - The properties object.
 * @param {boolean} [props.open=true] - Controls whether the modal is open or closed.
 * @param {Function} [props.onClose=() => {}] - Callback function to handle modal close event.
 * @param {React.ReactNode} props.children - The content to be displayed inside the modal.
 * @param {string} [props.closeButtonText] - The text to be displayed on the close button.
 * @param {string} [props.title] - The title to be displayed at the top of the modal.
 * @returns {React.Element} The rendered ModalCard component.
 */
export function ModalCard({
  open = true,
  onClose = () => {},
  children,
  closeButtonText = undefined,
  title = undefined,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      {title && <DialogTitle onClose={onClose}>{title}</DialogTitle>}
      <DialogContent>
        <Grid item>{children}</Grid>
      </DialogContent>
      <DialogActions>
        {closeButtonText && (
          <Button
            required
            color="secondary"
            variant="contained"
            onClick={onClose}
          >
            {closeButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ModalCard;
